import React, { useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import colors from '../styles/colors'
import { useGlobalVarible } from '../Constants/globalContextModel';

const TopNavbar = () => {
    const [searchText, setSearchText] = useState('');
    const [hover, setHover] = useState(false);
    const { view, anotherVar } = useGlobalVarible();

    const handleSearch = () => {
        console.log(`Searching for: ${searchText}`);
    };

    return (
        <div style={{
            ...(view == 1 ? {
                width: 'auto',
                padding: '5px',
                display: 'flex',
                height: '40px',
                justifyContent: 'flex-end',
                marginTop: '20px',
                background: colors.containerBG
            } : {
                width: 'auto',
                padding: '5px',
                display: 'flex',
                height: '60px',
                justifyContent: 'flex-end',
                alignItems:'center',
                backgroundColor: '#432F60',
                width:'100%',
                background: `linear-gradient(to right, #9E3C5D, #432F60)`,
            })

        }}>
            <div style={{
                display: 'flex',
                alignItems: 'center',
                border: `1px solid ${colors.border}`,
                borderRadius: '10px',
                padding: '5px',
                width: '250px',
                backgroundColor: colors.white,
                height: '40px',
                marginRight: '100px'
            }}>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: hover ? colors.secondary : colors.primary,
                        borderRadius: '5px',
                        padding: '5px',
                        marginRight: '5px',
                        cursor: 'pointer'
                    }}
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                >
                    <SearchIcon style={{ color: colors.white, fontSize: '18px' }} />
                </div>
                <input
                    type="text"
                    placeholder="Search for everything"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            handleSearch();
                        }
                    }}
                    style={{
                        border: 'none',
                        outline: 'none',
                        flex: 1,
                        fontSize: '14px',
                        height: '20px'
                    }}
                />
            </div>
        </div>
    );
};

export default TopNavbar;
