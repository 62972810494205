import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { dataHubDataFetch } from '../../components/Apis/DataHubApi';
import {
    Box,
    Grid,
    IconButton,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextField,
    Pagination,
    MenuItem,
    Stack,
    Button, // Import the Button component
    Divider,
    Avatar,
    Typography
} from '@mui/material';
import { KeyboardArrowRight, Refresh, Article } from '@mui/icons-material';
import StorageIcon from '@mui/icons-material/Storage';
import colors from "../../components/styles/colors";
import ApexCharts from 'react-apexcharts';

const gradientButtonStyle = {
    background: `linear-gradient(180deg, ${colors.primary} 60%, ${colors.secondary} 90%)`,
    color: colors.white,
    '&:hover': {
        background: `linear-gradient(180deg, ${colors.primary} 60%, ${colors.secondary} 90%)`,
        opacity: 0.8,
    },
};

const DonutChart = ({ series, labels, handleNavigate }) => {

    const options = {
        chart: {
            type: 'donut',
            events: {
                click: (event, chartContext, config) => {
                    const dataPointIndex = config.dataPointIndex;
                    if (dataPointIndex !== undefined && labels[dataPointIndex] && series[dataPointIndex]) {
                        const selectedType = {
                            document_type: labels[dataPointIndex],
                            total_count: series[dataPointIndex]
                        };
                        handleNavigate(selectedType);
                    } else {
                        console.error('Invalid data point selected or chart data is not available');
                    }
                }
            }
        },
        labels: labels,
        // colors: ['#674f87', '#ef798a', '#8b6cfc', '#fbc760', '#66cc99'],
        colors: ['#442F60', '#6D355F', '#9A3C5E', '#684893', '#ef798a'],
        dataLabels: {
            enabled: true,
            formatter: (val) => `${Math.round(val)}%`,
            style: {
                fontSize: '14px', // Adjust font size as needed
                fontWeight: 'bold', // Make it bold if required
                colors: ['#fff'], // Set the color of the text
            },
            offsetX: 0, // Adjust horizontal alignment (0 for centered)
            offsetY: 20, // Adjust vertical alignment (positive to move down, negative to move up)
        },
        legend: {
            show: true,
            position: 'bottom',
        },
    };

    return (
        series && series.length > 0 && labels && labels.length > 0 ? ( // Add checks for series and labels
            <ApexCharts options={options} series={series} type="donut" width="200" />
        ) : (
            <div>Loading Chart</div>
        )
    );
};

const DataHub = () => {
    const [dataHubList, setDataHubList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [searchTerm, setSearchTerm] = useState('');
    const [totalRecords, setTotalRecords] = useState(0);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const navigate = useNavigate();
    const [docList, setDocList] = useState([]);
    const [docTypeCounts, setDocTypeCounts] = useState({});
    const totalCategories = Object.keys(docTypeCounts).length;
    const chartSeries = Object.values(docTypeCounts);
    const chartLabels = Object.keys(docTypeCounts);

    const fetchDoc = async (offset, limit, search, startDate, endDate) => {
        setIsLoading(true);
        try {
            const response = await dataHubDataFetch(offset, limit, search, startDate, endDate);
            if (response.success !== false) {
                setDocList(response.items || []);
            } else {
                setDataHubList([]);
                console.warn(response.message || 'No data in response');
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const limit = totalRecords;
        const offset = (page - 1) * limit;
        fetchDoc(offset, limit, '', '', '');
    }, [totalRecords]);

    useEffect(() => {
        const typeCounts = docList.reduce((acc, doc) => {
            acc[doc.data_name] = (acc[doc.data_name] || 0) + 1;
            return acc;
        }, {});
        setDocTypeCounts(typeCounts);
    }, [docList]);

    const handleNavigate = () => {

    };

    const fetchData = async (offset, rowsPerPage, search, startDate, endDate) => {
        setIsLoading(true);
        try {
            const response = await dataHubDataFetch(offset, rowsPerPage, search, startDate, endDate);
            if (response.success !== false) {
                setDataHubList(response.items || []);
                setTotalRecords(response.total || 0);
            } else {
                setDataHubList([]);
                console.warn(response.message || 'No data in response');
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const offset = (page - 1) * rowsPerPage;
        fetchData(offset, rowsPerPage, searchTerm, startDate, endDate);
    }, [page, rowsPerPage, searchTerm, startDate, endDate]);

    const handleRowClick = (rowId) => {
        navigate(`/details/${rowId}`);
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
        setPage(1);
    };

    const handleStartDateChange = (event) => {
        setStartDate(event.target.value);
        setPage(1);
    };

    const handleEndDateChange = (event) => {
        setEndDate(event.target.value);
        setPage(1);
    };

    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(event.target.value);
        setPage(1);
    };

    // Function to clear filters
    const clearFilters = () => {
        setSearchTerm('');
        setStartDate('');
        setEndDate('');
        setPage(1); // Reset to the first page
    };

    return (
        <div style={{ padding: '40px 30px' }}>
            <h2>Data Hub <StorageIcon style={{ fontSize: 30, verticalAlign: 'bottom' }} /></h2>
            <Divider sx={{ borderWidth: 2 }} />
            <Box sx={{marginBlock:5}}>
                <Grid container alignItems="center" justifyContent="center" marginBottom={5}>
                    <Grid item xs={12} sm={6} md={3} display="flex" flexDirection="row" justifyContent="center" alignItems="center" gap={2}>
                        <Box alignItems="center">
                            <Avatar sx={{ bgcolor: '#432F60', width: { xs: 80, sm: 100 }, height: { xs: 80, sm: 100 } }}>
                                <Article sx={{ fontSize: { xs: 40, sm: 60 }, color: '#d8d8d8' }} />
                            </Avatar>
                        </Box>
                        <Box>
                            <Typography variant="h5" sx={{ marginBlock: 1 }}>
                                Documents Processed
                            </Typography>
                            <Typography variant="h4" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                                {totalRecords}
                            </Typography>
                        </Box>
                    </Grid>

                    <Divider orientation="vertical" flexItem sx={{ mx: 2, borderWidth: 3, display: { xs: 'none', md: 'block' }, color: '#d8d8d8' }} />

                    <Grid item xs={12} sm={6} md={3} display="flex" flexDirection="row" justifyContent="center" alignItems="center" gap={2}>
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", margin: "auto" }}>
                            <DonutChart series={chartSeries} labels={chartLabels} handleNavigate={handleNavigate} />
                        </Box>

                    </Grid>
                </Grid>
            </Box>
            <Box
                sx={{
                    p: 2,
                    marginBlock: 1,
                    backgroundColor: colors.containerBG,
                    borderRadius: 5,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent:"center",
                    textAlign: 'center',
                    width: '100%'
                }}
            >
                <Stack spacing={2} sx={{ mb: 2 }} direction="row" alignItems="flex-end">
                    <TextField
                        label="Search Agent"
                        variant="outlined"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        size="small"
                    />
                    <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Start Date"
                                type="date"
                                value={startDate}
                                onChange={handleStartDateChange}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                sx={{ width: '100%' }}
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="End Date"
                                type="date"
                                value={endDate}
                                onChange={handleEndDateChange}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                sx={{ width: '100%' }}
                                size="small"
                            />
                        </Grid>
                    </Grid>
                    <Button variant="outlined" sx={gradientButtonStyle} style={{ borderRadius: '20px' }} onClick={clearFilters}>
                        <Refresh />
                    </Button>
                </Stack>
                {isLoading ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <CircularProgress />
                    </div>
                ) : (
                    <>
                        <TableContainer component={Paper} sx={{ borderRadius: '10px', overflow: 'hidden' }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>ID</TableCell>
                                        <TableCell>Time</TableCell>
                                        <TableCell>Agent</TableCell>
                                        <TableCell>Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Array.isArray(dataHubList) && dataHubList.map(row => (
                                        <TableRow key={row.id} hover onClick={() => handleRowClick(row.id)}>
                                            <TableCell>{row.id}</TableCell>
                                            <TableCell>{new Date(row.timestamp).toLocaleString()}</TableCell>
                                            <TableCell>{row.data_name}</TableCell>
                                            <TableCell>
                                                <IconButton color="primary">
                                                    <KeyboardArrowRight />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Grid container justifyContent="space-between" alignItems="center" sx={{ mt: 2 }}>
                            <Grid item>
                                <TextField
                                    select
                                    label="Rows per page"
                                    value={rowsPerPage}
                                    onChange={handleRowsPerPageChange}
                                    sx={{ width: '120px' }}
                                >
                                    {[5, 10, 20].map((option) => (
                                        <MenuItem key={option} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item>
                                <Pagination
                                    count={Math.ceil(totalRecords / rowsPerPage)}
                                    page={page}
                                    onChange={(event, value) => setPage(value)}
                                    color="primary"
                                />
                            </Grid>
                        </Grid>
                    </>
                )}
            </Box>
        </div>
    );
};

export default DataHub;
