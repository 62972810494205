import React from 'react';
import colors from '../../components/styles/colors';
import { Button, Box } from '@mui/material';
import './LandingPage.css';
import TypewriterHeading from '../../components/Animations/TypingAnim';
import { useNavigate } from 'react-router-dom';

const LandingPage = () => {
    const navigate = useNavigate();
    const handleSignUpClick = () => {
        navigate('/LoginSignupage');
    };

    return (
        <div className="container"> 
            {/* Top Navbar */}
            <nav className="navbar">
                <img
                    src="icons/Logo.svg"
                    alt="AiGEN Logo"
                    className="logo"
                />
                <ul className="navList">
                    <li className="navLinkItem"  onClick={() => navigate('/home')}>Home</li>
                    <li className="navLinkItem">Page</li>
                    <li className="navLinkItem">Community</li>
                    <li className="navLinkItem">Features</li>
                    <li className="navLinkItem">Support</li>
                </ul>
                <button className="signUpButton" onClick={handleSignUpClick}>
                    Sign Up Free
                </button>
            </nav>

            <div className="mainContent">
                {/* Left Vertical Navbar */}
                <div className="verticalNavbar">
                    <div style={{ marginTop: 'auto' }}>
                        <div className="socialIcons">
                            <img src="/icons/TwitterWhiteLogo.svg" alt="Twitter" className="socialIcon" />
                            <img src="/icons/InstagramWhiteLogo.svg" alt="Instagram" className="socialIcon" />
                        </div>
                        <div className="socialIcons">
                            <img src="/icons/LinkedinWhiteLogo.svg" alt="LinkedIn" className="socialIcon" />
                            <img src="/icons/FacebookWhiteLogo.svg" alt="Facebook" className="socialIcon" />
                        </div>
                    </div>
                </div>

                <div className="contentSection">
                    {/* Page 0*/}
                    <div className="page0">
                        <div className="leftContent">
                            <h1><TypewriterHeading text="Empowering Your World with Artificial Intelligent Agents." typingSpeed={60} /></h1>
                            <p>
                                Our mission is to harness the power of AI to create innovative solutions that drive progress and transform industries.
                                With a team of dedicated experts, we are committed to developing intelligent system that enhance efficiency.
                            </p>
                            <div className="statsContainer">
                                <div className="statBox">
                                    <h2>93.24%</h2>
                                    <p>Customer Satisfaction</p>
                                </div>
                                <div className="statBox">
                                    <h2>$57M+</h2>
                                    <p>In Transaction</p>
                                </div>
                            </div>
                        </div>

                        <div className="rightContent">
                            <p className="largeText">SMART <br /><span>TECHNOLOGY</span></p>
                            <p>With a team of dedicated experts, we are committed to developing intelligent systems.</p>
                            <button className="readMoreButton">
                                Read More
                            </button>
                            <p className="largeText">DRIVEN<br /><span>EXCELLENCE</span></p>
                            <p>AI to create innovative solutions that drive progress and transform industries.</p>
                        </div>
                    </div>

                    {/* page 1 */}
                    <div className="page1">
                        <Box
                            sx={{
                                display: { lg: 'flex', sm: 'block' },
                                justifyContent: { lg: 'space-between', sm: "center" },
                                alignItems: 'center',
                                height: '100vh',
                                color: 'white',
                                padding: '0 5%',
                                gap: 3,
                                marginTop: { sm: '25px' }
                            }}
                        >
                            {/* Left Section with Text */}
                            <Box>
                                <Box style={{ margin: 0, p: 0 }}>
                                    <p style={{ fontSize: '70px', fontWeight: 'bold' }}>Welcome to the Revolution of Smart Technology</p>
                                </Box>
                                <Box>
                                    <p style={{ fontSize: '20px', color: '#cfcdcd', padding: '0px 80px 0px 0px' }}>
                                        Our mission is to harness the power of AI to create innovative solutions that drive progress and transform industries.
                                        With a team of dedicated experts, we are committed to developing intelligent systems.
                                    </p>
                                </Box>
                                <Box mt={3}>
                                    <Button variant="contained" sx={{ mr: 5, backgroundColor: '#00bfff' }}>
                                        Get Started
                                    </Button>
                                    <Button variant="outlined" sx={{ color: 'white', borderColor: 'white' }}>
                                        Contact Us
                                    </Button>
                                </Box>
                            </Box>
                            <Box
                                component="img"
                                sx={{
                                    width: { lg: '550px', sm: '400px',md: '550px' },
                                    height: { lg: '550px', sm: '400px', md: '550px' },
                                    display: { sm: 'none' },
                                    marginTop: { sm: '20px' },
                                    ml: '20px',
                                    borderRadius: '50px',
                                    mx: { sm: 'auto' },
                                    transition: 'transform 0.3s ease-in-out',
                                    '&:hover': {
                                        transform: 'scale(1.05)'
                                    },
                                }}
                                alt="Technology Background"
                                src="/images/p2-landingpage-image.png"
                            />
                        </Box>
                    </div>

                    {/* page 2 */}
                    <div className="page2">
                        <Box
                            sx={{
                                display: { lg: 'flex', sm: 'block' },
                                justifyContent: { lg: 'space-between', sm: "center" },
                                alignItems: 'center',
                                height: '100vh',
                                color: 'white',
                                padding: '0 5%',
                                marginTop: { sm: '25px' }
                            }}
                        >
                            {/* Image section */}
                            <Box
                                component="img"
                                sx={{
                                    width: { lg: '550px', sm: '400px',md: '550px' },
                                    height: { lg: '550px', sm: '400px', md: '550px' },
                                    display: { sm: 'block' },
                                    mx: { sm: 'auto' },
                                    borderRadius: '50px',
                                    marginRight: { lg: '150px' },
                                    transition: 'transform 0.3s ease-in-out',
                                    '&:hover': {
                                        transform: 'scale(1.05)'
                                    },
                                }}
                                alt="AI Concept"
                                src="/images/page2-landingPage.png"
                            />

                            {/* Text and CTA Section */}
                            <Box>
                                {/* Main Heading */}
                                <Box style={{ margin: 0, padding: 0 }}>
                                    <p style={{ fontSize: '55px', fontWeight: 'bold', color: '#ffffff' }}>
                                        Discover Cutting-Edge AI Solutions
                                    </p>
                                </Box>

                                {/* Mission Statement */}
                                <Box>
                                    <p style={{ fontSize: '20px', color: '#cfcdcd', padding: '0px 80px 0px 0px' }}>
                                        Our mission is to harness the power of AI to create innovative solutions that drive progress
                                        and transform industries. With a team of dedicated experts, we are committed to developing
                                        intelligent systems that impact the world.
                                    </p>
                                </Box>

                                {/* KPIs Section */}
                                <Box mt={4} display="flex" justifyContent="space-between" sx={{
                                    border: '1px solid white',
                                    width: { lg: '70%', sm: '100%' },
                                    padding: '5px 25px',
                                    borderRadius: '15px'
                                }}>
                                    <Box textAlign="center">
                                        <p style={{ fontSize: '30px', fontWeight: 'bold', color: '#00bfff' }}>89.2K+</p>
                                        <p style={{ fontSize: '18px', color: '#cfcdcd' }}>Driven Excellence</p>
                                    </Box>
                                    <Box textAlign="center">
                                        <p style={{ fontSize: '30px', fontWeight: 'bold', color: '#00bfff' }}>7001+</p>
                                        <p style={{ fontSize: '18px', color: '#cfcdcd' }}>AI Technology</p>
                                    </Box>
                                    <Box textAlign="center">
                                        <p style={{ fontSize: '30px', fontWeight: 'bold', color: '#00bfff' }}>3124+</p>
                                        <p style={{ fontSize: '18px', color: '#cfcdcd' }}>AI Innovations</p>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </div>
                </div>
                {/* </div> */}
            </div>
        </div>
    );
};

export default LandingPage;
