import React, { useState } from 'react';
import { Box, Typography, ToggleButtonGroup, ToggleButton } from '@mui/material';
import Chart from 'react-apexcharts';

const AgentActivitiesChart = ({width, height}) => {
    const dataSeries = [450, 250, 400, 850, 400, 200, 850, 300]; 
    const maxVal = Math.max(...dataSeries); 

    const [chartData] = useState({
        series: [
        {
            name: 'Bot Activity',
            data: dataSeries,
        },
        {
            name: 'Available',
            data: [150, 150, 300, 100, 100, 100, 100, 100],
        },
        ],
        options: {
        chart: {
            type: 'bar',
            stacked: true,
            height: 300,
            toolbar: {
                show: false,
            }
        },
        colors: [
            function({ value, seriesIndex, dataPointIndex }) {
                return value === maxVal ? '#0867d2' : '#000';
            },
            '#D3D3D3' 
        ], 
        plotOptions: {
            bar: {
            horizontal: false,
            columnWidth: '75%',
            borderRadius: 10,
            borderRadiusApplication: 'end',
            },
        },
        xaxis: {
            categories: [
            'Oct 7', 
            'Oct 8', 
            'Oct 9', 
            'Oct 10', 
            'Oct 11', 
            'Oct 12', 
            'Oct 13', 
            'Oct 14'
            ], 
        },
        yaxis: {
            max: 1000,
            tickAmount: 2, 
            labels: {
                formatter: function (val) {
                    return val === 0 || val === 500 || val === 1000 ? val : '';
                }
            } 
        },
        fill: {
            opacity: 1,
        },
        dataLabels: {
            enabled: false, 
        },
        tooltip: {
            enabled: true,
            y: {
            formatter: function (val) {
                return val + ' activities';
            },
            },
        },
        annotations: {
            xaxis: [
            {
                x: 'Dec 15',
                borderColor: 'transparent',
                fillColor: '#000',
            },
            ],
        },
        legend: {
            show: false, 
        }
        },
    });


const [alignment, setAlignment] = React.useState('monthly');

const handleChange = (event, newAlignment) => {
setAlignment(newAlignment);
};


  return (
    <Box className="chart" sx={{padding:2}}>
        <Box display={"flex"} alignItems={"center"}>
            <Typography variant='h5' sx={{fontWeight: 'bold'}}>
                    Bot Activity Graph  
            </Typography>
            <Box flexGrow={1}/>
            <Box>
                <ToggleButtonGroup
                    color="primary"
                    value={alignment}
                    exclusive
                    size='small'
                    onChange={handleChange}
                    aria-label="Platform"
                    sx={{border:'none'}}
                    >
                    <ToggleButton value="daily" disabled
                        sx={{ 
                        '&.Mui-selected': { 
                            background: 'linear-gradient(to right, #0867d2 , #000)',
                            color: '#fff',
                        }
                        }}
                    >
                        Daily
                    </ToggleButton>
                    <ToggleButton value="weekly" disabled
                        sx={{ 
                            '&.Mui-selected': { 
                                background: 'linear-gradient(to right, #0867d2 , #000)',
                                color: '#fff',
                            }
                            }}
                    >
                        Weekly
                    </ToggleButton>
                    <ToggleButton value="monthly" 
                            sx={{ 
                                '&.Mui-selected': { 
                                    background: 'linear-gradient(to right, #0867d2 , #000)',
                                    color: '#fff',
                                }
                                }}
                    >
                        Monthly
                    </ToggleButton>
                </ToggleButtonGroup>
            </Box>
        </Box>
        <Box>
            <Chart
                options={chartData.options}
                series={chartData.series}
                type="bar"
                height={height}
                width={width}
            />
        </Box>  
    </Box>
  );
};

export default AgentActivitiesChart;
