import React, { useState, useEffect } from 'react';
import {
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel, 
  OutlinedInput,
  Select,
  InputAdornment
} from '@mui/material';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import { createTool, uploadFiles } from '../../components/Apis/AgentApi';
import colors from '../../components/styles/colors';
import DescriptionIcon from '@mui/icons-material/Description';


const gradientButtonStyle = {
  background: `linear-gradient(180deg, ${colors.primary} 60%, ${colors.secondary} 90%)`,
  color: colors.white,
  '&:hover': {
    background: `linear-gradient(180deg, ${colors.primary} 60%, ${colors.secondary} 90%)`,
    opacity: 0.8,
  },
};

const CreateAgentDialog = ({ open, onClose }) => {
  const [formData, setFormData] = useState({
    agent_name: '',
    prompt: '',
    description: '',
    agent_type: 'Extraction',
    config: '{}',
    icon: '',
    tool_selection: 'base',
    codeFile: ''
  });

  useEffect(() => {
    console.log('Dialog open state:', open);
  }, [open]);
  

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData(prev => ({
          ...prev,
          icon: reader.result
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCodeUpload = async (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('file', file);
    
    try {
      await uploadFiles(formData); // Ensure this returns a promise.
      setFormData(prev => ({
        ...prev,
        codeFile: file.name
      }));
    } catch (error) {
      console.error('File upload error:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleToolSelectionChange = (event) => {
    const value = event.target.value;
    setFormData(prev => ({
      ...prev,
      tool_selection: value,
      icon: '',
      codeFile: ''
    }));
  };

  const handleSubmit = () => {
    createTool(formData).then((status) => {
      if (status === 201) {
        onClose();
      }
    });
  };

  const AGENT_TYPES = [
    { value: 'code', label: 'Code Validation' },
    { value: 'json', label: 'JSON Validation' },
    { value: 'text', label: 'Simple Prompt Validation' }
  ];

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        style: {
          padding: '24px',
          borderRadius: '8px',
          boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
        }
      }}
    >
      <DialogTitle style={{ textAlign: 'center', fontSize: '1.5rem', fontWeight: 600 }}>
        Create Connectors
      </DialogTitle>

      <DialogContent>
        <FormControl component="fieldset" fullWidth margin="dense">
          <FormLabel component="legend" style={{ fontWeight: 500 }}>Select Tool Type</FormLabel>
          <RadioGroup row value={formData.tool_selection} onChange={handleToolSelectionChange}>
            <FormControlLabel value="base" control={<Radio color="primary" />} label="Base Tool" />
            <FormControlLabel value="validation" control={<Radio color="primary" />} label="Validation Tool" />
          </RadioGroup>
        </FormControl>

        <div style={{ display: 'flex' }}>
          {formData.tool_selection === 'base' ? (
            <div style={{ display: 'flex', justifyContent: 'center', width:"100%" }}>
              <div style={{
                position: 'relative',
                width: '128px',
                height: '128px',
                borderRadius: '50%',
                border: '4px solid #e0e0e0',
                overflow: 'hidden',
                cursor: 'pointer',
                backgroundColor: '#f5f5f5',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageUpload}
                  style={{ display: 'none' }}
                  id="profile-picture-input"
                />
                <label htmlFor="profile-picture-input" style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  {formData.icon ? (
                    <img
                      src={formData.icon}
                      alt="Profile"
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        borderRadius: '50%'
                      }}
                    />
                  ) : (
                    <div style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      color: '#9e9e9e'
                    }}>
                      <AddAPhotoIcon style={{ fontSize: 40 }} />
                      <span style={{ fontSize: '14px', marginTop: '8px' }}>Add Icon</span>
                    </div>
                  )}
                </label>
              </div>
            </div>
          ) : (
            <div style={{width:"100%"}}>
              <div style={{ fontSize: "16px", color: colors.primary, fontWeight: "bold"}}>
                Upload your code/support files
              </div>
              <div style={{ marginBottom: '16px', fontSize: "16px", color: colors.primary, fontWeight: "bold", width:"100%",justifyContent:"center"}}>
                <TextField
                  type="file"
                  accept=".json, .txt, .py"
                  onChange={handleCodeUpload}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <DescriptionIcon style={{ color: colors.primary }} />
                      </InputAdornment>
                    ),
                  }}
                  style={{ marginBlock: '8px', borderRadius:3}}
                />
                {formData.codeFile && <p style={{ color: '#555' }}>Uploaded Code File: <strong>{formData.codeFile}</strong></p>}
              </div>
            </div>
          )}
        </div>

        {/* <TextField
          margin="dense"
          label="Agent Name"
          name="agent_name"
          fullWidth
          variant="outlined"
          value={formData.agent_name}
          onChange={handleChange}
          style={{ marginBottom: '16px' }}
        /> */}
        <FormControl fullWidth sx={{ marginBottom: 3 }}>
          <FormLabel
            sx={{
              fontSize: "16px",
              color: colors.primary,
              fontWeight: "bold",
            }}
          >
            Connector Name
          </FormLabel>
          <OutlinedInput
            value={formData.agent_name}
            onChange={handleChange}
            placeholder="Enter Connector Name"
            name="agent_name"
            sx={{
              borderRadius: 3,
              "& .MuiOutlinedInput-notchedOutline": { borderColor: colors.silver },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: colors.primary,
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: colors.primary,
              },
            }}
          />
        </FormControl>
        {/* <TextField
          margin="dense"
          label="Description"
          name="description"
          fullWidth
          variant="outlined"
          value={formData.description}
          onChange={handleChange}
          multiline
          minRows={3}
          maxRows={5}
          style={{ marginBottom: '16px' }}
        /> */}
        <FormControl fullWidth sx={{ marginBottom: 3 }}>
          <FormLabel
            sx={{
              fontSize: "16px",
              color: colors.primary,
              fontWeight: "bold",
            }}
          >
            Description
          </FormLabel>
          <OutlinedInput
            value={formData.description}
            onChange={handleChange}
            placeholder="Enter Description"
            name="description"
            multiline
            minRows={3}
            maxRows={5}
            sx={{
              borderRadius: 3,
              "& .MuiOutlinedInput-notchedOutline": { borderColor: colors.silver },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: colors.primary,
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: colors.primary,
              },
            }}
          />
        </FormControl>
        {formData.tool_selection === 'base' ? (
          // <TextField
          //   margin="dense"
          //   label="Agent Type"
          //   name="agent_type"
          //   fullWidth
          //   variant="outlined"
          //   value={formData.agent_type}
          //   onChange={handleChange}
          //   style={{ marginBottom: '16px' }}
          // />
          <FormControl fullWidth sx={{ marginBottom: 3 }}>
            <FormLabel
              sx={{
                fontSize: "16px",
                color: colors.primary,
                fontWeight: "bold",
              }}
            >
              Connector Type
            </FormLabel>
            <OutlinedInput
              value={formData.agent_type}
              onChange={handleChange}
              placeholder="Enter Connector Type"
              name="agent_type"
              sx={{
                borderRadius: 3,
                "& .MuiOutlinedInput-notchedOutline": { borderColor: colors.silver },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: colors.primary,
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: colors.primary,
                },
              }}
            />
          </FormControl>
        ) : (
          // <TextField
          //   select
          //   margin="dense"
          //   label="Agent Type"
          //   name="agent_type"
          //   fullWidth
          //   variant="outlined"
          //   value={formData.agent_type}
          //   onChange={handleChange}
          //   style={{ marginBottom: '16px' }}
          // >
          //   {AGENT_TYPES.map((option) => (
          //     <MenuItem key={option.value} value={option.value}>
          //       {option.label}
          //     </MenuItem>
          //   ))}
          // </TextField>
          <FormControl fullWidth sx={{ marginBottom: 3 }}>
            <FormLabel
              sx={{
                fontSize: "16px",
                color: "#000",
                fontWeight: "bold",
              }}
            >
              Connector Type
            </FormLabel>
            <Select
              value={formData.agent_type}
              name="agent_type"
              onChange={handleChange}
              MenuProps={{
                PaperProps: { style: { maxHeight: 200, width: "auto" } },
              }}
              sx={{
                borderRadius: 3,
                "& .MuiOutlinedInput-notchedOutline": { borderColor: colors.silver },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: colors.primary,
                },
              }}
            >
              {AGENT_TYPES.map((option) => (
               <MenuItem key={option.value} value={option.value}>
                 {option.label}
               </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {/* <TextField
          margin="dense"
          label="Prompt"
          name="prompt"
          fullWidth
          variant="outlined"
          value={formData.prompt}
          onChange={handleChange}
          multiline
          minRows={3}
          maxRows={5}
          style={{ marginBottom: '16px' }}
        /> */}
        <FormControl fullWidth sx={{ marginBottom: 3 }}>
          <FormLabel
            sx={{
              fontSize: "16px",
              color: colors.primary,
              fontWeight: "bold",
            }}
          >
            Prompt
          </FormLabel>
          <OutlinedInput
            value={formData.prompt}
            onChange={handleChange}
            placeholder="Enter Prompt"
            name="prompt"
            multiline
            minRows={3}
            maxRows={5}
            sx={{
              borderRadius: 3,
              "& .MuiOutlinedInput-notchedOutline": { borderColor: colors.silver },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: colors.primary,
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: colors.primary,
              },
            }}
          />
        </FormControl>
        {/* <TextField
          margin="dense"
          label="Config"
          name="config"
          fullWidth
          variant="outlined"
          value={formData.config}
          onChange={handleChange}
          multiline
          minRows={3}
          maxRows={5}
          style={{ marginBottom: '16px' }}
        /> */}
        <FormControl fullWidth sx={{ marginBottom: 3 }}>
          <FormLabel
            sx={{
              fontSize: "16px",
              color: colors.primary,
              fontWeight: "bold",
            }}
          >
            Config
          </FormLabel>
          <OutlinedInput
            value={formData.config}
            onChange={handleChange}
            placeholder="Enter Config"
            name="config"
            multiline
            minRows={3}
            maxRows={5}
            sx={{
              borderRadius: 3,
              "& .MuiOutlinedInput-notchedOutline": { borderColor: colors.silver },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: colors.primary,
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: colors.primary,
              },
            }}
          />
        </FormControl>
      </DialogContent>

      <DialogActions style={{ justifyContent: "flex-end" }}>
        <Button onClick={onClose} variant="contained" style={{ borderRadius: '10px', padding: '5px 20px', background: colors.white, color: colors.black}}>
          Cancel
        </Button>
        <Button onClick={handleSubmit} sx={gradientButtonStyle} variant="contained" style={{ borderRadius: '10px', padding: '5px 20px' }}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateAgentDialog;