import React, { useState, useEffect, useCallback } from 'react';
import { List, ListItem, ListItemText, Typography, Box, TextField, Button, Tooltip,
    FormControl,
    FormLabel, 
    OutlinedInput } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import colors from '../../../components/styles/colors';
import Switch from '@mui/material/Switch';
import { getValidationTools } from '../../../components/Apis/AgentApi';
import AgentListTemplate from '../../../components/Constants/AgentList';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ToolsButton from '../../Tools/ToolsButton'

const tempAgent = {
    "Agent": []
}

const AgentsTab = (props) => {
    const [activeTool, setActiveTool] = useState('Enabled');
    const [activeAgent, setActiveAgent] = useState(null);
    const [tools, setToolList] = useState([]);
    const [agentList, setAgentList] = useState(tempAgent);
    const [enabledAgentList, setEnabledAgentList] = useState([]);
    const [apiAgent, setapiAgent] = useState();
    const [currentAgent, setSurrentAgent] = useState(null);
    const [validationReload, setValidationReload] = useState(null);
    
    // let currentAgent=null
    const validationReloadFunction = () => {
        setValidationReload(true)
        getValidationTools().then((res) => {
            if (res) {
                setapiAgent({ Agent: res });
            }
        });
        if (apiAgent?.Agent) {
            setAgentList(apiAgent);
            filterUniqueToolsByType(apiAgent);
            updateAgentListOnload();
        }
    };


    useEffect(() => {
        getValidationTools().then((res) => {
            if (res) {
                setapiAgent({ Agent: res });
            }
        });
    }, []);
    

    const handleClick = (id) => {
        setActiveTool(id);
    };

    useEffect(() => {
        setAgentList(apiAgent);
        if (apiAgent) {
            filterUniqueToolsByType(apiAgent);
            updateAgentListOnload();
        };
    }, [apiAgent]);

    const filterUniqueToolsByType = (config) => {
        const uniqueNames = new Set();
        uniqueNames.add("Enabled");
        uniqueNames.add("All")
        tools.map((tool) => uniqueNames.add(tool.name));
        
        const newToolList = Array.from(uniqueNames).map((name, index) => ({
            id: index + 1,
            name: name
        }));

        setToolList(newToolList);
    };

    const updateAgentListOnload = useCallback(() => {
        setAgentList((prevAgentList) => {
            if (!prevAgentList?.Agent) return prevAgentList;
    
            const updatedAgents = prevAgentList.Agent.map((agent) => {
                const isActive = props.agentData.validation_enabled.some(
                    (validation_enabled) => validation_enabled.id === agent.id
                );
                return {
                    ...agent,
                    status: isActive ? true : agent.status,
                };
            });
    
            return {
                ...prevAgentList,
                Agent: updatedAgents,
            };
        });
    }, [props.agentData.validation_enabled]);

    // useEffect(() => {
    //     updateAgentListOnload();
    //     filterUniqueToolsByType(agentList);
    // }, [updateAgentListOnload]);

    useEffect(() => {

        console.log('----^^^^^^^^^^^^^^useEffect^^^^^^^^^^^^^^^-----')
        if (apiAgent?.Agent) {
            setAgentList(apiAgent);
            filterUniqueToolsByType(apiAgent);
            updateAgentListOnload();
        }
    }, [apiAgent, updateAgentListOnload, validationReload]);
    

    const handleChange = (agentId) => {
        // Map through the Agent array and update the status of the clicked agent
        const updatedAgents = agentList.Agent.map((agent) => {
            if (agent.id === agentId) {
                return {
                    ...agent,
                    status: !agent.status // Toggle the status
                };
            }
            return agent; // Return agent as-is if it's not the one being toggled
        });
        setAgentList((prevAgentList) => {
            // Return the updated agentList with the modified Agent array
            return {
                ...prevAgentList,
                Agent: updatedAgents
            };
        });


        const enabledAgents = updatedAgents.filter(agent => agent.status);
        setEnabledAgentList(enabledAgents);
        const newAgentList = enabledAgents.map((agent) => {
            return {
                id: agent.id,
                config: agent.config
            };
        });
        handleAgentChange("validation_enabled", newAgentList);
    };


    const handleToolChange = (agentId) => {
        const updatedAgents = agentList.Agent.map((agent) => {
            if (agent.id === agentId) {
                setActiveAgent(true);
                console.log('current-agent in ---',agent)
                setSurrentAgent(agent)
            }
            return agent;
        });

        setAgentList((prevAgentList) => {
            // Return the updated agentList with the modified Agent array
            return {
                ...prevAgentList,
                Agent: updatedAgents
            };
        });


        const enabledAgents = updatedAgents.filter(agent => agent.status);
        setEnabledAgentList(enabledAgents);
        const newAgentList = enabledAgents.map((agent) => {
            return {
                id: agent.id,
                config: agent.config
            };
        });
        handleAgentChange("validation_enabled", newAgentList);
    };

    const handleAgentChange = (section, value) => {
        props.setAgentData(prevData => ({
            ...prevData,
            [section]: value
        }));
    };

    const filteredAgents = () => {
        if (!agentList?.Agent) return [];
        if (activeTool === 'Enabled') {
            return enabledAgentList; // Only enabled agents
        } else if (activeTool === 'All') {
            return agentList.Agent; // All agents
        } else {
            return agentList.Agent.filter(agent => agent.type.includes(activeTool));
        }
    };

    
    const onDragEnd = (result) => {
        const { source, destination } = result;
        if (!destination) return;
        const updatedAgents = [...filteredAgents()];
        const [movedItem] = updatedAgents.splice(source.index, 1);
        updatedAgents.splice(destination.index, 0, movedItem);
        setEnabledAgentList(updatedAgents);
    };

    return (
        <Box
            sx={{
                flexGrow: 1,
                padding: { xs: 1, sm: 2, md: 3 },
                height: { xs: 'auto', sm: 'auto', md: '85%' },
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                gap: 2
            }}
        >
            <Box
                sx={{
                    flex: 3,
                    padding: { xs: 1, sm: 1 },
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: { xs: 'auto', md: '70%' },
                    background: "#fff",
                    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
                    borderRadius: 3
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 3 }}>
                    <AccountCircleIcon sx={{ width: 56, height: 56, color: colors.silver }} />
                    <Typography sx={{ marginLeft: 2, fontSize: '20px', fontWeight: 'bold' }}>
                        {props.agentData.profile.Name || 'Name Surname'}
                    </Typography>
                    <Box sx={{ ml: 'auto' }}>
                        <ToolsButton validationReloadFunction={validationReloadFunction}/>
                    </Box>
                </Box>

                <Box sx={{
                    display: 'flex',
                    gap: 1,
                    flexDirection: { xs: 'column', md: 'row' },
                    maxHeight: '87%',
                    minHeight: '87%',
                }}>
                    <Box sx={{ border: `1px solid ${colors.border}`, width: { xs: '95%', md: '26%' }, padding: 1, borderRadius: 3 }}>
                        <p style={{ fontSize: '12px' }}>Tool settings / All tools</p>
                        <List
                            sx={{
                                overflowY: 'auto',
                                paddingRight: '2px',
                                maxHeight: { md: '84%' },
                                '&::-webkit-scrollbar': {
                                    width: '5px',
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    backgroundColor: colors.secondary,
                                    borderRadius: '10px',
                                },
                                '&::-webkit-scrollbar-track': {
                                    backgroundColor: 'transparent',
                                },
                            }}
                        >
                            {tools.map((tool) => (
                                <ListItem
                                    button
                                    key={tool.id}
                                    onClick={() =>  handleClick(tool.name)}
                                    sx={{
                                        borderRadius: '10px',
                                        marginBottom: '3px',
                                        bgcolor: activeTool === tool.name ? `${colors.primary}40` : `${colors.primary}10`,
                                    }}
                                >
                                    <ListItemText primary={tool.name} />
                                </ListItem>
                            ))}
                        </List>
                    </Box>

                    <Box padding={1} sx={{
                        overflowY: 'auto',
                        flex: 3,
                        minHeight: '65%',
                        border: `1px solid ${colors.border}`,
                        width: { xs: '100%', md: '55%' },
                        background: `#fff`,
                        borderRadius: 3
                    }}>
                        <p style={{ fontSize: '12px' }}>Equip your agent with new connectors</p>
                        <DragDropContext onDragEnd={onDragEnd}>
    <Droppable droppableId="enabled-agents">
        {(provided) => (
            <List
                sx={{
                    overflowY: 'auto',
                    paddingRight: '2px',
                    maxHeight: { md: '84%' },
                }}
                ref={provided.innerRef}
                {...provided.droppableProps}
            >
                {filteredAgents().map((agent, index) => (
                    <Draggable key={agent.id} draggableId={agent.id.toString()} index={index}>
                        {(provided) => (
                            <ListItem
                                button
                                onClick={() => handleToolChange(agent.id)} // Call handleToolChange on click
                                sx={{
                                    borderRadius: '10px',
                                    marginBottom: '3px',
                                }}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                            >
                                <ListItemText 
                                    primary={ 
                                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}  >
                                            {agent.name}
                                        </Typography>
                                    }
                                    secondary={
                                        <Tooltip title={agent.description}>
                                            <Typography
                                                variant="body2"
                                                sx={{
                                                    maxWidth: '100%',
                                                    display: '-webkit-box',
                                                    WebkitBoxOrient: 'vertical',
                                                    overflow: 'hidden',
                                                    WebkitLineClamp: 1,
                                                    textOverflow: 'ellipsis',
                                                }}
                                            >
                                                {agent.description}
                                            </Typography>
                                        </Tooltip>
                                    }
                                />
                                <Switch
                                    checked={agent.status}
                                    onChange={(event) => handleChange(agent.id)}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            </ListItem>
                        )}
                    </Draggable>
                ))}
                {provided.placeholder}
            </List>
        )}
    </Droppable>
</DragDropContext>
                    </Box>
                </Box>
            </Box>

            <Box
                sx={{
                    overflowY: 'scroll',
                    flex: 1,
                    padding: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '70%',
                    alignItems: 'center',
                    background: "#fff",
                    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
                    borderRadius: 3
                }}
            >
                {(currentAgent) ? (
                     
                    <Typography variant="body2">
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                margin: 'auto',
                            }}
                        >
                            <Typography gutterBottom style={{ fontSize: '20px', fontWeight: 'bold', alignSelf: 'center' }}>
                                Tool: {currentAgent.name}
                            </Typography>
                            
                            <FormControl fullWidth sx={{ marginBottom: 3 }}>
                                <FormLabel
                                    sx={{
                                    fontSize: "16px",
                                    color: colors.primary,
                                    fontWeight: "bold",
                                    }}
                                >
                                    Name
                                </FormLabel>
                                <OutlinedInput
                                    value={currentAgent.name}
                                    // onChange={handleChange}
                                    placeholder="Enter Description"
                                    name="description"
                                    minRows={3}
                                    maxRows={5}
                                    sx={{
                                    borderRadius: 3,
                                    "& .MuiOutlinedInput-notchedOutline": { borderColor: colors.silver },
                                    "&:hover .MuiOutlinedInput-notchedOutline": {
                                        borderColor: colors.primary,
                                    },
                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                        borderColor: colors.primary,
                                    },
                                    }}
                                />
                            </FormControl>
                            
                            <FormControl fullWidth sx={{ marginBottom: 3 }}>
                                <FormLabel
                                    sx={{
                                    fontSize: "16px",
                                    color: colors.primary,
                                    fontWeight: "bold",
                                    }}
                                >
                                    Description
                                </FormLabel>
                                <OutlinedInput
                                    value={currentAgent.description}
                                    // onChange={handleChange}
                                    placeholder="Enter Description"
                                    name="description"
                                    multiline
                                    minRows={3}
                                    maxRows={5}
                                    sx={{
                                    borderRadius: 3,
                                    "& .MuiOutlinedInput-notchedOutline": { borderColor: colors.silver },
                                    "&:hover .MuiOutlinedInput-notchedOutline": {
                                        borderColor: colors.primary,
                                    },
                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                        borderColor: colors.primary,
                                    },
                                    }}
                                />
                            </FormControl>
                            <FormControl fullWidth sx={{ marginBottom: 3 }}>
                                <FormLabel
                                    sx={{
                                    fontSize: "16px",
                                    color: colors.primary,
                                    fontWeight: "bold",
                                    }}
                                >
                                    Prompt
                                </FormLabel>
                                <OutlinedInput
                                    value={currentAgent.prompt}
                                    // onChange={handleChange}
                                    placeholder="Enter Description"
                                    name="description"
                                    multiline
                                    minRows={3}
                                    maxRows={5}
                                    sx={{
                                    borderRadius: 3,
                                    "& .MuiOutlinedInput-notchedOutline": { borderColor: colors.silver },
                                    "&:hover .MuiOutlinedInput-notchedOutline": {
                                        borderColor: colors.primary,
                                    },
                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                        borderColor: colors.primary,
                                    },
                                    }}
                                />
                            </FormControl>

                            

                            <Button
                                variant="contained"
                                fullWidth
                                sx={{
                                    background: `linear-gradient(180deg, ${colors.primary}, ${colors.secondary})`,
                                    color: 'white',
                                    marginTop: 2,
                                    padding: '10px 0',
                                    borderRadius: '30px',
                                    fontWeight: 'bold',
                                }}
                            >
                                Save
                            </Button>
                        </Box>
                    </Typography>
                ) : (
                    <>
                        <Typography sx={{ marginBottom: 2, fontWeight: 'bold' }}>
                            Suggestions
                        </Typography>
                        <Typography variant="body2" sx={{ fontSize: '14px' }}>
                            <p>Here is a list of third-party and in-house tools available.</p>
                            <p>Your agent can use these tools by enabling them.</p>
                            <p>Some tools require setup of credentials and configuration.</p>
                        </Typography>
                    </>
                )}
            </Box>
        </Box>
    );
};

export default AgentsTab;