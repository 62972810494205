import React from 'react';
import { Box, Button, Grid, Typography, Chip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import colors from '../../components/styles/colors';
import HandymanIcon from '@mui/icons-material/Handyman';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import CreateAgentDialog from './createToolDialog';
import { useGlobalVarible } from '../../components/Constants/globalContextModel';

const agents = [
  { "title": "Google Bucket", "description": "Allow file operations with GCP buckets, including upload, download, and management of files." },
  { "title": "Gmail", "description": "Integration with Gmail features for sending, receiving, and organizing emails programmatically." },
  { "title": "Google Map", "description": "Provide location services, geocoding, and real-time mapping capabilities." },
  { "title": "Outlook", "description": "Integration with Microsoft Outlook to manage emails, calendars, and tasks." },
  { "title": "SAP", "description": "Connect with SAP systems for data retrieval, updates, and other ERP-related operations." },
  { "title": "AWS S3", "description": "Perform file operations on AWS S3, such as uploading, downloading, and managing data." },
  { "title": "API Connector", "description": "Integrate with external APIs to send and receive data dynamically." },
  { "title": "SQL Connector", "description": "Enable seamless connection with SQL databases for executing queries and data manipulation." },
  { "title": "FTP", "description": "Facilitate file transfers between local systems and FTP servers securely." }
]
;

// const categories = ['All categories', 'Popular', 'Marketing', 'Research', 'Sales', 'Integrations'];
const categories = [];


const gradientButtonStyle = {
  background: `linear-gradient(180deg, ${colors.primary} 60%, ${colors.secondary} 90%)`,
  color: colors.white,
  '&:hover': {
    background: `linear-gradient(180deg, ${colors.primary} 60%, ${colors.secondary} 90%)`,
    opacity: 0.8,
  },
};

const Tools = () => {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const { view, anotherVar } = useGlobalVarible();

  return (
    <div style={{ padding: '40px 30px' }}>
      <h2>Create Connectors <HandymanIcon style={{ fontSize: 30, verticalAlign: 'bottom' }} /></h2>
      <Box
        sx={{
          p: 2,
          mb: 4,
          backgroundColor: view === 1 ? colors.containerBG : colors.lightgrey,
          boxShadow: 4,
          borderRadius: 5,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          textAlign: 'center',
          width: '30%'
        }}
        onClick={() => setDialogOpen(true)}
      >
        <Typography sx={{ mb: 1, fontWeight: 'bold', fontSize: '20px' }}>Build it Yourself</Typography>
        <Typography variant="body1" sx={{ mb: 2, color: colors.black }}>Build your own custom connector</Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          sx={gradientButtonStyle}
          style={{ borderRadius: '20px' }}
        >
          Create Connectors
        </Button>
      </Box>
      <CreateAgentDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      />
      <h2>Our Tool Templates <ViewCarouselIcon style={{ fontSize: 30, verticalAlign: 'bottom' }} /></h2>
      <Box sx={{ mb: 3 }}>
        {categories.map((category, index) => (
          <Chip
            key={index}
            label={category}
            clickable
            sx={{ m: 0.5, fontSize: '14px', fontWeight: 500 }}
          />
        ))}
      </Box>

      {/* Agents List */}
      <Grid container spacing={2}>
        {agents.map((agent, index) => (
          <Grid item xs={12} sm={6} md={4} key={index} sx={{ display: 'flex' }}>
            <Box
              sx={{
                p: 2,
                backgroundColor: view === 1 ? colors.containerBG : colors.lightgrey,
                boxShadow: 4,
                borderRadius: 5,
                display: 'flex',
                flexDirection: 'column',
                maxWidth: '400px',
                width: '100%',
                margin: 0
              }}
            >
              <Box>
                <Typography sx={{ fontWeight: 'bold', fontSize: '20px' }} gutterBottom>
                  {agent.title}
                </Typography>
                <Typography variant="body2" color="textSecondary" sx={{color:colors.black}}>
                  {agent.description}
                </Typography>
              </Box>
              <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-start', padding: '0px 5px', gap: 1 }}>
                <Button
                  variant="contained"
                  size="small"
                  sx={gradientButtonStyle}
                  startIcon={<AddIcon />}
                  style={{ borderRadius: '10px', padding: '5px 20px' }}
                >
                  Use
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  style={{ borderRadius: '10px', padding: '5px 20px', background: colors.white, color: colors.black }}
                >
                  Modify
                </Button>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default Tools;
