import React, { useState, useEffect, useRef } from 'react';
import './chatBot.css';
import Modal from 'react-modal';
import SendIcon from '@material-ui/icons/Send';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import colors from '../../components/styles/colors';

import commentIcon from './uploads/message.svg'
import voiceIcon from './uploads/voiceIcon.svg'
import { useChatSocket } from '../Apis/websocket';
import { useGlobalVarible } from '../Constants/globalContextModel';
import { VoiceChat } from './voiceChat';
import CallIcon from '@mui/icons-material/Call';
import CallEndIcon from '@mui/icons-material/CallEnd';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

Modal.setAppElement('#root');

const Cbot = () => {

    const MaterialUISwitch = styled(Switch)(({ theme }) => ({
        width: 62,
        height: 34,
        padding: 7,
        '& .MuiSwitch-thumb': {
            backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
            width: 32,
            height: 32,
            '&::before': {
                content: "''",
                position: 'absolute',
                left: 0,
                top: 0,
                backgroundImage: `url(${commentIcon})`,
                filter: 'brightness(0) invert(1)',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                width: '60%',
                height: '60%',
                margin: '6px',
                id: 'msgIcon',
            },
        },
        '& .MuiSwitch-switchBase': {
            margin: 1,
            padding: 0,
            transform: 'translateX(6px)',
            '&.Mui-checked': {
                color: '#fff',
                transform: 'translateX(22px)',
                '& .MuiSwitch-thumb:before': {
                    backgroundImage: `url(${voiceIcon})`,
                    filter: 'brightness(0) invert(1)',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center center',
                    width: '60%',
                    height: '60%',
                    margin: '6px',
                    id: 'voiceIcon',
                },
                '& + .MuiSwitch-track': {
                    opacity: 1,
                    backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
                },
            },
        },
        '& .MuiSwitch-track': {
            opacity: 1,
            backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
            borderRadius: 20 / 2,
        },
    }));

    const [inputValue, setInputValue] = useState('');
    const [messages, setMessages] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [isTyping, setIsTyping] = useState(false);
    const [recordedAudio, setRecordedAudio] = useState(null);
    const [q_response, setQ_response] = useState('');
    const messagesEndRef = useRef(null);
    const [agentName, setagentName] = useState('Select Agent');
    const [checked, setChecked] = useState(false);
    const { agentid, setagentid } = useGlobalVarible(0); // Get the agent id from the global context
    const [first_message, setfirst_message] = useState(true);
    const [startSpeaking, setstartSpeaking] = useState(false);
    const [startRecord, setStartRecord] = useState(false);
    const [isGenerating, setIsGenerating] = useState(false);
    const [isCalling, setIsCalling] = useState(false);

    const error_fillers = [
        'error_message_one.mp3',
        'error_message_two.mp3',
    ]

    const startTimers = () => {
    }


    // Function to fetch the audio file and convert to Base64
    const fetchAndConvertAudio = async (audiofile) => {
        try {
            // Fetch the audio file from the public directory
            const response = await fetch(`${process.env.PUBLIC_URL}/fillers/` + audiofile);
    
            // Convert the response to a Blob (binary large object)
            const audioBlob = await response.blob();
    
            // Return a promise that resolves with the Base64 string
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    const base64String = reader.result; // Base64 string
                    const parts = base64String.split(',');
                    resolve([parts[1], parts[0]]); // Resolve with Base64 string and metadata
                };
                reader.onerror = reject; // Reject the promise on error
    
                // Read the audio Blob as a Data URL (Base64)
                reader.readAsDataURL(audioBlob);
            });
        } catch (error) {
            console.error("Error fetching or converting audio:", error);
            throw error; // Re-throw the error to be handled by the caller
        }
    };


    const onmesg = (incommingMessage) => {
        console.log(incommingMessage);
        switch (incommingMessage.type) {
            case 'message':
                const q_response = incommingMessage.data;
                setMessages((prevMessages) => [
                    ...prevMessages,
                    { type: 'bot', text: q_response, sender: 'HR Bot' },
                ]);
                if (incommingMessage.imageUrl) {
                    // const map_items = incommingMessage.imageUrl.map((item, index) => {
                    //     return { type: 'bot', imageUrl: item, sender: 'HR Bot', index: 0 };
                    // });
                    const map_items = { type: 'bot', imageUrl: incommingMessage.imageUrl, sender: 'HR Bot', index: 0 };
                    // Use functional update to ensure the latest state is used
                    setMessages((prevMessages) => [
                        ...prevMessages,
                        map_items
                    ]);
                }

                if (incommingMessage.Map_Link && incommingMessage.Map_Link !== '') {
                    // Functional update again to maintain the previous state changes
                    setMessages((prevMessages) => [
                        ...prevMessages,
                        { type: 'bot', link: incommingMessage.Map_Link, sender: 'HR Bot' },
                    ]);
                }

                console.log(messages); // This will still log the old state because state updates are async
                break;

            case 'connection':
                console.log('Connection message:', incommingMessage.data);
                break;

            case 'input_text':
                const q_message = { type: 'user', text: incommingMessage.data, sender: 'You' }
                setMessages([
                    ...messages,
                    q_message
                ]);
                break;

            case 'response':
                const response_message = { type: 'bot', text: incommingMessage.data, sender: 'HR Bot' }
                setMessages([
                    ...messages,
                    response_message
                ]);
                break;

            case 'audio':
                console.log('Received audio data');
                receivedAudio(incommingMessage.data, 'audio/mpeg')
                break;

            case 'generating':
                console.log('Generating response');
                setIsGenerating(true);
                break;

            case 'generating':
                console.log('Generating response');
                setIsGenerating(true);
                break;

            case 'started':
                setstartSpeaking(true);
                console.log(!startSpeaking, isGenerating, !startRecord)
                break;

            case 'error':
                const randomIndex = Math.floor(Math.random() * error_fillers.length);
                const selectedAudio = error_fillers[randomIndex];            
                fetchAndConvertAudio(selectedAudio)
                    .then(([b64data, metadata]) => {
                        receivedAudio(b64data, metadata);
                    })
                    .catch(error => {
                        console.error("Error processing audio:", error);
                    });
                break;

            default:
                console.warn('Unknown message type:', incommingMessage.type);
                setIsGenerating(false);
        }
    }

    const { sendMessage, readyState } = useChatSocket(onmesg);
    const [userRecording, setUserRecording] = useState(false);
    const { startRecording, stopRecording, receivedAudio, convertBase64ToBlob } = VoiceChat(first_message, setfirst_message, startSpeaking, setStartRecord, sendMessage, setUserRecording);
    useEffect(() => {
        console.log("userRecording", userRecording);
    }, [userRecording]);
    const handleCallIconClick = () => {
        setIsCalling((prev) => !prev);
        if (!isCalling) {
            startRecording();
        }
        else {
            stopRecording();
        }
    };
    const ToggleMsgOrVoice = () => {
        setChecked(!checked);
    }

    const openInNewTab = (url) => {
        window.open(url, "_blank", "noreferrer");
    };

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
        setIsTyping(e.target.value.trim() !== '');
    };

    const handleSendMessage = async () => {
        if (inputValue.trim() !== '') {
            const userMessage = { type: 'message', data: inputValue };
            const q_message = { type: 'user', text: inputValue, sender: 'You' }
            sendMessage(JSON.stringify(userMessage));
            setMessages([
                ...messages,
                q_message
            ]);
            setInputValue('');
            setIsTyping(false);
        }
    };

    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages]);

    useEffect(() => {
        if (recordedAudio) {
            const userAudioMessage = {
                type: 'user',
                audio: recordedAudio,
                sender: 'You'
            };

            setMessages([...messages, userAudioMessage]); // Add recorded audio message and bot message to messages state
            setRecordedAudio(null); // Reset recorded audio state
        }
    }, [recordedAudio, messages]);

    useEffect(() => {
        if (agentid) {
            const messageData = { type: 'model', data: agentid.agent_id };
            sendMessage(JSON.stringify(messageData));
            setMessages([]);
            setagentName(agentid.agent_name);

        }
    }, [agentid]);

    useEffect(() => {
        if (q_response !== '') {
            const botMessage = {
                type: 'bot',
                audio: q_response,
                sender: 'HR Bot'
            };

            setMessages([...messages, botMessage]); // Add recorded audio message and bot message to messages state
            setRecordedAudio(null); // Reset recorded audio state
        }
    }, [q_response]);

    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSendMessage();
        }
    };

    // Handle next button click
    const handleNext = (messageIndex) => {
        setMessages((prevMessages) =>
            prevMessages.map((msg, idx) =>
                idx === messageIndex
                    ? {
                        ...msg,
                        index: msg.index === msg.imageUrl.length - 1 ? msg.index : msg.index + 1,
                    }
                    : msg
            )
        );
    };

    // Handle previous button click
    const handlePrevious = (messageIndex) => {
        setMessages((prevMessages) =>
            prevMessages.map((msg, idx) =>
                idx === messageIndex
                    ? {
                        ...msg,
                        index: msg.index > 0 ? msg.index - 1 : 0,
                    }
                    : msg
            )
        );
    };
    return (
        <div className="app">
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                className="chat-modal"
                overlayClassName="chat-overlay"
            >
                <div className="chat-container">
                    <div className="header" style={{
                        display: 'flex', position: 'relative', padding: '10px', background: `linear-gradient(to right, ${colors.primary}, ${colors.secondary})`,
                        color: 'white',
                        fontWeight: 'bold',
                        height: '8%'
                    }}>
                        <div className="avatar">
                            <img src="./botIcons/header-logo.svg" alt="Robot Avatar" />
                        </div>
                        <div className="bot-name">{agentName}</div>


                        {isCalling ? (
                            <CallEndIcon
                                sx={{ position: 'absolute', right: '60px', top: '25px', cursor: 'pointer', color: 'red' }}
                                onClick={handleCallIconClick}
                            />
                        ) : (
                            <CallIcon
                                sx={{ position: 'absolute', right: '60px', top: '25px', cursor: 'pointer' }}
                                onClick={handleCallIconClick}
                            />
                        )}

                        <button fontSize='18px' className="close-button" onClick={closeModal}>
                            &times;
                        </button>
                    </div>
                    <div className="messages">
                        {messages.map((message, index) => (
                            <div key={index} className={`message-container ${message.type}`}>
                                <div className={`icon ${message.type}`} style={{ backgroundColor: 'white', marginLeft: '10px', marginRight: '5px' }}>
                                    {message.type === 'user' && (
                                        <div className='user-icon'><AccountCircleIcon style={{ fontSize: 30, color: 'grey' }} /></div>
                                    )}
                                    {message.type === 'bot' && (
                                        <img src="./botIcons/header-logo.svg" alt="Bot Icon" />
                                    )}
                                </div>
                                <div className="message">
                                    {message.audio ? (
                                        <div className="audio-message">
                                            <audio controls>
                                                <source src={URL.createObjectURL(message.audio)} type="audio/wav" />
                                                Your browser does not support the audio element.
                                            </audio>
                                        </div>
                                    ) : message.imageUrl ? (
                                        <div className="image-message">
                                            <img src={message.imageUrl[message.index]} alt="Bot Image" />
                                            <button
                                                className="nav-btn nav-btn-prev"
                                                onClick={() => handlePrevious(index)}
                                            >
                                                <ChevronLeftIcon />
                                            </button>
                                            <button
                                                className="nav-btn nav-btn-next"
                                                onClick={() => handleNext(index)}
                                            >
                                                <ChevronRightIcon />
                                            </button>
                                        </div>
                                    ) : (
                                        <div>
                                            <div className="text" data-sender={message.sender}>
                                                {message.text}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}
                        <div ref={messagesEndRef} />
                    </div>

                    <div className="input-container" style={{ justifyContent: 'center' }}>
                        {isCalling ? (
                            <>
                                {startRecord ? (
                                    userRecording ? (
                                        <img
                                            src="/icons/mickIconRed.gif"
                                            alt="Recording"
                                            style={{ width: '60px', height: '60px', cursor: 'pointer' }}
                                        />

                                    ) : (
                                        <img
                                            src="/icons/mickIcon.gif"
                                            alt="Recording"
                                            style={{ width: '60px', height: '60px', cursor: 'pointer' }}
                                        />

                                    )
                                ) : (
                                    <HourglassBottomIcon sx={{ fontSize: '40px', cursor: 'pointer' }} />
                                )}
                            </>
                        ) : (
                            <>
                                <input
                                    type="text"
                                    placeholder="Enter your message"
                                    value={inputValue}
                                    onChange={handleInputChange}
                                    onKeyPress={handleKeyPress}
                                />
                                <button onClick={handleSendMessage}>
                                    <SendIcon sx={{ color: 'blue' }} />
                                </button>
                            </>
                        )}
                    </div>

                </div>
            </Modal>
            <div className="chat-icon" onClick={openModal} >
                {modalIsOpen ? (
                    <img src="./botIcons/bot-logo.svg" alt="Chat Icon" />
                ) : (
                    <img
                        src="/botIcons/botIcon.gif"
                        alt="Chat Icon"
                        style={{ width: '75px', height: '75px', cursor: 'pointer' }}
                    />

                )}
            </div>

        </div>
    );
};

export default Cbot;
