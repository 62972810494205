const AgentTemplate = {
    profile: {
        Name: "",
        Description: "",
        ProfileImage: "",
        WorkflowAgent: false, 
        Template: "",
        VoiceBot: {
            Required: false,
            Voice: ""
        }
    },
    BehaviourSettings: {
        Details: ""
    },
    OperationalWorkflow:{
        FlowConditions:[]
    },
    agents_enabled: [],
    validation_enabled: [],
    ReferanceDocument: {
        files: [],
        url: [],
        text: []
    },
    AccessAndPermissions: [],
    Validation: [],
};

export default AgentTemplate;