import React from 'react';
import { Box } from '@mui/material';
import colors from '../styles/colors';

const GradientCard = ({ width, height, children }) => {
  return (
    <Box
      sx={{
        width: { xs: '100%', sm: width || '300px' },
        height: { xs: 'auto', sm: height || '200px' },
        borderRadius: '16px',
        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
        background: `linear-gradient(45deg, ${colors.primary}, ${colors.secondary})`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: colors.white,
        textAlign: 'center',
        padding: '20px',
        overflow: 'hidden', // Ensure content doesn't overflow the card
      }}
    >
      <Box sx={{ width: '100%', height: '100%' }}> {/* Ensure the children fully expand */}
        {children}
      </Box>
    </Box>
  );
};

export default GradientCard;
