import React, { useState } from 'react';
import LeftNavbar from './components/Navbar/LeftNavbar';
import TopNavbar from './components/Navbar/TopNavbar';
import { Outlet } from "react-router-dom";
import Box from '@mui/material/Box';
import { useMediaQuery } from '@mui/material';
import ChatBot from './components/ChatBot/ChatBot';
import { useGlobalVarible } from './components/Constants/globalContextModel';


function App() {

  const isSmallerScreen = useMediaQuery('(max-width:1000px), (max-height:600px)');
  const isMediumScreen = useMediaQuery('(min-width:1000px) and (max-width:1500px)');
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { view, anotherVar } = useGlobalVarible();

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  React.useEffect(() => {
    if (isMediumScreen) {
      setIsCollapsed(true);
    } else {
      setIsCollapsed(false);
    }
  }, [isSmallerScreen, isMediumScreen]);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  return (
    <Box>
      <Box
        sx={{
          ...(view === 1
            ? {
              border: '1px solid #000',
              margin: '1%',
              height: '95vh',
              borderRadius: '30px',
              overflowY: 'scroll',
              scrollbarWidth: 'none',
              '&::-webkit-scrollbar': {
                display: 'none',
              },
            }
            : {
              // Styles for view 2
              overflowY: 'auto',
              scrollbarWidth: 'thin',
              '&::-webkit-scrollbar': {
                display: 'block',
              },
            }),
        }}
      >
        <Box sx={{ display: 'flex', overflow: 'hidden' }}>
          <LeftNavbar />
          <Box sx={{ display: 'block', overflow: 'scroll', width:'100%', background:"#F5F5F9" }}>
            <Box sx={{ position: 'sticky', top: 0, zIndex: 1000 }}>
              <TopNavbar />
            </Box>
            <ChatBot />
            {/* <Agents/> */}
            {/* <Dashboard/> */}
            {/* <LeftNavbar isDrawerOpen={isDrawerOpen} isCollapsed={isCollapsed} toggleDrawer={toggleDrawer} /> */}
            {/* <TopNavbar toggleDrawer={toggleDrawer} toggleCollapse={toggleCollapse} /> */}
            <Box component="main" 
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              height: 'calc(93vh - 50px)',
              width: 'calc(83vw - 10px)',
            }}>
              <Outlet />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default App;
