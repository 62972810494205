import { color } from "chart.js/helpers";
import React from "react";
import Chart from "react-apexcharts";

const StackedBarChart = ({width, height}) => {
  const options = {
    chart: {
      type: 'bar',
      stacked: true,
    },
    colors: ['#FC9718', '#FF37A0', '#FF272C', '#d8d8d8'],
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          enabled: false,
        },
        background: {
          colors: ['#d8d8d8'],
        },
      },
    },
    stroke: {
      width: 0,
    },
    xaxis: {
      categories: ['Doc-Extraction', 'Automobile', 'HR Bot'],
      axisBorder: {
        show: false,
      },
    },
    yaxis: {
      title: {
        text: undefined,
      },
      axisBorder: {
        show: false,
      },
    },
    tooltip: {
      theme: 'dark',
      style: {
        color: '#000', 
      },
      background: {
        color: '#fff' 
      }
    },
    
    fill: {
      opacity: 1,
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
  };
  

  const series = [
    {
      name: 'Usage 1',
      data: [44, 55, 41]
    },
    {
      name: 'Usage 2',
      data: [13, 23, 20]
    },
    {
      name: 'Usage 3',
      data: [11, 17, 15]
    },
  ];

  return (
    <div style={{ width: width, height: height }}> 
      <Chart options={options} series={series} type="bar" width='100%' height='100%' sx={{color:'#000'}} /> {/* Set width and height to 100% */}
    </div>
  );
};

export default StackedBarChart;
