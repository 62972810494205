const colors = {

    // Basic
    // primary: '#013935',
    // secondary: '#0F897F',
    // primary: '#0867d2',
    // secondary: '#0984e3',
    primary: '#432F60',
    secondary: '#9E3C5D',
    white: '#ffffff',
    black: '#000000',
    offwhite:'#FAF9F6',

    // Navbar
    listItemBG: '#0033cc',
    listItemActive: '#9E3C5D',
    divider: '#77A2A2',
    navHeadings: '#daf0e3',

    //TopNavbar
    border: '#cccccc',

    //Agent
    grey: '#f2f2f2',
    containerBG: '#e3f2fd',

    //Agent Profile
    silver: '#C0C0C0',
    lightBlue:'#6cbcf9',
    lightgrey:'#F2F2F2'
    
};
export default colors;
