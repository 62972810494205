import React, { useState } from 'react';
import { Typography, Box, Tabs, Tab, Button, TextField } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import colors from '../../../components/styles/colors';
import { styled } from '@mui/system';
import AddIcon from '@mui/icons-material/Add';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DescriptionIcon from '@mui/icons-material/Description';
import ImageIcon from '@mui/icons-material/Image';
import ZipFileIcon from '@mui/icons-material/Archive';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import SaveIcon from '@mui/icons-material/Save';

const CustomTabs = styled(Tabs)({
    boxShadow: 'none',
    '& .MuiTabs-indicator': {
        backgroundColor: colors.containerBG,
    },
});

const CustomTab = styled(Tab)(({ theme }) => ({
    textTransform: 'none',
    fontWeight: 'bold',
    minWidth: 0,
    '&.Mui-selected': {
        color: colors.black,
    },
}));

const CustomIndicator = styled(Box)({
    height: '10px',
    background: `linear-gradient(to right, ${colors.primary}, ${colors.secondary}, ${colors.primary})`,
    position: 'absolute',
    bottom: -8,
});

const gradientButtonStyle = {
    background: `linear-gradient(180deg, ${colors.primary} 60%, ${colors.secondary} 90%)`,
    color: colors.white,
    '&:hover': {
        background: `linear-gradient(180deg, ${colors.primary} 60%, ${colors.secondary} 90%)`,
        opacity: 0.8,
    },
};

const ReserenceDoc = (props) => {
    // tab variables
    const [selectedTab, setSelectedTab] = useState(0);
    const tabContent = [{ label: "Documents" }, { label: "URL" }, { label: "Text Data" }
    ];
    const [selectedFileIndex, setSelectedFileIndex] = useState(null);
    const handleBoxClick = (index) => {
        setSelectedFileIndex(selectedFileIndex === index ? null : index);
    };
    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };
    const handleFileUpload = (event) => {
        const files = Array.from(event.target.files);
        props.setAgentData((preagentData) => {
            return {
                ...preagentData,
                ReferanceDocument: { ...preagentData.ReferanceDocument, files: [...preagentData.ReferanceDocument.files, ...files] }
            }
        }
        );
    };
    const triggerFileUpload = () => {
        document.getElementById('file-upload').click();
    };
    const getFileIcon = (file) => {
        let extension = null;
        if (file.name){
            extension = file.name.split('.').pop().toLowerCase();
        } else {
            extension = file.split('.').pop().toLowerCase();
        }
        
        switch (extension) {
            case 'pdf':
                return <PictureAsPdfIcon sx={{ fontSize: '70px', color: colors.black }} />;
            case 'png':
            case 'jpg':
            case 'jpeg':
            case 'bmp':
            case 'gif':
            case 'webp':
                return <ImageIcon sx={{ fontSize: '70px', color: colors.black }} />;
            case 'zip':
                return <ZipFileIcon sx={{ fontSize: '70px', color: colors.black }} />;
            case 'doc':
            case 'docx':
                return <DescriptionIcon sx={{ fontSize: '70px', color: colors.black }} />;
            default:
                return null;
        }
    };
    const handleEditFile = (index) => {
        console.log('Edit file:', props.agentData.ReferanceDocument.files[index]);
    };
    const handleDeleteFile = (index) => {
        props.setAgentData((preagentData) => {
            return {
                ...preagentData,
                ReferanceDocument: { ...preagentData.ReferanceDocument, files: preagentData.ReferanceDocument.files.filter((_, i) => i !== index) }
            }
        });
    };
    const renderUploadedFiles = () => {
        return props.agentData.ReferanceDocument.files.map((file, index) => (
            <Box
                key={index}
                sx={{ display: 'inline-flex', flexDirection: 'column', alignItems: 'center', marginRight: 2, cursor: 'pointer', position: 'relative', }}
                onClick={() => handleBoxClick(index)}
            >
                <span>
                    {getFileIcon(file)}
                </span>

                <Tooltip title={(file.name? file.name : file)} arrow>
                    <Typography variant="body2" sx={{ fontSize: '0.75rem', marginTop: 1 }}>
                        {(file.name? file.name.length : file.length) > 10 ? `${(file.name? file.name.slice(0, 7) : file.slice(0, 7))}...` : (file.name? file.name : file)} {/* Truncate long file names */}
                    </Typography>
                </Tooltip>

                {/* Show Edit/Delete buttons only when the box is clicked */}
                {selectedFileIndex === index && (
                    <Box sx={{ display: 'flex', gap: 1, marginTop: 0.5 }}>
                        <IconButton size="small" onClick={() => handleEditFile(index)}>
                            <EditIcon fontSize="small" sx={{ color: colors.primary }} />
                        </IconButton>
                        <IconButton size="small" onClick={() => handleDeleteFile(index)}>
                            <DeleteIcon fontSize="small" sx={{ color: colors.primary }} />
                        </IconButton>
                    </Box>
                )}
            </Box>
        ));
    };


    //URL Tab constants and functions
    // const initialURLDatas = [" https://en.wikipedia.org/wiki/Intelligent_agent"];
    // const [urlItems, setUrlItems] = useState(initialURLDatas);
    const [editUrlIndex, setEditUrlIndex] = useState(null);
    const [editUrl, setEditUrl] = useState("");
    const [isAddingNewUrl, setIsAddingNewUrl] = useState(false);
    const handleUrlEditClick = (index) => {
        setEditUrlIndex(index);
        // setEditUrl(urlItems[index]);
        setEditUrl(props.agentData.ReferanceDocument.url[index]);
        setIsAddingNewUrl(false);
    };
    // Handle when text changes during editing
    const handleUrlEditChange = (event) => {
        setEditUrl(event.target.value);
    };
    // Handle saving the edited text or new item
    const handleUrlSave = () => {
        if (!editUrl.trim()) {
            return;
        }
        if (isAddingNewUrl) {
            // setUrlItems([...urlItems, editUrl]);
            props.setAgentData((preagentData) => {
                return {
                    ...preagentData,
                    ReferanceDocument: { ...preagentData.ReferanceDocument, url: [...preagentData.ReferanceDocument.url, editUrl] }
                }
            });
            setIsAddingNewUrl(false);
        } else {
            const updatedItems = [...props.agentData.ReferanceDocument.url];
            updatedItems[editUrlIndex] = editUrl;
            // setUrlItems(updatedItems);
            props.setAgentData((preagentData) => {
                return {
                    ...preagentData,
                    ReferanceDocument: { ...preagentData.ReferanceDocument, url: updatedItems }
                }
            });
        }
        setEditUrlIndex(null);
        setEditUrl("");
    };
    const handleUrlDelete = (index) => {
        if (isAddingNewUrl && index === props.agentData.ReferanceDocument.url.length) {
            setIsAddingNewUrl(false);
            setEditUrl("");
            setEditUrlIndex(null);
        } else {
            const updatedItems = props.agentData.ReferanceDocument.url.filter((_, i) => i !== index);
            // setUrlItems(updatedItems);
            props.setAgentData((preagentData) => {
                return {
                    ...preagentData,
                    ReferanceDocument: { ...preagentData.ReferanceDocument, url: updatedItems }
                }
            });
        }
    };
    const handleAddNewUrl = () => {
        setIsAddingNewUrl(true);
        setEditUrl("");
        setEditUrlIndex(props.agentData.ReferanceDocument.url.length);
    };


    //Text Tab constants and functions
    // const [textItems, setTextItems] = useState(initialTextData);
    const [editIndex, setEditIndex] = useState(null);
    const [editText, setEditText] = useState("");
    const [isAddingNewItem, setIsAddingNewItem] = useState(false);
    const handleEditClick = (index) => {
        setEditIndex(index);
        setEditText(props.agentData.ReferanceDocument.text[index]);
        setIsAddingNewItem(false);
    };
    // Handle when text changes during editing
    const handleEditChange = (event) => {
        setEditText(event.target.value);
    };
    // Handle saving the edited text or new item
    const handleTextSave = () => {
        if (!editText.trim()) {
            return;
        }
        if (isAddingNewItem) {
            // setTextItems([...textItems, editText]);
            props.setAgentData((preagentData) => {
                return {
                    ...preagentData,
                    ReferanceDocument: { ...preagentData.ReferanceDocument, text: [...preagentData.ReferanceDocument.text, editText] }
                }
            });
            setIsAddingNewItem(false);
        } else {
            const updatedItems = [...props.agentData.ReferanceDocument.text];
            updatedItems[editIndex] = editText;
            // setTextItems(updatedItems);
            props.setAgentData((preagentData) => {
                return {
                    ...preagentData,
                    ReferanceDocument: { ...preagentData.ReferanceDocument, text: updatedItems }
                }
            });
        }
        setEditIndex(null);
        setEditText("");
    };
    const handleTextDelete = (index) => {
        if (isAddingNewItem && index === props.agentData.ReferanceDocument.text.length) {
            setIsAddingNewItem(false);
            setEditText("");
            setEditIndex(null);
        } else {
            const updatedItems = props.agentData.ReferanceDocument.text.filter((_, i) => i !== index);
            props.setAgentData((preagentData) => {
                return {
                    ...preagentData,
                    ReferanceDocument: { ...preagentData.ReferanceDocument, text: updatedItems }
                }
            });
        }
    };
    const handleAddNewItem = () => {
        setIsAddingNewItem(true);
        setEditText("");
        setEditIndex(props.agentData.ReferanceDocument.text.length);
    };


    return (
        <Box
            sx={{ flexGrow: 1, padding: { xs: 1, sm: 2, md: 3 }, height: { xs: 'auto', sm: 'auto', md: '85%' }, display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: 2 }} >

            {/* Left section */}
            <Box
                sx={{background:"#fff", boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)', flex: 3, padding: { xs: 1, sm: 1 }, display: 'flex', flexDirection: 'column', minHeight: { xs: 'auto', md: '70%' }, borderRadius: 3 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: { xs: 2, md: 1 } }}>
                    <AccountCircleIcon sx={{ width: { xs: 40, md: 56 }, height: { xs: 40, md: 56 }, color: colors.silver }} />
                    <Typography variant="h6" sx={{ marginLeft: 2, fontSize: { xs: '1rem', md: '1.5rem' } }}>
                        {props.agentData.profile.Name ? (
                            props.agentData.profile.Name
                        ) : (
                            'Name Surname'
                        )}
                    </Typography>
                </Box>

                <Box sx={{ display: 'flex', gap: 1, flexDirection: { xs: 'column', md: 'row' }, maxHeight: '86%', minHeight: '86%', borderRadius: '15px', border: `1px solid ${colors.border}`, padding: '8px' }}>
                    <Box sx={{ flex: 3, backgroundColor: colors.grey, width: { xs: '100%', md: '55%' }, borderRadius: '15px', }}>
                        <Box sx={{ width: '100%', position: 'relative', display: 'flex', borderBottom: '10px solid white', padding: 0 }}>
                            <CustomTabs sx={{ width: '100%', display: 'flex' }} value={selectedTab} onChange={handleTabChange}>
                                {tabContent.map((tab, index) => (
                                    <CustomTab key={index} label={tab.label} sx={{ flexGrow: 1 }} />
                                ))}
                            </CustomTabs>
                            <CustomIndicator sx={{ width: `${100 / 3}%`, left: `${(100 / 3) * selectedTab}%` }} />
                        </Box>
                        <Box sx={{ p: 2, position: 'relative', height: '82%' }}>
                            {tabContent[selectedTab].label === 'Documents' ? (
                                <Box sx={{ height: '100%', overflowY: 'auto' }}>
                                    <Typography variant="h6">Uploaded Documents</Typography>
                                    <Box>{renderUploadedFiles()}</Box>
                                    <input id="file-upload" type="file" multiple accept=".png,.jpg,.jpeg,.bmp,.gif,.webp,.pdf,.doc,.docx,.zip" style={{ display: 'none' }} onChange={handleFileUpload} />

                                    <Button
                                        variant="contained"
                                        startIcon={<AddIcon />}
                                        sx={{ ...gradientButtonStyle, position: 'absolute', bottom: 0, right: 0, margin: '10px', display: 'flex', justifyContent: 'flex-end', padding: 1, }}
                                        onClick={triggerFileUpload}
                                    >
                                        Add New Document
                                    </Button>
                                </Box>
                            ) : tabContent[selectedTab].label === 'URL' ? (
                                <Box sx={{ height: '100%', overflowY: 'auto' }}>
                                    {/* <Typography variant="h6">Uploaded URLs</Typography> */}
                                    {props.agentData.ReferanceDocument.url.map((item, index) => (
                                        <Box
                                            key={index}
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="space-between"
                                            marginBottom={2}
                                            padding={1}
                                            borderRadius={4}
                                            bgcolor={editUrlIndex === index ? colors.grey : colors.white}
                                            sx={{ border: `1px solid ${colors.border}`, maxHeight: '4.5em', paddingRight: 2, overflowY: 'auto', }}
                                        >
                                            {/* Text Container with scroll */}
                                            <Box sx={{ flex: 1, paddingRight: 2, display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: 3, wordWrap: 'break-word', }} >
                                                {editUrlIndex === index ? (
                                                    <TextField
                                                        type="url"
                                                        value={editUrl}
                                                        onChange={handleUrlEditChange}
                                                        fullWidth
                                                        variant="outlined"
                                                        autoFocus
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') {
                                                                handleUrlSave();
                                                            }
                                                        }}
                                                        inputProps={{
                                                            pattern: "https?://.+", // Accepts URLs starting with http:// or https://
                                                            title: "Please enter a valid URL that starts with http:// or https://"
                                                        }}
                                                        sx={{
                                                            padding: '0px',
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    borderColor: colors.silver,
                                                                    borderRadius: '15px'
                                                                },
                                                                '&:hover fieldset': {
                                                                    borderColor: colors.primary,
                                                                    borderRadius: '15px'
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    borderColor: colors.silver,
                                                                    borderRadius: '15px'
                                                                },
                                                            },
                                                        }}
                                                    />

                                                ) : (
                                                    <Typography>{item}</Typography>
                                                )}
                                            </Box>

                                            {/* Action Buttons */}
                                            <Box sx={{ display: 'flex', alignItems: 'center', flexShrink: 0 }}>
                                                {editUrlIndex === index ? (
                                                    <IconButton onClick={handleUrlSave}>
                                                        <SaveIcon style={{ color: colors.secondary }} />
                                                    </IconButton>
                                                ) : (
                                                    <IconButton onClick={() => handleUrlEditClick(index)} sx={{ marginRight: 1 }}>
                                                        <EditIcon style={{ color: colors.secondary }} />
                                                    </IconButton>
                                                )}
                                                <IconButton onClick={() => handleUrlDelete(index)}>
                                                    <DeleteIcon style={{ color: colors.primary }} />
                                                </IconButton>
                                            </Box>
                                        </Box>
                                    ))}

                                    {/* If adding a new item, show an editable field */}
                                    {isAddingNewUrl && (
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="space-between"
                                            marginBottom={2}
                                            padding={1}
                                            borderRadius={4}
                                            sx={{
                                                border: `1px solid ${colors.border}`,
                                                maxHeight: '4.5em',
                                                paddingRight: 2,
                                                overflowY: 'auto',
                                                bgcolor: colors.grey
                                            }}
                                        >
                                            <TextField
                                                value={editUrl}
                                                onChange={handleUrlEditChange}
                                                fullWidth
                                                variant="outlined"
                                                autoFocus
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        handleUrlSave();
                                                    }
                                                }}
                                                sx={{
                                                    padding: '0px',
                                                    '& .MuiOutlinedInput-root': {
                                                        '& fieldset': {
                                                            borderColor: colors.silver,
                                                            borderRadius: '15px'
                                                        },
                                                        '&:hover fieldset': {
                                                            borderColor: colors.primary,
                                                            borderRadius: '15px'
                                                        },
                                                        '&.Mui-focused fieldset': {
                                                            borderColor: colors.silver,
                                                            borderRadius: '15px'
                                                        },
                                                    },
                                                }}
                                            />
                                            <IconButton onClick={handleUrlSave}>
                                                <SaveIcon style={{ color: colors.secondary }} />
                                            </IconButton>
                                            <IconButton onClick={() => handleUrlDelete(props.agentData.ReferanceDocument.url.length)}>
                                                <DeleteIcon style={{ color: colors.primary }} />
                                            </IconButton>
                                        </Box>
                                    )}
                                    <Button
                                        variant="contained"
                                        startIcon={<AddIcon />}
                                        sx={{ ...gradientButtonStyle, position: 'absolute', bottom: 0, right: 0, margin: '10px', display: 'flex', justifyContent: 'flex-end', padding: 1, }}
                                        onClick={handleAddNewUrl}
                                    >
                                        Add New URL
                                    </Button>
                                </Box>
                            ) : (
                                <Box sx={{ height: '100%', overflowY: 'auto' }}>
                                    {/* <Typography variant="h6">Text Data</Typography> */}
                                    {props.agentData.ReferanceDocument.text.map((item, index) => (
                                        <Box
                                            key={index}
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="space-between"
                                            marginBottom={2}
                                            padding={1}
                                            borderRadius={4}
                                            bgcolor={editIndex === index ? colors.grey : colors.white}
                                            sx={{
                                                border: `1px solid ${colors.border}`,
                                                maxHeight: '4.5em',
                                                paddingRight: 2,
                                            }}
                                        >
                                            {/* Text Container with scroll */}
                                            <Box
                                                sx={{
                                                    flex: 1,
                                                    paddingRight: 2,
                                                    display: '-webkit-box',
                                                    WebkitBoxOrient: 'vertical',
                                                    WebkitLineClamp: 3,
                                                    wordWrap: 'break-word',
                                                    maxHeight: '4.5em',
                                                    overflowY: 'auto',
                                                }}
                                            >
                                                {editIndex === index ? (
                                                    <TextField
                                                        value={editText}
                                                        onChange={handleEditChange}
                                                        fullWidth
                                                        variant="outlined"
                                                        autoFocus
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') {
                                                                handleTextSave();
                                                            }
                                                        }}
                                                        sx={{
                                                            padding: '0px',
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    borderColor: colors.silver,
                                                                    borderRadius: '15px',
                                                                },
                                                                '&:hover fieldset': {
                                                                    borderColor: colors.primary,
                                                                    borderRadius: '15px',
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    borderColor: colors.silver,
                                                                    borderRadius: '15px',
                                                                },
                                                            },
                                                        }}
                                                    />
                                                ) : (
                                                    <Typography>{item}</Typography>
                                                )}
                                            </Box>

                                            {/* Action Buttons */}
                                            <Box sx={{ display: 'flex', alignItems: 'center', flexShrink: 0 }}>
                                                {editIndex === index ? (
                                                    <IconButton onClick={handleTextSave}>
                                                        <SaveIcon style={{ color: colors.secondary }} />
                                                    </IconButton>
                                                ) : (
                                                    <IconButton
                                                        onClick={() => handleEditClick(index)}
                                                        sx={{ marginRight: 1 }}
                                                    >
                                                        <EditIcon style={{ color: colors.secondary }} />
                                                    </IconButton>
                                                )}
                                                <IconButton onClick={() => handleTextDelete(index)}>
                                                    <DeleteIcon style={{ color: colors.primary }} />
                                                </IconButton>
                                            </Box>
                                        </Box>

                                    ))}

                                    {/* If adding a new item, show an editable field */}
                                    {isAddingNewItem && (
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="space-between"
                                            marginBottom={2}
                                            padding={1}
                                            borderRadius={4}
                                            sx={{
                                                border: `1px solid ${colors.border}`,
                                                maxHeight: '4.5em',
                                                paddingRight: 2,
                                                overflowY: 'auto',
                                                bgcolor: colors.grey
                                            }}
                                        >
                                            <TextField
                                                value={editText}
                                                onChange={handleEditChange}
                                                fullWidth
                                                variant="outlined"
                                                autoFocus
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        handleTextSave();
                                                    }
                                                }}
                                                sx={{
                                                    padding: '0px',
                                                    '& .MuiOutlinedInput-root': {
                                                        '& fieldset': {
                                                            borderColor: colors.silver,
                                                            borderRadius: '15px'
                                                        },
                                                        '&:hover fieldset': {
                                                            borderColor: colors.primary,
                                                            borderRadius: '15px'
                                                        },
                                                        '&.Mui-focused fieldset': {
                                                            borderColor: colors.silver,
                                                            borderRadius: '15px'
                                                        },
                                                    },
                                                }}
                                            />
                                            <IconButton onClick={handleTextSave}>
                                                <SaveIcon style={{ color: colors.secondary }} />
                                            </IconButton>
                                            <IconButton onClick={() => handleTextDelete(props.agentData.ReferanceDocument.text.length)}>
                                                <DeleteIcon style={{ color: colors.primary }} />
                                            </IconButton>
                                        </Box>
                                    )}

                                    <Button
                                        variant="contained"
                                        startIcon={<AddIcon />}
                                        sx={{ ...gradientButtonStyle, position: 'absolute', bottom: 0, right: 0, margin: '10px', display: 'flex', justifyContent: 'flex-end', padding: 1, }}
                                        onClick={handleAddNewItem}
                                    >
                                        Add Text Data
                                    </Button>
                                </Box>
                            )}
                        </Box>
                    </Box>
                </Box>
            </Box>

            {/* Right section - Suggestions */}
            <Box
                sx={{
                    background:"#fff", boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)', borderRadius:3, overflowY: 'scroll', flex: 1, padding: 2, display: 'flex', flexDirection: 'column', minHeight: '70%', alignItems: 'center',
                    '&::-webkit-scrollbar': {
                        width: '5px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: colors.secondary,
                        borderRadius: '10px',
                    },
                    '&::-webkit-scrollbar-track': {
                        backgroundColor: 'transparent',
                    },
                }}
            >
                <Typography sx={{ marginBottom: 2, fontWeight: 'bold' }}>
                    Suggestions
                </Typography>
                <Typography variant="body2" sx={{ fontSize: '12px' }}>
                    <p>Behavior Setting Instructions for a Call Center Customer Support AI Agent</p>
                    <br />
                    <em>Objective:</em> To provide a clear and comprehensive set of guidelines for an AI agent to effectively interact with customers in a call center environment, ensuring a positive and helpful experience.
                    <br /><br />
                    <p>Core Principles</p>
                    <p><strong>Empathy and Understanding:</strong> The AI agent should strive to understand the customer's perspective, emotions, and needs.</p>
                    <p><strong>Clarity and Conciseness:</strong> Responses should be clear, concise, and easy to understand. Avoid jargon or technical terms.</p>
                    <p><strong>Politeness and Respect:</strong> Always maintain a polite and respectful tone, even in challenging situations.</p>
                    <p><strong>Problem-Solving Focus:</strong> The AI agent should be proactive in identifying and resolving customer issues.</p>
                </Typography>
            </Box>
        </Box>
    );
};

export default ReserenceDoc;
