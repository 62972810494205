import React, { useState, useEffect } from 'react';

const TypewriterHeading = ({ text, typingSpeed = 100 }) => {
  const [displayedText, setDisplayedText] = useState(''); // State to store the text to display

  useEffect(() => {
    let index = 0;
    const timer = setInterval(() => {
      setDisplayedText(text.slice(0, index + 1)); // Update the text one character at a time
      index++;
      if (index === text.length) {
        clearInterval(timer); // Stop the interval when the whole text is displayed
      }
    }, typingSpeed);

    return () => clearInterval(timer); // Cleanup the interval on component unmount
  }, [text, typingSpeed]);

  return <p>{displayedText}</p>;
};

export default TypewriterHeading;
