import axios from 'axios';

const apiURL = process.env.REACT_APP_BACKEND_API_URL;
const api = axios.create({
    baseURL: apiURL,
    timeout: 50000,
    headers: {
        'Content-Type': ' multipart/form-data',
    }
});


export const createAgentApi = async (agentData) => {
    try {
        // Create a new FormData instance
        const formData = new FormData();

        // Destructure to separate files from other data
        const { ReferanceDocument, ...restAgentData } = agentData;
        const { files, ...restReferanceDocument } = ReferanceDocument;

        // Prepare the agent data without files
        const agentDataToSend = {
            ...restAgentData,
            ReferanceDocument: restReferanceDocument,
        };

        // Append the stringified agent data to formData
        formData.append("agent_template", JSON.stringify(agentDataToSend));

        // Append files to the formData
        if (files && files.length > 0) {
            files.forEach((file, index) => {
                formData.append(`files`, file);
            });
        }


        // Send the POST request with the FormData
        const response = await api.post('files/create_agent', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        
        return response.status;
    } catch (error) {
        console.error(error);
        return null;
    }
}

export const getAgentListApi = async () => {
    try {
        const response = await api.get('files/get_agent_list/');
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
}

export const deleteAgentAPi = async (agent_id) => {
    try {
        const agend_id = {
            id:agent_id
        }
        const response = await api.post('files/delete_agent/', agend_id, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response.status;
    } catch (error) {
        console.error(error);
        return null;
    }
}

export const getAgentdata = async (agent_id) => {
    try {
        const response = await api.get('files/get_agent_data_edit',{
            params:{
                "agentid":agent_id
            }
        });
        return response;
    } catch (error) {
        console.error(error);
        return null;
    }
}

export const updateAgent = async (agentData, agentid) => {
    try {
        // Create a new FormData instance
        const formData = new FormData();
        const { ReferanceDocument, ...restAgentData } = agentData;
        const { files, ...restReferanceDocument } = ReferanceDocument;

        // Separate string elements and file objects
        const stringFiles = [];
        const fileObjects = [];

        files.forEach(item => {
            console.log(typeof item);
            if (typeof item === 'string') {
                stringFiles.push(item);
            } else {
                fileObjects.push(item);
            }
        });

        // Prepare the agent data with separated files
        const agentDataToSend = {
            ...restAgentData,
            ReferanceDocument: {
                ...restReferanceDocument,
                files: stringFiles
            },
        };

        // fileObjects array now contains all the file objects
        formData.append("agent_template", JSON.stringify(agentDataToSend));
        formData.append(`agent_id`, agentid);

        if (files && fileObjects.length > 0) {
            fileObjects.forEach((file, index) => {
                formData.append(`files`, file);
            });
        }


        // Send the POST request with the FormData
        const response = await api.post('files/update_agent', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        
        return response.status;
    } catch (error) {
        console.error(error);
        return null;
    }
}

// export const createTool = async (toolData) => {
//   console.log('toolData****',toolData)
//     try {
//         const response = await api.post('files/create_tool', toolData, {
//             headers: {
//                 'Content-Type': 'application/json',
//             },
//         });
//         return response.status;
//     } catch (error) {
//         console.error(error);
//         return null;
//     }
// }

export const uploadFiles = async (formData) => {
  const response = await api.post('files/upload_files', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response

}
export const createTool = async (toolData) => {
  console.log('toolData****', toolData);
  try {
    let endpoint = '';
    
    // Conditional logic for endpoint selection
    if (toolData.tool_selection === 'base') {
      endpoint = 'files/create_tool';
      const response = await api.post(endpoint, toolData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return response.status;
    } else {
      const formattedToolData = {
        name: toolData.agent_name,
        prompt: toolData.prompt,
        description: toolData.description,
        code: "", // Kept empty as requested
        validation_condition: "", // Kept empty as requested
        files: toolData.codeFile ? [{ name: toolData.codeFile }] : [],
        config: toolData.config ? JSON.parse(toolData.config) : {}
      };

      
      endpoint = 'files/validation_tools';
      const response = await api.post(endpoint, formattedToolData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return response.status;
    }

    
    
  } catch (error) {
    console.error('Error creating tool:', error);
    return null;
  }
}


export const getTools = async () => {
    try {
        const response = await api.get('files/get_tools_list/');
        return response.data;
    } catch (error) {
        console.error(error);
        return [];
    }
}
export const getValidationTools = async () => {
    try {
        const response = await api.get('files/list_validation_tools/');
        return response.data;
    } catch (error) {
        console.error(error);
        return [];
    }
}

export async function startWorkflowAgent(agentId) {
    try {
      const response = await api.post('files/start_workflow_agent', JSON.stringify({ id: agentId }), {
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (response.status !== 204) {
        throw new Error(`HTTP error ${response.status}`);
      }
    } catch (error) {
      console.error('Error starting workflow agent:', error);
      throw error;
    }
  }
  
  // Stop Workflow Agent
  export async function stopWorkflowAgent(agentId) {
    try {
      const response = await api.post('files/stop_workflow_agent/',  JSON.stringify({ id: agentId }), {
        headers: {
          'Content-Type': 'application/json',
        }
      });
  
      if (response.status !== 204) {
        if (response.status !== 404) {
          throw new Error('Workflow agent not found');
        } else {
          throw new Error(`HTTP error ${response.status}`);
        }
      }
    } catch (error) {
      console.error('Error stopping workflow agent:', error);
      throw error;
    }
  }
  
  // Get Running Tasks
  export async function getRunningTasks() {
    try {
      const response = await api.get('files/get_running_tasks/');
      const data = response.data
  
      if (response.status !== 200) {
        throw new Error(`HTTP error ${response.status}`);
      }
  
      return data;
    } catch (error) {
      console.error('Error getting running tasks:', error);
      throw error;
    }
  }

  // Function to get the list of grouped patient documents
export async function getDataList() {
    try {
      const response = await api.post('files/data_list/');
      
      if (response.status !== 200) {
        throw new Error(`HTTP error ${response.status}`);
      }
  
      const data = response.data;
      console.log("Data list:", data);
      return data;
    } catch (error) {
      console.error('Error getting data list:', error);
      throw error;
    }
  }
  
  // Function to get patient data based on report type
export async function getPatientData(patientId, reportType) {
    try {
      const payload = {
        id: patientId,
        Type: reportType,
      };
  
      const response = await api.post('files/get_patient_data/',  JSON.stringify(payload), {
        headers: {
          'Content-Type': 'application/json',
        }
      });;
      
      if (response.status !== 200) {
        throw new Error(`HTTP error ${response.status}`);
      }
  
      const data = response.data;
      console.log("Patient report data:", data);
      return data;
    } catch (error) {
      console.error('Error getting patient data:', error);
      throw error;
    }
  }
  