import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  FormLabel,
  OutlinedInput,
  Select,
  MenuItem,
  InputAdornment,
  TextField
} from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import { createTool, uploadFiles } from '../../components/Apis/AgentApi';
import colors from '../../components/styles/colors';

const gradientButtonStyle = {
  background: `linear-gradient(180deg, ${colors.primary} 60%, ${colors.secondary} 90%)`,
  color: colors.white,
  '&:hover': {
    opacity: 0.8,
  },
};

const CreateAgentDialog = ({ open, onClose }) => {
  const [formData, setFormData] = useState({
    agent_name: '',
    prompt: '',
    description: '',
    agent_type: 'Validation',
    config: '{}',
    codeFile: '',
  });

  const handleCodeUpload = async (event) => {
    const file = event.target.files[0];
    const uploadData = new FormData();
    uploadData.append('file', file);

    try {
      await uploadFiles(uploadData);
      setFormData((prev) => ({
        ...prev,
        codeFile: file.name,
      }));
    } catch (error) {
      console.error('File upload error:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    createTool(formData).then((status) => {
      if (status === 201) {
        onClose();
      }
    });
  };

  const AGENT_TYPES = [
    { value: 'code', label: 'Code Validation' },
    { value: 'json', label: 'JSON Validation' },
    { value: 'text', label: 'Simple Prompt Validation' },
  ];

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        style: {
          padding: '24px',
          borderRadius: '8px',
          boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
        },
      }}
    >
      <DialogTitle style={{ textAlign: 'center', fontSize: '1.5rem', fontWeight: 600 }}>
        Create Connectors
      </DialogTitle>

      <DialogContent>
        <div style={{ marginBottom: '16px' }}>
          <div style={{ fontSize: '16px', color: colors.primary, fontWeight: 'bold' }}>
            Upload your code/support files
          </div>
          <TextField
            type="file"
            accept=".json, .txt, .py"
            onChange={handleCodeUpload}
            variant="outlined"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <DescriptionIcon style={{ color: colors.primary }} />
                </InputAdornment>
              ),
            }}
            style={{ marginTop: '8px', borderRadius: 3 }}
          />
          {formData.codeFile && (
            <p style={{ color: '#555' }}>
              Uploaded Code File: <strong>{formData.codeFile}</strong>
            </p>
          )}
        </div>

        <FormControl fullWidth sx={{ marginBottom: 3 }}>
          <FormLabel
            sx={{
              fontSize: '16px',
              color: colors.primary,
              fontWeight: 'bold',
            }}
          >
            Connector Name
          </FormLabel>
          <OutlinedInput
            value={formData.agent_name}
            onChange={handleChange}
            placeholder="Enter Connector Name"
            name="agent_name"
            sx={{
              borderRadius: 3,
              '& .MuiOutlinedInput-notchedOutline': { borderColor: colors.silver },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: colors.primary,
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: colors.primary,
              },
            }}
          />
        </FormControl>

        <FormControl fullWidth sx={{ marginBottom: 3 }}>
          <FormLabel
            sx={{
              fontSize: '16px',
              color: colors.primary,
              fontWeight: 'bold',
            }}
          >
            Description
          </FormLabel>
          <OutlinedInput
            value={formData.description}
            onChange={handleChange}
            placeholder="Enter Description"
            name="description"
            multiline
            minRows={3}
            maxRows={5}
            sx={{
              borderRadius: 3,
              '& .MuiOutlinedInput-notchedOutline': { borderColor: colors.silver },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: colors.primary,
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: colors.primary,
              },
            }}
          />
        </FormControl>

        <FormControl fullWidth sx={{ marginBottom: 3 }}>
          <FormLabel
            sx={{
              fontSize: '16px',
              color: '#000',
              fontWeight: 'bold',
            }}
          >
            Connector Type
          </FormLabel>
          <Select
            value={formData.agent_type}
            name="agent_type"
            onChange={handleChange}
            MenuProps={{
              PaperProps: { style: { maxHeight: 200, width: 'auto' } },
            }}
            sx={{
              borderRadius: 3,
              '& .MuiOutlinedInput-notchedOutline': { borderColor: colors.silver },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: colors.primary,
              },
            }}
          >
            {AGENT_TYPES.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth sx={{ marginBottom: 3 }}>
          <FormLabel
            sx={{
              fontSize: "16px",
              color: colors.primary,
              fontWeight: "bold",
            }}
          >
            Prompt
          </FormLabel>
          <OutlinedInput
            value={formData.prompt}
            onChange={handleChange}
            placeholder="Enter Prompt"
            name="prompt"
            multiline
            minRows={3}
            maxRows={5}
            sx={{
              borderRadius: 3,
              "& .MuiOutlinedInput-notchedOutline": { borderColor: colors.silver },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: colors.primary,
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: colors.primary,
              },
            }}
          />
        </FormControl>
        <FormControl fullWidth sx={{ marginBottom: 3 }}>
          <FormLabel
            sx={{
              fontSize: "16px",
              color: colors.primary,
              fontWeight: "bold",
            }}
          >
            Config
          </FormLabel>
          <OutlinedInput
            value={formData.config}
            onChange={handleChange}
            placeholder="Enter Config"
            name="config"
            multiline
            minRows={3}
            maxRows={5}
            sx={{
              borderRadius: 3,
              "& .MuiOutlinedInput-notchedOutline": { borderColor: colors.silver },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: colors.primary,
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: colors.primary,
              },
            }}
          />
        </FormControl>
      </DialogContent>

      <DialogActions style={{ justifyContent: 'flex-end' }}>
        <Button
          onClick={onClose}
          variant="contained"
          style={{
            borderRadius: '10px',
            padding: '5px 20px',
            background: colors.white,
            color: colors.black,
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          sx={gradientButtonStyle}
          variant="contained"
          style={{ borderRadius: '10px', padding: '5px 20px' }}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateAgentDialog;
