import React, { useState, useEffect, useRef } from 'react';
import { TextField, Typography, Box, IconButton, Button } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import colors from '../../../components/styles/colors';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';

const OperationalWorkflow = (props) => {
    const [items, setItems] = useState(props.agentData.OperationalWorkflow.FlowConditions);
    const prevItemsRef = useRef(items);
    const [editIndex, setEditIndex] = useState(null);
    const [editText, setEditText] = useState("");
    const [isAddingNewItem, setIsAddingNewItem] = useState(false);

    const gradientButtonStyle = {
        background: `linear-gradient(180deg, ${colors.primary} 60%, ${colors.secondary} 90%)`,
        color: colors.white,
        '&:hover': {
            opacity: 0.8,
        },
    };

    useEffect(() => {
        if (prevItemsRef.current !== items) {
            const handleChange = (section, field, value) => {
                props.setAgentData(prevData => ({
                    ...prevData,
                    [section]: {
                        ...prevData[section],
                        [field]: value
                    }
                }));
            };
            handleChange("OperationalWorkflow", "FlowConditions", items);
            prevItemsRef.current = items;
        }
    }, [items, props]);

    const handleEditClick = (index) => {
        setEditIndex(index);
        setEditText(items[index]);
        setIsAddingNewItem(false);
    };

    const handleEditChange = (event) => {
        setEditText(event.target.value);
    };

    const handleSave = () => {
        if (!editText.trim()) return;

        if (isAddingNewItem) {
            setItems([...items, editText]);
            setIsAddingNewItem(false);
        } else {
            const updatedItems = [...items];
            updatedItems[editIndex] = editText;
            setItems(updatedItems);
        }

        setEditIndex(null);
        setEditText("");
    };

    const handleDelete = (index) => {
        if (isAddingNewItem && index === items.length) {
            setIsAddingNewItem(false);
            setEditText("");
            setEditIndex(null);
        } else {
            const updatedItems = items.filter((_, i) => i !== index);
            setItems(updatedItems);
        }
    };

    const handleAddNewItem = () => {
        setIsAddingNewItem(true);
        setEditText("");
        setEditIndex(items.length);
    };

    return (
        <Box
            sx={{
                flexGrow: 1,
                padding: 3,
                height: '85%',
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'column', md: 'row' }
            }}
        >
            {/* Left section */}
            <Box
                sx={{
                    // border: `1px solid ${colors.silver}`,
                    background:"#fff",
                    flex: 3,
                    padding: 2,
                    marginBottom: { xs: 2, sm: 0 },
                    display: 'flex',
                    flexDirection: 'column',
                    marginRight: 2,
                    position: 'relative',
                    overflowY: 'auto',
                    maxHeight: '100%',
                    borderRadius: 3,
                    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 3 }}>
                    <AccountCircleIcon sx={{ width: 56, height: 56, color: colors.silver }} />
                    <Typography sx={{ marginLeft: 2, fontSize: '20px', fontWeight: 'bold' }}>
                        {props.agentData.profile.Name || 'Name Surname'}
                    </Typography>
                </Box>

                <Typography sx={{ marginBottom: 1, fontSize: '20px', fontWeight: 'bold' }}>
                    Flow Conditions
                </Typography>

                {/* Flow Conditions Body */}
                <Box sx={{ overflowY: 'auto', flex: 1 }}>
                    {items.map((item, index) => (
                        <Box
                            key={index}
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            marginBottom={2}
                            padding={1}
                            borderRadius={4}
                            bgcolor={editIndex === index ? "#f0f0f0" : "#fff"}
                            sx={{
                                border: `1px solid ${colors.border}`,
                                maxHeight: '4.5em',
                                overflow: 'hidden',
                            }}
                        >
                            <Box
                                sx={{
                                    flex: 1,
                                    paddingRight: 2,
                                    display: '-webkit-box',
                                    WebkitBoxOrient: 'vertical',
                                    WebkitLineClamp: 3, 
                                    wordWrap: 'break-word',
                                }}
                            >
                                {editIndex === index ? (
                                    <TextField
                                        value={editText}
                                        onChange={handleEditChange}
                                        fullWidth
                                        variant="outlined"
                                        autoFocus
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') handleSave();
                                        }}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    borderColor: colors.silver,
                                                    borderRadius: '15px'
                                                },
                                                '&:hover fieldset, &.Mui-focused fieldset': {
                                                    borderColor: colors.primary,
                                                },
                                            },
                                        }}
                                    />
                                ) : (
                                    <Typography noWrap>{item}</Typography>
                                )}
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'center', flexShrink: 0 }}>
                                {editIndex === index ? (
                                    <IconButton onClick={handleSave}>
                                        <SaveIcon style={{ color: colors.secondary }} />
                                    </IconButton>
                                ) : (
                                    <IconButton onClick={() => handleEditClick(index)} sx={{ marginRight: 1 }}>
                                        <EditIcon style={{ color: colors.secondary }} />
                                    </IconButton>
                                )}
                                <IconButton onClick={() => handleDelete(index)}>
                                    <DeleteIcon style={{ color: colors.primary }} />
                                </IconButton>
                            </Box>
                        </Box>
                    ))}

                    {/* If adding a new item, show an editable field */}
                    {isAddingNewItem && (
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            marginBottom={2}
                            padding={1}
                            borderRadius={4}
                            sx={{
                                backgroundColor: '#f0f0f0',
                                border: `1px solid ${colors.border}`,
                                maxHeight: '4.5em',
                            }}
                        >
                            <TextField
                                value={editText}
                                onChange={handleEditChange}
                                fullWidth
                                variant="outlined"
                                autoFocus
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') handleSave();
                                }}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: colors.silver,
                                            borderRadius: '15px'
                                        },
                                        '&:hover fieldset, &.Mui-focused fieldset': {
                                            borderColor: colors.primary,
                                        },
                                    },
                                }}
                            />
                            <IconButton onClick={handleSave}>
                                <SaveIcon style={{ color: colors.secondary }} />
                            </IconButton>
                            <IconButton onClick={() => handleDelete(items.length)}>
                                <DeleteIcon style={{ color: colors.primary }} />
                            </IconButton>
                        </Box>
                    )}

                    {/* Add Text Data Button */}
                    <Box
                        sx={{
                            position: 'absolute',
                            bottom: 0,
                            right: '5px',
                            display: 'flex',
                            justifyContent: 'flex-end',
                            padding: 1,
                        }}
                    >
                        <Button onClick={handleAddNewItem} sx={gradientButtonStyle} startIcon={<AddIcon />}>
                            Add
                        </Button>
                    </Box>
                </Box>
            </Box>

            {/* Right section - Suggestions */}
            <Box
                sx={{
                    // border: `1px solid ${colors.silver}`,
                    background:"#fff",
                    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
                    overflowY: 'scroll',
                    flex: 1,
                    padding: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    marginLeft: 2,
                    borderRadius: '8px',
                    minHeight: '70%',
                    '&::-webkit-scrollbar': {
                        width: '5px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: colors.secondary,
                        borderRadius: '10px',
                    },
                    '&::-webkit-scrollbar-track': {
                        backgroundColor: 'transparent',
                    },
                }}
            >
                <Typography sx={{ marginBottom: 2, fontWeight: 'bold' }}>
                    User Guidelines for Interacting with Workflow Agents
                </Typography>
                <Typography variant="body2" sx={{ fontSize: '14px', lineHeight: 1.6 }}>
                    <p>To ensure smooth and efficient communication with the Workflow Agents, it’s important to understand how each agent behaves and operates, and what permissions they have. These guidelines will help you navigate your interactions more effectively.</p>
                    
                    <Typography sx={{ marginY: 2, fontWeight: 'bold', fontSize: '16px' }}>
                        Operational Workflow
                    </Typography>
                    <p>The operational workflow defines the steps that each agent follows to assist you in your inquiry. This includes the greeting process, how inquiries are handled, and the resolution of your issue.</p>
                    
                    <p><strong>Greeting & Identification:</strong></p>
                    <ul>
                        <li>The agent will start by greeting you warmly and asking about your specific needs.</li>
                    </ul>
                    
                    <p><strong>Inquiry Handling:</strong></p>
                    <ul>
                        <li>The agent processes your request using structured information, such as the company’s knowledge base.</li>
                    </ul>
                    
                    <p><strong>Solution Delivery:</strong></p>
                    <ul>
                        <li>The agent will break down complex issues into simple steps to make them easier for you to follow.</li>
                    </ul>
                    
                    <p><strong>Sample Conversations:</strong></p>
                    <ul>
                        <li><strong>Sales Query:</strong> "I want to know more about SUV models."</li>
                        <li><strong>HR Query:</strong> "How do I apply for parental leave?"</li>
                    </ul>
                    
                    <p><strong>Escalation Process:</strong></p>
                    <ul>
                        <li>If your issue cannot be resolved, the agent will ask if you'd like to create a support ticket.</li>
                    </ul>
                    
                    <em>User Tip:</em> You can follow the step-by-step guidance provided by the agents to complete tasks.

                    <Typography sx={{ marginY: 2, fontWeight: 'bold', fontSize: '16px' }}>
                        Key Things to Remember:
                    </Typography>
                    <ol>
                        <li>Ask Clear Questions.</li>
                        <li>Follow the Step-by-Step Instructions.</li>
                        <li>Escalate When Necessary.</li>
                        <li>Agent Behaviour Varies.</li>
                    </ol>
                    
                    <p>By following these guidelines, you’ll have an easier time navigating your interactions with the system.</p>
                </Typography>
            </Box>
        </Box>
    );
};

export default OperationalWorkflow;