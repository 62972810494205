import { Home, Settings, Extension, Build } from '@mui/icons-material';
import RouteOutlinedIcon from '@mui/icons-material/RouteOutlined';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import PermDataSettingIcon from '@mui/icons-material/PermDataSetting';

import profile_image from '../list-image/1505516.png';
import auth_image from '../list-image/auth.png';
import valid_image from '../list-image/green-check-in-round-stamp-validate-true-approved-701751694698727anafd1g6ie.png';
import ticket_image from '../list-image/support-ticket.png';
export const profile = [
    {
        id: 0,
        icon: <AccountCircleIcon/>,
        label: 'Agent Profile',
        image: profile_image,
        route: '',
    },
]

export const Instructions = [
    {
        id: 1,
        icon: <Home fontSize="small"/>,
        label: 'Behaviour Setting',
        image: auth_image,
        route: '',
    },
    {
        id: 2,
        icon: <RouteOutlinedIcon fontSize="small"/>,
        label: 'Operational Workflow',
        image: profile_image,
        route: '',
    },
]

export const ServiceIntegrations = [
    {
        id: 11,
        icon: <Build fontSize="small"/>,
        label: 'Connectors',
        image: auth_image,
        route: '',
    },
    {
        id: 12,
        icon: <Extension fontSize="small"/>,
        label: 'Reference Documents',
        image: ticket_image,
        route: '',
    },
]

export const Others = [
    {
        id: 21,
        icon: <FactCheckIcon fontSize="small"/>,
        label: 'Validation',
        image: valid_image,
        route: '',
    },
    {
        id: 22,
        icon: <PermDataSettingIcon fontSize="small"/>,
        label: 'Assets',
        image: ticket_image,
        route: '',
    },
    {
        id: 23,
        icon: <LockPersonIcon fontSize="small"/>,
        label: 'Access & Permissions',
        image: auth_image,
        route: '',
    },
    {
        id: 24,
        icon: <Settings fontSize="small"/>,
        label: 'Advanced Settings',
        image: valid_image,
        route: '',
    },
]
