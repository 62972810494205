

import React, { useState } from 'react';

import { Box, Button, Grid, Typography, Chip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import colors from '../../components/styles/colors';
import HandymanIcon from '@mui/icons-material/Handyman';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
// import CreateAgentDialog from './createToolDialog';
import CreateAgentDialog from './CreateValidationDialog';
import { useGlobalVarible } from '../../components/Constants/globalContextModel';

// const categories = ['All categories', 'Popular', 'Marketing', 'Research', 'Sales', 'Integrations'];
const categories = [];


const gradientButtonStyle = {
  background: `linear-gradient(180deg, ${colors.primary} 60%, ${colors.secondary} 90%)`,
  color: colors.white,
  '&:hover': {
    background: `linear-gradient(180deg, ${colors.primary} 60%, ${colors.secondary} 90%)`,
    opacity: 0.8,
  },
};

// const ToolsButton = () => {
//   const [dialogOpen, setDialogOpen] = React.useState(false);
//   const { view, anotherVar } = useGlobalVarible();

//   return (
//     <div >
    
//         <Button
//           variant="contained"
//           startIcon={<AddIcon />}
//           sx={gradientButtonStyle}
//           style={{ borderRadius: '20px' }}
//           onClick={() => setDialogOpen(true)}
//         >
//            Validation
//         </Button>
//       <CreateAgentDialog
//         open={dialogOpen}
//         onClose={() => setDialogOpen(false)}
//       />

//     </div>
//   );
// };

// export default ToolsButton;

const ToolsButton = ({ validationReloadFunction }) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <div>
      <Button
        variant="contained"
        startIcon={<AddIcon />}
        sx={gradientButtonStyle}
        style={{ borderRadius: '20px' }}
        onClick={() => setDialogOpen(true)}
      >
        Validation
      </Button>
      <CreateAgentDialog
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false);
          if (validationReloadFunction) validationReloadFunction(); // Call the function when dialog closes
        }}
      />
    </div>
  );
};

export default ToolsButton;
