import React, { useState, useEffect } from 'react';
import { Box, Button, Grid, Typography, Chip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Stop } from '@mui/icons-material';
import colors from '../../components/styles/colors';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import PeopleIcon from '@material-ui/icons/People';
import AgentProfilePopup from './AgentProfilePopup'; // Import the popup component
import { getAgentListApi, deleteAgentAPi, startWorkflowAgent, stopWorkflowAgent, getRunningTasks } from '../../components/Apis/AgentApi';
import { useGlobalVarible } from '../../components/Constants/globalContextModel';
import DeleteIcon from '@mui/icons-material/Delete';
import EditNoteIcon from '@mui/icons-material/EditNote';

// const agents = [
//   { title: 'Document Extractor', description: 'Intelligent Document Integrations', icon: '🗂️' },
//   { title: 'Researcher Agent', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
//   { title: 'Marketing Manager', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
//   { title: 'Content Writer Agent', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
//   { title: 'Website Content Agent', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
//   { title: 'Image Finder Agent', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
// ];

// const categories = ['All categories', 'Popular', 'Marketing', 'Research', 'Sales', 'Integrations'];
const categories = [];

const gradientButtonStyle = {
  background: `linear-gradient(180deg, ${colors.primary} 60%, ${colors.secondary} 90%)`,
  color: colors.white,
  '&:hover': {
    background: `linear-gradient(180deg, ${colors.primary} 60%, ${colors.secondary} 90%)`,
    opacity: 0.8,
  },
};

const gradientButtonStyleStop = {
  background: `linear-gradient(180deg, #E53935 60%, #C62828 90%)`,
  color: colors.white,
  '&:hover': {
    background: `linear-gradient(180deg, #E53935 60%, #C62828 90%)`,
    opacity: 0.8,
  },
};

const Agents = () => {
  // State to control the dialog visibility
  const [openPopup, setOpenPopup] = useState(false);
  const [agents, setAgents] = useState([]);
  const { agentid, setagentid } = useGlobalVarible(); // Get the agent id from the global context
  const [selectedId, setSelectedId] = useState(null);
  const { view, anotherVar } = useGlobalVarible();

  // Function to open the popup
  const handleOpenPopup = () => {
    setOpenPopup(true);
  };

  const deleteAgent = async (id) => {
    console.log('delete', id);
    deleteAgentAPi(id);
    const agentList = await getAgentListApi();
    if (agentList) {
      setAgents(agentList);
    }

  };

  const editAgent = (id) => {
    console.log(id);
    setSelectedId(id);
    setOpenPopup(true);
  };

  // Function to close the popup
  const handleClosePopup = () => {
    setOpenPopup(false);
  };
  
  const fetchAgents = async () => {
    try {
      const agentList = await getAgentListApi();
      const running_tasks = await getRunningTasks();
  
      const updatedAgentList = agentList.map((agent) => {
        return {
          ...agent,
          running_task: running_tasks.includes(agent.id),
        };
      });
      if (updatedAgentList) {
        setAgents(updatedAgentList);
      }
    } catch (error) {
      console.error('Error fetching agents:', error);
    }
  };

  useEffect(() => {

    fetchAgents(); // Initial fetch

    const intervalId = setInterval(fetchAgents, 10000); // Fetch every 10 seconds

    return () => clearInterval(intervalId); // Cleanup on component unmount
  }, []);
  return (
    <div style={{ padding: '40px 30px', background:"#F5F5F9" }}>
      {!openPopup ? (
        <>
         
            <div  style={{  display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              variant="contained"
              startIcon={<PersonAddIcon />}
              sx={gradientButtonStyle}
              style={{ borderRadius: '20px' }}
              onClick={handleOpenPopup} // Open popup on button click
            >
              Create Agent
            </Button>
            </div>
          {/* </Box> */}
  
          <h2>Chat Agents <PeopleIcon style={{ fontSize: 30, verticalAlign: 'bottom' }} /></h2>
          <Box sx={{ mb: 3 }}>
            {categories.map((category, index) => (
              <Chip
                key={index}
                label={category}
                clickable
                sx={{ m: 0.5, fontSize: '14px', fontWeight: 500 }}
              />
            ))}
          </Box>
  
          {/* Agents List */}
          <Grid container spacing={2}>
            {agents.filter(agent => !agent.WorkflowAgent).map((agent, index) => (
              <Grid item xs={12} sm={6} md={4} key={index} sx={{ display: 'flex' }}>
                <Box
                  sx={{
                    p: 2,
                    // backgroundColor: `${colors.primary}10`,
                    borderRadius: 5,
                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
                    display: 'flex',
                    flexDirection: 'column',
                    maxWidth: '400px',
                    width: '100%',
                    margin: 0,
                    background:"#fff",
                  }}
                >
                  <Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      {agent.icon && (
                        <img
                          src={agent.icon}
                          alt={agent.title}
                          style={{ width: '50px', height: '50px', marginRight: 8, borderRadius: '50%' }}
                        />
                      )}
                      <Typography sx={{ fontWeight: 'bold', fontSize: '20px', textAlign: 'center' }} gutterBottom>
                        {agent.title}
                      </Typography>
                    </Box>
  
                    <Typography variant="body2" color="textSecondary">
                      {agent.description}
                    </Typography>
                  </Box>
                  <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                      variant="contained"
                      size="small"
                      sx={gradientButtonStyle}
                      style={{ borderRadius: '10px' }}
                      onClick={() => { deleteAgent(agent.id); }}
                      disabled={agent.status !== 'Completed'}
                    >
                      <DeleteIcon />
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      sx={gradientButtonStyle}
                      style={{ borderRadius: '10px', marginLeft: '10px', marginRight: '10px' }}
                      onClick={() => { editAgent(agent.id); }}
                      disabled={agent.status !== 'Completed'}
                    >
                      <EditNoteIcon />
                    </Button>
  
                    <Button
                      variant="contained"
                      size="small"
                      sx={gradientButtonStyle}
                      startIcon={<AddIcon />}
                      style={{ borderRadius: '10px' }}
                      onClick={() => { setagentid({ 'agent_id': agent.id, 'agent_name': agent.title }); }}
                      disabled={agent.status !== 'Completed'}
                    >
                      {agent.status === 'Completed' ? 'Use Agent' : 'Pending'}
                    </Button>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
  
          <div style={{ paddingTop: '50px', background:"#F5F5F9" }}>
          <h2>Workflow Agents <PeopleIcon style={{ fontSize: 30, verticalAlign: 'bottom' }} /></h2>
          </div>
          <Box sx={{ mb: 3 }}>
            {categories.map((category, index) => (
              <Chip
                key={index}
                label={category}
                clickable
                sx={{ m: 0.5, fontSize: '14px', fontWeight: 500 }}
              />
            ))}
          </Box>
  
          {/* Agents List */}
          <Grid container spacing={2}>
            {agents.filter(agent => agent.WorkflowAgent).map((agent, index) => (
              <Grid item xs={12} sm={6} md={4} key={index} sx={{ display: 'flex' }}>
                <Box
                  sx={{
                    p: 2,
                    // backgroundColor: view === 1 ? colors.containerBG : `${colors.primary}10`,
                    background:"#fff",
                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
                    borderRadius: 5,
                    display: 'flex',
                    flexDirection: 'column',
                    maxWidth: '400px',
                    width: '100%',
                    margin: 0,
                  }}
                >
                  <Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      {agent.icon && (
                        <img
                          src={agent.icon}
                          alt={agent.title}
                          style={{ width: '50px', height: '50px', marginRight: 8, borderRadius: '50%' }}
                        />
                      )}
                      <Typography sx={{ fontWeight: 'bold', fontSize: '20px', textAlign: 'center' }} gutterBottom>
                        {agent.title}
                      </Typography>
                    </Box>
  
                    <Typography variant="body2" color="textSecondary" sx={{ color: colors.black }}>
                      {agent.description}
                    </Typography>
                  </Box>
                  <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                      variant="contained"
                      size="small"
                      sx={gradientButtonStyle}
                      style={{ borderRadius: '10px' }}
                      onClick={() => { deleteAgent(agent.id); }}
                      disabled={agent.status !== 'Completed'}
                    >
                      <DeleteIcon />
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      sx={gradientButtonStyle}
                      style={{ borderRadius: '10px', marginLeft: '10px', marginRight: '10px' }}
                      onClick={() => { editAgent(agent.id); }}
                      disabled={agent.status !== 'Completed'}
                    >
                      <EditNoteIcon />
                    </Button>
  
                    {!agent.running_task ? (
                      <Button
                        variant="contained"
                        size="small"
                        sx={gradientButtonStyle}
                        startIcon={<AddIcon />}
                        style={{ borderRadius: '10px' }}
                        onClick={() => { startWorkflowAgent(agent.id).then(fetchAgents()); }}
                      >
                        Start Agent
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        size="small"
                        sx={gradientButtonStyleStop}
                        startIcon={<Stop />}
                        style={{ borderRadius: '10px' }}
                        onClick={() => { stopWorkflowAgent(agent.id).then(fetchAgents()); }}
                      >
                        Stop Agent
                      </Button>
                    )}
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </>
      ) : (
        <AgentProfilePopup open={openPopup} handleClose={handleClosePopup} id={selectedId} />
      )}
    </div>
  );
};

export default Agents;
