import { useEffect, useState } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
const apiURL = process.env.REACT_APP_BACKEND_API_URL;

export const useChatSocket = (onIncommingMessage) => {
    const [socketUrl] = useState(apiURL + '/ws');
    const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, {
        onOpen: () => {
            console.log('WebSocket connection opened.');
        },
        onClose: (event) => console.log('WebSocket connection closed:', event),
        onError: (error) => console.error('WebSocket error:', error),
        shouldReconnect: (closeEvent) => true,
    });

    // Handle incoming messages
    useEffect(() => {
        if (lastMessage !== null) {
            console.log('Raw WebSocket message received:', lastMessage);
            try {
                onIncommingMessage(JSON.parse(lastMessage.data));
            } catch (error) {
                console.error('Error parsing WebSocket message:', error);
            }
        }
    }, [lastMessage]);

    // Return useful WebSocket functions and states
    return {
        sendMessage,
        lastMessage,
        readyState,
    };
};
