import React, { useState, useEffect, useRef } from 'react';
import { TextField, Typography, Box, IconButton, Button } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import colors from '../../../components/styles/colors';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';

const AccessAndPermissions = (props) => {

    const [items, setItems] = useState(props.agentData.AccessAndPermissions);
    const prevItemsRef = useRef(items);
    const [editIndex, setEditIndex] = useState(null);
    const [editText, setEditText] = useState("");
    const [isAddingNewItem, setIsAddingNewItem] = useState(false);

    const gradientButtonStyle = {
        background: `linear-gradient(180deg, ${colors.primary} 60%, ${colors.secondary} 90%)`,
        color: colors.white,
        '&:hover': {
            background: `linear-gradient(180deg, ${colors.primary} 60%, ${colors.secondary} 90%)`,
            opacity: 0.8,
        },
    };

    useEffect(() => {
        if (prevItemsRef.current !== items) {
            const handleChange = (section, value) => {
                props.setAgentData(prevData => ({
                    ...prevData,
                    [section]: value
                }));
            };
            handleChange("AccessAndPermissions", items);
            prevItemsRef.current = items;
        }
    }, [items, props]);

    // Handle when the edit button is clicked
    const handleEditClick = (index) => {
        setEditIndex(index);
        setEditText(items[index]);
        setIsAddingNewItem(false);
    };

    // Handle when text changes during editing
    const handleEditChange = (event) => {
        setEditText(event.target.value);
    };

    // Handle saving the edited text or new item
    const handleSave = () => {
        if (!editText.trim()) {
            return;
        }

        if (isAddingNewItem) {
            setItems([...items, editText]);
            setIsAddingNewItem(false);
        } else {
            const updatedItems = [...items];
            updatedItems[editIndex] = editText;
            setItems(updatedItems);
        }

        setEditIndex(null);
        setEditText("");
    };

    // Handle deleting an item
    const handleDelete = (index) => {
        if (isAddingNewItem && index === items.length) {
            setIsAddingNewItem(false);
            setEditText("");
            setEditIndex(null);
        } else {
            const updatedItems = items.filter((_, i) => i !== index);
            setItems(updatedItems);
        }
    };

    // Handle adding a new item and set it to editable mode
    const handleAddNewItem = () => {
        setIsAddingNewItem(true);
        setEditText("");
        setEditIndex(items.length);
    };

    return (
        <Box
            sx={{
                flexGrow: 1,
                padding: 3,
                height: '85%',
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'column', md: 'row' }
            }}
        >
            {/* Left section */}
            <Box
                sx={{
                    // border: `1px solid ${colors.silver}`,
                    background:"#fff",
                    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
                    borderRadius:3,
                    flex: 3,
                    padding: 2,
                    marginBottom: { xs: 2, sm: 0 },
                    display: 'flex',
                    flexDirection: 'column',
                    marginRight: 2,
                    position: 'relative',
                    overflowY: 'auto',
                    maxHeight: '100%',
                    paddingBottom: '4%',
                    minHeight: '70%'
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 3 }}>
                    <AccountCircleIcon sx={{ width: 56, height: 56, color: colors.silver }} />
                    <Typography sx={{ marginLeft: 2, fontSize: '20px' }}>
                        {props.agentData.profile.Name ? (
                            props.agentData.profile.Name
                        ) : (
                            'Name Surname'
                        )}
                    </Typography>
                </Box>

                {/* Behavior Setting Section */}
                <Typography sx={{ marginBottom: 1, fontSize: '20px' }}>
                    Access and Permissions
                </Typography>

                {/* Flow Conditions Body */}
                <Box sx={{ overflowY: 'auto', flex: 1, minHeight: '70%' }}>
                    {items.map((item, index) => (
                        <Box
                            key={index}
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            marginBottom={2}
                            padding={1}
                            borderRadius={4}
                            bgcolor={editIndex === index ? "#f0f0f0" : "#fff"}
                            sx={{
                                border: `1px solid ${colors.border}`,
                                maxHeight: '4.5em',
                                paddingRight: 2,
                                overflowY: 'auto',
                            }}
                        >
                            {/* Text Container with scroll */}
                            <Box
                                sx={{
                                    flex: 1,
                                    paddingRight: 2,
                                    display: '-webkit-box',
                                    WebkitBoxOrient: 'vertical',
                                    WebkitLineClamp: 3, // Limits lines shown before scrolling
                                    wordWrap: 'break-word',
                                }}
                            >
                                {editIndex === index ? (
                                    <TextField
                                        value={editText}
                                        onChange={handleEditChange}
                                        fullWidth
                                        variant="outlined"
                                        autoFocus
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                handleSave();
                                            }
                                        }}
                                        sx={{
                                            padding: '0px',
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    borderColor: colors.silver,
                                                    borderRadius: '15px'
                                                },
                                                '&:hover fieldset': {
                                                    borderColor: colors.primary,
                                                    borderRadius: '15px'
                                                },
                                                '&.Mui-focused fieldset': {
                                                    borderColor: colors.silver,
                                                    borderRadius: '15px'
                                                },
                                            },
                                        }}
                                    />
                                ) : (
                                    <Typography>{item}</Typography>
                                )}
                            </Box>

                            {/* Action Buttons */}
                            <Box sx={{ display: 'flex', alignItems: 'center', flexShrink: 0 }}>
                                {editIndex === index ? (
                                    <IconButton onClick={handleSave}>
                                        <SaveIcon style={{ color: colors.secondary }} />
                                    </IconButton>
                                ) : (
                                    <IconButton onClick={() => handleEditClick(index)} sx={{ marginRight: 1 }}>
                                        <EditIcon style={{ color: colors.secondary }} />
                                    </IconButton>
                                )}
                                <IconButton onClick={() => handleDelete(index)}>
                                    <DeleteIcon style={{ color: colors.primary }} />
                                </IconButton>
                            </Box>
                        </Box>
                    ))}

                    {/* If adding a new item, show an editable field */}
                    {isAddingNewItem && (
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            marginBottom={2}
                            padding={1}
                            borderRadius={4}
                            sx={{
                                border: `1px solid ${colors.border}`,
                                maxHeight: '4.5em',
                                paddingRight: 2,
                                overflowY: 'auto',
                                bgcolor: '#f0f0f0'
                            }}
                        >
                            <TextField
                                value={editText}
                                onChange={handleEditChange}
                                fullWidth
                                variant="outlined"
                                autoFocus
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        handleSave();
                                    }
                                }}
                                sx={{
                                    padding: '0px',
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: colors.silver,
                                            borderRadius: '15px'
                                        },
                                        '&:hover fieldset': {
                                            borderColor: colors.primary,
                                            borderRadius: '15px'
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: colors.silver,
                                            borderRadius: '15px'
                                        },
                                    },
                                }}
                            />
                            <IconButton onClick={handleSave}>
                                <SaveIcon style={{ color: colors.secondary }} />
                            </IconButton>
                            <IconButton onClick={() => handleDelete(items.length)}>
                                <DeleteIcon style={{ color: colors.primary }} />
                            </IconButton>
                        </Box>
                    )}

                    {/* Add Text Data Button */}
                    <Box
                        sx={{
                            position: 'absolute',
                            bottom: 0,
                            right: '5px',
                            display: 'flex',
                            justifyContent: 'flex-end',
                            padding: 1,
                        }}
                    >
                        <Button onClick={handleAddNewItem} sx={gradientButtonStyle} style={{ color: colors.white }} startIcon={<AddIcon />}>
                            Add Text Data
                        </Button>
                    </Box>
                </Box>
            </Box>

            {/* Right section - Suggestions */}
            <Box
                sx={{
                    // border: `1px solid ${colors.silver}`,
                    background:"#fff",
                    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
                    borderRadius: 3,
                    overflowY: 'scroll',
                    flex: 1,
                    padding: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    marginRight: 2,
                    marginLeft: 1,
                    minHeight: '70%',
                    '&::-webkit-scrollbar': {
                        width: '5px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: colors.secondary,
                        borderRadius: '10px',
                    },
                    '&::-webkit-scrollbar-track': {
                        backgroundColor: 'transparent',
                    },
                }}
            >
                <Typography sx={{ marginBottom: 2, fontSize: '20px' }}>
                    Suggestions
                </Typography>
                <Typography variant="body2" sx={{ fontSize: '12px' }}>
                    <p>Behavior Setting Instructions for a Call Center Customer Support AI Agent</p>
                    <br /><br />
                    <em>Objective:</em> To provide a clear and comprehensive set of guidelines for an AI agent to effectively interact with customers in a call center environment, ensuring a positive and helpful experience.
                    <br /><br />
                    <p>Core Principles</p>
                    <p>Empathy and Understanding: The AI agent should strive to understand the customer's perspective, emotions, and needs.</p>
                    <p>Clarity and Conciseness: Responses should be clear, concise, and easy to understand. Avoid jargon or technical terms.</p>
                    <p>Politeness and Respect: Always maintain a polite and respectful tone, even in challenging situations.</p>
                    <p>Problem-Solving Focus: The AI agent should be proactive in identifying and resolving customer issues.</p>
                </Typography>
            </Box>
        </Box>
    );
};

export default AccessAndPermissions;
