import axios from 'axios';

const dataHubApiURL = process.env.REACT_APP_BACKEND_DATA_HUB_API_URL;
console.log('REACT_APP_BACKEND_DATA_HUB_API_URL',dataHubApiURL)
const dataHubApi = axios.create({
    baseURL: dataHubApiURL,
    timeout: 50000,
    headers: {
        'Content-Type': 'application/json',
    }
});
export default dataHubApi;

export const dataHubDataFetch = async (offset, rowsPerPage, search, startDate, endDate) => {
    try {
        console.log('Fetching data with offset:', offset, 'limit:', rowsPerPage, 'search:', search, 'startDate:', startDate, 'endDate:', endDate);

        // Create a base params object
        const paramsObj = {
            offset,
            limit: rowsPerPage,
            search: encodeURIComponent(search),
        };
        if (startDate) {
            paramsObj.startDate = startDate;
        }
        if (endDate) {
            paramsObj.endDate = endDate;
        }
        const params = new URLSearchParams(paramsObj);
        console.warn(params.toString());
        const response = await dataHubApi.get(`/api/data?${params.toString()}`);

        return response.data; // Return the fetched data
    } catch (error) {
        console.error('Error fetching data:', error);
        return {success: false, message: 'Failed to fetch data'}; // Handle error
    }
};


export const dataHubDetailFetch = async (id) => {
    try {
        return await dataHubApi.get(`/api/data/${id}`);
        // console.warn(response);
    } catch (error) {
        console.error('Error fetching data:', error);
        return null;
    }
};
