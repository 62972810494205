import React, { useState, useEffect, useCallback } from 'react';
import { List, ListItem, ListItemText, Typography, Box, TextField, Button, Tooltip } from '@mui/material';
import { FormControlLabel, Modal } from '@mui/material';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import colors from '../../../components/styles/colors';
import Switch from '@mui/material/Switch';
import { getTools } from '../../../components/Apis/AgentApi';
import AgentListTemplate from '../../../components/Constants/AgentList';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const gradientButtonStyle = {
    background: `linear-gradient(180deg, ${colors.primary} 60%, ${colors.secondary} 90%)`,
    color: colors.white,
    '&:hover': {
      background: `linear-gradient(180deg, ${colors.primary} 60%, ${colors.secondary} 90%)`,
      opacity: 0.8,
    },
  };

const tempAgent = {
    "Agent": []
}
const modalStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '8px',
    backgroundColor: 'white',
    padding: '20px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    outline: 'none'
  };
  
const AgentsTab = (props) => {
    const [activeTool, setActiveTool] = useState('Enabled');
    const [activeAgent, setActiveAgent] = useState(null);
    // ];
    const [tools, setToolList] = useState([]);
    const filterUniqueToolsByType = (config) => {

        // Use a Set to track unique names
        const uniqueNames = new Set();
        uniqueNames.add("Enabled");
        uniqueNames.add("All")
        tools.map((tool) => uniqueNames.add(tool.name));
        config.Agent.forEach((agent) => {
            const innerList = agent.type.map((innerAgent) => innerAgent);

            // console.log(innerList); // Log the innerList as expected

            // Populate the uniqueNames Set
            innerList.forEach((name) => {
                uniqueNames.add(name); // Add each name to the Set
            });
        });

        // Transform the Set of unique names into an array of objects
        const newToolList = Array.from(uniqueNames).map((name, index) => ({
            id: index + 1, // Incremental ID starting from 1
            name: name // Original name
        }));

        // Update toolList with the new unique items
        setToolList(newToolList);
    };

    const [open, setOpen] = useState(false);
    const [config, setConfig] = useState('');

    // const handleOpen = (agent) => {
    //     console.log('agent__((',agent.config)
    //     if (Object.keys(agent.config).length > 5) {
    //         handleChange(agent.id, agent);
    //         setConfig(agent.config);
    //         setOpen(true);
    //     } else {
    //         setOpen(false);
    //         handleChange(agent.id, agent);
    //     }
    // };
    const handleOpen = (agent) => {
        console.log('agent__((', agent.config);
    
        // Check if agent is not null and agent.config is also a valid object
        if (agent !== null && agent.config !== null && Object.keys(agent.config).length > 5) {
            handleChange(agent.id, agent);
            setConfig(agent.config);
            setOpen(true);
        } else {
            setOpen(false);
            handleChange(agent.id, agent);
        }
    };
    const handleClose = () => setOpen(false);
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setConfig({ ...config, [name]: value });
    };

    const handleSubmit = () => {
        // Update the agent config (you might want to update it in the state as well)
        console.log('Updated Config:');
        // Optionally, perform any further logic here to update state or persist changes
        handleClose();
    };
    // Use useEffect to observe toolList updates
    // useEffect(() => {
    //     console.log('Updated toolList:', tools);
    // }, [tools]); // This will log the updated toolList after every update

    const [agentList, setAgentList] = React.useState(tempAgent)
    const [enabledAgentList, setEnabledAgentList] = React.useState([])
    const [apiAgent, setapiAgent] = useState();
    useEffect(() => {
        getTools().then((res) => {
            setapiAgent({ "Agent": res });
        });
    }, []);

    let currentAgent = null;

    useEffect(() => {
        setAgentList(apiAgent);
        if (apiAgent) {

            filterUniqueToolsByType(apiAgent);
            updateAgentListOnload();
        };

    }, [apiAgent])

    useEffect(() => {
        try {
            currentAgent = agentList.Agent.find(agent => agent.id === activeAgent);
        }
        catch (error) {
            console.error('Error:', error);
        }

    }, [activeAgent, agentList])

    const updateAgentListOnload = useCallback(() => {
        try {
            if (agentList?.Agent) {
                setAgentList((prevAgentList) => {
                    // Safety check for prevAgentList
                    if (!prevAgentList?.Agent) {
                        return
                    }
                    const updatedAgents = prevAgentList.Agent.map((agent) => {
                        // Check if the agent is in the activeAgents list
                        const isActive = props.agentData.agents_enabled.some(agents_enabled => agents_enabled.id === agent.id);

                        // If it is, set status to true, otherwise keep the existing status
                        return {
                            ...agent,
                            status: isActive ? true : agent.status
                        };
                    });

                    // Return the updated agentList with the modified Agent array
                    return {
                        ...prevAgentList,
                        Agent: updatedAgents
                    };
                });
            }
        }
        catch (error) {
            console.error('Error:', error);
        }
    }, [props.agentData.agents_enabled]);

    useEffect(() => {
        updateAgentListOnload();
        filterUniqueToolsByType(agentList);
    }, [updateAgentListOnload])

    // Find the active agent

    const handleAgentChange = (section, value) => {
        props.setAgentData(prevData => ({
            ...prevData,
            [section]: value
        }));
    };

    // Function to handle switch toggle and update agent's status
    const handleChange = (agentId,ag) => {
        // Map through the Agent array and update the status of the clicked agent
        const updatedAgents = agentList.Agent.map((agent) => {
            if (agent.id === agentId) {
                return {
                    ...agent,
                    status: !agent.status // Toggle the status
                };
            }
            return agent; // Return agent as-is if it's not the one being toggled
        });
        setAgentList((prevAgentList) => {
            // Return the updated agentList with the modified Agent array
            return {
                ...prevAgentList,
                Agent: updatedAgents
            };
        });


        const enabledAgents = updatedAgents.filter(agent => agent.status);
        setEnabledAgentList(enabledAgents);
        const newAgentList = enabledAgents.map((agent) => {
            return {
                id: agent.id,
                config: agent.config
            };
        });
        handleAgentChange("agents_enabled", newAgentList);

    };

    const handleClick = (id) => {
        setActiveTool(id);
    };

    const handleAgentClick = (toolId) => {
        setActiveAgent(toolId);
        // setCurrentAgent(agentList.Agent.find(agent => agent.id === activeAgent));
        // currentAgent.log("CUR",currentAgent);
    };


    // Filter agents based on the selected tool
    const filteredAgents = () => {
        if (!agentList?.Agent) {
            return [];
        }
        if (activeTool === 'Enabled') {
            return enabledAgentList; // Filter agents with status true
        } else if (activeTool === 'All') {
            return agentList.Agent; // Return all agents
        } else {
            // Check if activeTool matches a type
            const filteredByType = agentList.Agent.filter(agent => agent.type.includes(activeTool));
            if (filteredByType.length > 0) {
                return filteredByType; // Return agents matching the type
            } else {
                // If no agents found by type, filter by name instead
                return agentList?.Agent.filter(agent => agent.name.includes(activeTool)); // Filter by name
            }
        }
    };

    const onDragEnd = (result) => {
        const { source, destination } = result;
        if (!destination) return;
        const updatedAgents = [...filteredAgents()];
        const [movedItem] = updatedAgents.splice(source.index, 1);
        updatedAgents.splice(destination.index, 0, movedItem);
        setEnabledAgentList(updatedAgents);
    };

    return (
        <Box
            sx={{
                flexGrow: 1,
                padding: { xs: 1, sm: 2, md: 3 },
                height: { xs: 'auto', sm: 'auto', md: '85%' },
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                gap: 2
            }}
        >
            {/* Left section */}
            <Box
                sx={{
                    // border: `1px solid ${colors.silver}`,
                    flex: 3,
                    padding: { xs: 1, sm: 1 },
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: { xs: 'auto', md: '70%' },
                    background:"#fff",
                    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
                    borderRadius: 3
                }}
            >
            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 3 }}>
                <AccountCircleIcon sx={{ width: 56, height: 56, color: colors.silver }} />
                <Typography sx={{ marginLeft: 2, fontSize: '20px', fontWeight: 'bold' }}>
                    {props.agentData.profile.Name || 'Name Surname'}
                </Typography>
            </Box>

                <Box sx={{
                    display: 'flex',
                    gap: 1,
                    flexDirection: { xs: 'column', md: 'row' },
                    maxHeight: '87%',
                    minHeight: '87%',
                }}>
                    <Box sx={{ border: `1px solid ${colors.border}`, width: { xs: '95%', md: '26%' }, padding: 1, borderRadius:3 }}>
                        <p style={{ fontSize: '12px' }}>Tool settings / All tools</p>
                        <List
                            sx={{
                                overflowY: 'auto',
                                paddingRight: '2px',
                                maxHeight: { md: '84%' },
                                '&::-webkit-scrollbar': {
                                    width: '5px',
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    backgroundColor: colors.secondary,
                                    borderRadius: '10px',
                                },
                                '&::-webkit-scrollbar-track': {
                                    backgroundColor: 'transparent',
                                },
                            }}
                        >
                            {tools.map((tool) => (
                                <ListItem
                                    button
                                    key={tool.id}
                                    onClick={() => handleClick(tool.name)}
                                    sx={{
                                        borderRadius: '10px',
                                        marginBottom: '3px',
                                        bgcolor: activeTool === tool.name ? `${colors.primary}40` : `${colors.primary}10` ,
                                        boxShadow: activeTool ===tool.name ? `${'0 2px 10px rgba(0, 0, 0, 0.2   )'}`:`${colors.primary}`,
                                
                                        
                                        '&:hover': {
                                            bgcolor: activeTool === tool.name ? colors.white : `${colors.grey}`,
                                        },
                                    }}
                                >
                                    <ListItemText primary={tool.name} />
                                </ListItem>
                            ))}
                        </List>
                    </Box>

                    <Box padding={1} sx={{
                        overflowY: 'auto',
                        flex: 3,
                        minHeight: '65%',
                        border: `1px solid ${colors.border}`,
                        width: { xs: '100%', md: '55%' },
                        background:`#fff`,
                        borderRadius:3
                    }}>
                        <p style={{ fontSize: '12px' }}>Equip your agent with new tool</p>
                       
                        <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="enabled-agents">
                            {(provided) => (
                                <List
                                    sx={{
                                        overflowY: 'auto',
                                        paddingRight: '2px',
                                        maxHeight: { md: '84%' },
                                        '&::-webkit-scrollbar': {
                                            width: '5px',
                                        },
                                        '&::-webkit-scrollbar-thumb': {
                                            backgroundColor: colors.secondary,
                                            borderRadius: '10px',
                                        },
                                        '&::-webkit-scrollbar-track': {
                                            backgroundColor: 'transparent',
                                        },
                                    }}
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                >
                                    {filteredAgents().map((agent, index) => (
                                        <Draggable key={agent.id} draggableId={agent.id.toString()} index={index}>
                                            {(provided) => (
                                                <ListItem
                                                    button
                                                    sx={{
                                                        borderRadius: '10px',
                                                        marginBottom: '3px',
                                                        bgcolor: activeAgent === agent.id ? colors.lightBlue : `#fff`,
                                                        '&:hover': {
                                                            bgcolor: activeAgent === agent.id ? colors.lightBlue : "#d8d8d8",
                                                        },
                                                    }}
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >
                                                    <img
                                                        src={agent.icon}
                                                        alt='icon'
                                                        style={{ width: '40px', height: '40px', marginRight: '10px', background: 'transparent', boxShadow: '0px 4px 10px #dbc9de', padding:5, borderRadius:10 }}
                                                    />
                                                    <ListItemText
                                                        primary={
                                                            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                                                {agent.name}
                                                            </Typography>
                                                        }
                                                        secondary={
                                                            <Tooltip title={agent.description}>
                                                                <Typography
                                                                    variant="body2"
                                                                    sx={{
                                                                        maxWidth: '100%',
                                                                        display: '-webkit-box',
                                                                        WebkitBoxOrient: 'vertical',
                                                                        overflow: 'hidden',
                                                                        WebkitLineClamp: 1,
                                                                        textOverflow: 'ellipsis',
                                                                    }}
                                                                >
                                                                    {agent.description}
                                                                </Typography>
                                                            </Tooltip>
                                                        }
                                                    />
                                                    {/* <Switch
                                                        checked={agent.status}
                                                        onChange={(event) => handleChange(agent.id,agent)}
                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                    /> */}
                                                    <div>
                                                        <FormControlLabel
                                                            control={
                                                            <Switch
                                                                checked={agent.status}
                                                                onChange={() => handleOpen(agent)}
                                                                inputProps={{ 'aria-label': 'controlled' }}
                                                            />
                                                            }
                                                            // label={`${agent.id}`}
                                                        />
                                                        {/* <Modal
                                                                open={open}
                                                                onClose={handleClose}
                                                                BackdropProps={{
                                                                    style: {
                                                                        backgroundColor: 'transparent' // Remove dark background
                                                                    }
                                                                }}
                                                            >
                                                                <div style={modalStyle}>
                                                                    <h2>Edit Config</h2>
                                                                    {Object.keys(config).map((key) => (
                                                                        <TextField
                                                                            key={key}
                                                                            name={key}
                                                                            label={key}
                                                                            value={config[key]}
                                                                            onChange={handleInputChange}
                                                                            fullWidth
                                                                            margin="normal"
                                                                        />
                                                                    ))}
                                                                    <Button
                                                                        onClick={handleSubmit}
                                                                        variant="contained"
                                                                        color="primary"
                                                                    >
                                                                        Submit
                                                                    </Button>
                                                                </div>
                                                            </Modal> */}
                                                        </div>
                                                </ListItem>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </List>
                            )}
                        </Droppable>
                        </DragDropContext>
                    </Box>
                </Box>
            </Box>

            {/* Right section - Suggestions */}
            <Box
                sx={{
                    // border: `1px solid ${colors.silver}`,
                    overflowY: 'scroll',
                    flex: 1,
                    padding: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '70%',
                    alignItems: 'center',
                    '&::-webkit-scrollbar': {
                        width: '5px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: colors.secondary,
                        borderRadius: '10px',
                    },
                    '&::-webkit-scrollbar-track': {
                        backgroundColor: 'transparent',
                    },
                    background:"#fff",
                    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
                    borderRadius: 3
                }}
            >
                {(activeAgent && currentAgent) ? (
                    <Typography variant="body2">
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column', // Ensure items stack vertically
                                justifyContent: 'center', // Align horizontally in the center
                                alignItems: 'center', // Align vertically in the center
                                margin: 'auto',
                            }}
                        >
                            <img
                                src={currentAgent.icon}
                                alt={currentAgent.name}
                                style={{ width: '100px', height: '100px', marginRight: '10px', background: 'transparent', margin: 'auto' }}
                            />
                            <Typography gutterBottom style={{ fontSize: '20px', fontWeight: 'bold', alignSelf: 'center' }}>
                                {currentAgent.name} {/* Configuration */}
                            </Typography>

                            {/* Loop through config keys and create TextFields */}
                            {Object.keys(currentAgent.config).map((key, index) => (
                                <TextField
                                    key={index}
                                    label={key.charAt(0).toUpperCase() + key.slice(1)}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    type={key === "password" ? "password" : "text"}
                                    InputLabelProps={{
                                        style: { color: colors.primary },
                                    }}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: colors.silver,
                                                borderRadius: '25px'
                                            },
                                            '&:hover fieldset': {
                                                borderColor: colors.primary,
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: colors.silver,
                                            },
                                        },
                                    }}
                                // defaultValue={currentAgent.config[key]} 
                                />
                            ))}

                            <Button
                                variant="contained"
                                fullWidth
                                sx={{
                                    background: `linear-gradient(180deg, ${colors.primary}, ${colors.secondary})`,
                                    color: 'white',
                                    marginTop: 2,
                                    padding: '10px 0',
                                    borderRadius: '30px',
                                    fontWeight: 'bold',
                                }}
                            >
                                Save
                            </Button>
                        </Box>
                    </Typography>
                ) : open?( 
                    <div >
                        <Typography sx={{ marginBottom: 2, fontWeight: 'bold' }}>
                            Edit Config
                        </Typography>
                        {Object.keys(config).map((key) => (
                            <TextField
                                key={key}
                                name={key}
                                label={key}
                                value={config[key]}
                                onChange={handleInputChange}
                                fullWidth
                                margin="normal"
                                size="small"
                            />
                        ))}
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
                            <Button
                                onClick={handleSubmit}
                                variant="contained"
                                size='small'
                                sx={gradientButtonStyle}
                                style={{ borderRadius: '10px', padding: '5px 20px'}}
                            >
                                Submit
                            </Button>                            
                        </div>                        
                    </div>
                ):(
                    <>
                        <Typography sx={{ marginBottom: 2, fontWeight: 'bold' }}>
                            Suggestions
                        </Typography>
                        <Typography variant="body2" sx={{ fontSize: '14px' }}>
                            <p>Here is a list of third-party and in-house tools available.</p>
                            <p>Your agent can use these tools by enabling them.</p>
                            <p>Some tools require setup of credentials and configuration.</p>
                        </Typography>
                    </>
                )}
            </Box>
        </Box>
    );
};

export default AgentsTab;
