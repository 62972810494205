import React, { useState, useEffect } from 'react';
import { List, ListItem, Tooltip, Box, Button, Stepper, Step, StepLabel } from '@mui/material';
import { Badge } from '@mui/material';
import { profile, Instructions, ServiceIntegrations, Others } from '../../Constants/AgentNavbarItems';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import colors from '../../styles/colors';
import { useNavigate } from 'react-router-dom';
import { createAgentApi, updateAgent } from '../../Apis/AgentApi';
import profile_image from '../../list-image/1505516.png';
import { BorderAllOutlined } from '@mui/icons-material';

const gradientButtonStyle = {
    background: `linear-gradient(180deg, ${colors.primary} 60%, ${colors.secondary} 90%)`,
    color: colors.white,
    '&:hover': {
        background: `linear-gradient(180deg, ${colors.primary} 60%, ${colors.secondary} 90%)`,
        opacity: 0.8,
    },
};

const AgentNavBar = ({ setNavId, setNavCollapsed, isVisible, agentData, closePopup, isEditi, agentid }) => {
    const navigate = useNavigate();
    const [collapsed, setCollapsed] = useState(true);
    const [hovered, setHovered] = useState(false);
    const [activeItem, setActiveItem] = useState(0);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 960) {
                setCollapsed(false);
                setNavCollapsed(false);
            } else {
                setCollapsed(true);
                setNavCollapsed(true);
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, [setNavCollapsed]);

    const toggleCollapse = () => {
        setCollapsed(!collapsed);
        setNavCollapsed(!collapsed);
    };

    const handleItemClick = (route, id) => {
        setNavId(id);
        setActiveItem(id);
        navigate(route);
    };

    const listItemStyles = {
        base: {
            color: colors.primary,
            padding: '10px',
            borderRadius: '10px',
            transition: 'background-color 0.3s ease',
            display: 'flex',
            alignItems: 'center',
        },
        active: {
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
        },
        inactive: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
        },
    };

    const excludedLabels = ['Behaviour Setting', 'Reference Documents', 'Access & Permissions'];

    const filteredItems = [...profile, ...Instructions, ...ServiceIntegrations, ...Others].filter((item) => {
        return agentData?.profile?.WorkflowAgent ? !excludedLabels.includes(item.label) : true;
    });

    return (
        <Box
            sx={{
                width: '100%',
                minHeight: '70px',
                background: "#F5F5F9",
                color: colors.white,
                borderRadius: '8px',
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
                padding: '0 20px',
                boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
            }}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        >
            <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
                <Stepper
                    activeStep={filteredItems.findIndex((item) => item.id === activeItem)}
                    alternativeLabel
                    sx={{
                        width: '80%',
                        margin: '0 auto', 
                    }}
                >
                    {filteredItems.map((item) => (
                        <Step key={item.id} onClick={() => handleItemClick(item.route, item.id)}
                            sx={{
                                cursor: 'pointer',
                                '& .MuiStepLabel-label': {
                                    color: activeItem === item.id ? '#000' : '#a1a1a1',
                                    fontWeight: activeItem === item.id ? 'bold' : 'normal',
                                },
                                '& .MuiStepIcon-root': {
                                    color: activeItem === item.id ? '#000' : '#d3d3d3',
                                },
                            }}>
                            <StepLabel>
                                {item.label}
                            </StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Box>

            <Button
                variant="contained"
                sx={gradientButtonStyle}
                style={{ borderRadius: '20px', marginLeft: "auto" }}
                onClick={() => { (isEditi ? updateAgent(agentData, agentid) : createAgentApi(agentData)); closePopup(); }}
            >
                Finish
            </Button>
        </Box>
    );
};

export default AgentNavBar;