import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  DialogTitle,
  DialogContent,
  TextField,
  Typography,
  IconButton,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  FormLabel,
  OutlinedInput,
  Grid
} from '@mui/material';
import colors from '../../../components/styles/colors';
import WorkflowAgentList from '../../../components/Constants/WorkflowAgents';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';

const VoiceOption = ({ voiceName, isPlaying, onPlayPause }) => (
  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
    <Typography variant="body1" style={{ flexGrow: 1 }}>
      {voiceName}
    </Typography>
    <IconButton onClick={onPlayPause} style={{ padding: 0 }}>
      <div
        style={{
          backgroundColor: isPlaying ? colors.secondary : colors.primary,
          borderRadius: '50%',
          width: '30px',
          height: '30px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        {isPlaying ? (
          <PauseIcon style={{ color: 'white', padding: 0 }} />
        ) : (
          <PlayArrowIcon style={{ color: 'white', padding: 0 }} />
        )}
      </div>
    </IconButton>
  </div>
);

const Profile = (props) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [isChecked, setIsChecked] = useState(props.agentData.profile.VoiceBot.Required);
  const [isWorkflowAgentChecked, setIsWorkflowAgentChecked] = useState(props.agentData.profile.WorkflowAgent);
  const [selectedValue, setSelectedValue] = useState(props.agentData.profile.VoiceBot.Voice);
  const [selectedTemplateValue, setSelectedTemplateValue] = useState(props.agentData.profile.Template);
  const [audioFiles, setAudioFiles] = useState([]);
  const [currentPlaying, setCurrentPlaying] = useState(null);
  const audioRef = useRef(new Audio());

  const toBase64 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

  const handleChange = (section, field, value) => {
    props.setAgentData(prevData => ({
      ...prevData,
      [section]: {
        ...prevData[section],
        [field]: value
      }
    }));
  };

  // For nested objects like VoiceBot
  const handleNestedChange = (section, nestedField, field, value) => {
    props.setAgentData(prevData => ({
      ...prevData,
      [section]: {
        ...prevData[section],
        [nestedField]: {
          ...prevData[section][nestedField],
          [field]: value
        }
      }
    }));
  };

  const setAgentImageonLoad = useCallback(() => {
    const profileImage = props.agentData.profile.ProfileImage;
    if (profileImage && profileImage.startsWith("data:image/")) {
      setSelectedImage(profileImage);
    }
  }, [props.agentData.profile.ProfileImage]);

  const setAgentDetails = (agentName) => {
    const agent = WorkflowAgentList[agentName];

    if (agent && agent.operational_workflow) {
      // Split the operational_workflow string into a list using \n\n as delimiter
      const workflowList = agent.operational_workflow.split("\n\n");
      const selectedAgentData = {
        behaviour: agent.behaviour || "No behaviour information available",
        operationalWorkflow: workflowList || ["No operational workflow available"],
        permission: agent.permission || "No permission information available",
      };
      handleChange('BehaviourSettings', 'Details', selectedAgentData.behaviour);
      handleChange('OperationalWorkflow', 'FlowConditions', selectedAgentData.operationalWorkflow);
    }
  };


  useEffect(() => {
    const audios = [
      { name: 'Voice 1', file: `${process.env.PUBLIC_URL}/botVoices/Voice1.mp3` },
      { name: 'Voice 2', file: `${process.env.PUBLIC_URL}/botVoices/voice2.mp3` },
    ];

    setAudioFiles(audios);
    setAgentImageonLoad();
  }, [setAgentImageonLoad, props]);

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const base64Image = await toBase64(file);
      setSelectedImage(base64Image);
      handleChange('profile', 'ProfileImage', base64Image);
    }
  };
  const handleBotCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
    handleNestedChange('profile', 'VoiceBot', 'Required', event.target.checked);
  };
  const handleAgentCheckboxChange = (event) => {
    setIsWorkflowAgentChecked(event.target.checked);
    handleChange('profile', 'WorkflowAgent', event.target.checked);
  };

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
    handleNestedChange('profile', 'VoiceBot', 'Voice', event.target.value);
  };

  const handleTemplateSelectChange = (event) => {
    setSelectedTemplateValue(event.target.value);
    handleChange('profile', 'Template', event.target.value);
    setAgentDetails(event.target.value);
  };

  const handlePlayPauseVoice = (audioFile) => {
    if (currentPlaying === audioFile) {
      audioRef.current.pause();
      setCurrentPlaying(null);
    } else {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
      audioRef.current.src = audioFile;
      audioRef.current.play();
      setCurrentPlaying(audioFile);
    }

    audioRef.current.onended = () => {
      setCurrentPlaying(null);
    };
  };


  return (
    <Box
      sx={{
        margin: 3,
        background: "#fff",
        borderRadius: 3,
        padding: 5,
        boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Typography variant="h5" sx={{ marginBottom: 3, fontWeight: "bold",
                  borderColor: colors.primary }}>
        Agent Profile
      </Typography>

      <Grid container spacing={3}>
        {/* Profile Picture Section */}
        <Grid item xs={12} md={4} sx={{ textAlign: "center" }}>
          <input
            type="file"
            accept="image/*"
            onChange={handleImageUpload}
            style={{ display: "none" }}
            id="profile-picture-input"
          />
          <label
            htmlFor="profile-picture-input"
            style={{
              position: "relative",
              width: 150,
              height: 150,
              borderRadius: "50%",
              border: `4px solid ${colors.silver}`,
              overflow: "hidden",
              cursor: "pointer",
              display: "inline-block",
            }}
          >
            <IconButton
              component="span"
              sx={{
                padding: 0,
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.1)",
                },
              }}
            >
              {selectedImage ? (
                <img
                  src={selectedImage}
                  alt="Profile"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
              ) : (
                <AddAPhotoIcon sx={{ fontSize: 40, color: colors.silver }} />
              )}
            </IconButton>
          </label>
        </Grid>

        {/* Form Section */}
        <Grid item xs={12} md={8}>
          <FormControl fullWidth sx={{ marginBottom: 3 }}>
            <FormLabel
              sx={{
                fontSize: "16px",
                color: colors.primary,
                fontWeight: "bold",
              }}
            >
              Agent Name
            </FormLabel>
            <OutlinedInput
              value={props.agentData.profile.Name}
              onChange={(e) => handleChange("profile", "Name", e.target.value)}
              placeholder="Enter Name"
              sx={{
                borderRadius: 3,
                "& .MuiOutlinedInput-notchedOutline": { borderColor: colors.silver },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: colors.primary,
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: colors.primary,
                },
              }}
            />
          </FormControl>

          <FormControl fullWidth sx={{ marginBottom: 3 }}>
            <FormLabel
              sx={{
                fontSize: "16px",
                color: colors.primary,
                fontWeight: "bold",
              }}
            >
              Agent Description
            </FormLabel>
            <OutlinedInput
              value={props.agentData.profile.Description}
              onChange={(e) => handleChange("profile", "Description", e.target.value)}
              placeholder="Describe what this agent does."
              multiline
              rows={4}
              sx={{
                borderRadius: 3,
                "& .MuiOutlinedInput-notchedOutline": { borderColor: colors.silver },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: colors.primary,
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: colors.primary,
                },
              }}
            />
            {/* <Typography variant="body2" color="textSecondary" sx={{ marginTop: 1 }}>
              Describe what this agent does.
            </Typography> */}
          </FormControl>

          {/* Workflow Agent Checkbox */}
          <Box sx={{ display: "flex", alignItems: "center", marginBottom: 3 }}>
            <Checkbox
              checked={isWorkflowAgentChecked}
              onChange={handleAgentCheckboxChange}
              sx={{ color: isWorkflowAgentChecked ? colors.secondary : colors.silver }}
            />
            <Typography variant="body1" sx={{ fontWeight: "bold", 
                color: colors.primary,}}>
              Workflow Agent
            </Typography>
          </Box>

          {/* Template Selection */}
          <FormControl fullWidth sx={{ marginBottom: 3 }}>
            <FormLabel
              sx={{
                fontSize: "16px",
                color: colors.primary,  
                fontWeight: "bold",
              }}
            >
              Select a Template
            </FormLabel>
            <Select
              value={selectedTemplateValue}
              onChange={handleTemplateSelectChange}
              MenuProps={{
                PaperProps: { style: { maxHeight: 200, width: "auto" } },
              }}
              sx={{
                borderRadius: 3,
                "& .MuiOutlinedInput-notchedOutline": { borderColor: colors.silver },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: colors.primary,
                },
              }}
            >
              {Object.keys(WorkflowAgentList).map((key) => (
                <MenuItem key={key} value={key}>
                  {key}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Include Voice Assistant */}
          <Box sx={{ display: "flex", alignItems: "center", marginBottom: 3 }}>
            <Checkbox
              checked={isChecked}
              onChange={handleBotCheckboxChange}
              sx={{ color: isChecked ? colors.secondary : colors.silver }}
            />
            <Typography variant="body1" sx={{ fontWeight: "bold",
                color: colors.primary }}>
              Include Voice Assistant
            </Typography>
          </Box>

          {/* Bot Voice Dropdown */}
          {isChecked && (
            <FormControl fullWidth>
              <FormLabel
                sx={{
                  fontSize: "16px",
                  color: "#000",
                  fontWeight: "bold",
                }}
              >
                Select a Voice
              </FormLabel>
              <Select
                value={selectedValue}
                onChange={handleSelectChange}
                MenuProps={{
                  PaperProps: { style: { maxHeight: 200, width: "auto" } },
                }}
                sx={{
                  borderRadius: 3,
                  "& .MuiOutlinedInput-notchedOutline": { borderColor: colors.silver },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: colors.primary,
                  },
                }}
              >
                {audioFiles.map((audio, index) => (
                  <MenuItem
                    key={index}
                    value={audio.name}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <VoiceOption
                      voiceName={audio.name}
                      isPlaying={currentPlaying === audio.file}
                      onPlayPause={(e) => {
                        e.stopPropagation();
                        handlePlayPauseVoice(audio.file);
                      }}
                    />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Grid>
      </Grid>
    </Box>

  );
};

export default Profile;
