import React, { useState } from 'react';
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextField,
    Select,
    MenuItem,
    Button,
    Typography,
    Divider,
    InputAdornment,
    Modal,
    IconButton
} from '@mui/material';
import { border, borderRadius, styled } from '@mui/system';
import ApiIcon from '@mui/icons-material/Api';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import RefreshIcon from '@mui/icons-material/Refresh';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import CloudIcon from '@mui/icons-material/Cloud';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import EmailIcon from '@mui/icons-material/Email';
import FlagIcon from '@mui/icons-material/Flag';
import LanguageIcon from '@mui/icons-material/Language';
import { Edit, Save, Add, Close } from '@mui/icons-material';
import colors from '../../../components/styles/colors';
import SearchIcon from '@mui/icons-material/Search';

const gradientButtonStyle = {
    borderRadius:"20px",
    background: `linear-gradient(180deg, ${colors.primary} 60%, ${colors.secondary} 90%)`,
    color: colors.white,
    '&:hover': {
      background: `linear-gradient(180deg, ${colors.primary} 60%, ${colors.secondary} 90%)`,
      opacity: 0.8,
    },
  };

function Assets() {
    const [globalVariables, setGlobalVariables] = useState([
        { name: 'LOG_LEVEL', icon: <TextSnippetOutlinedIcon sx={{ color: '#9E3C5D' }} />, type: 'field', value: 'INFO', isEditing: false },
        { name: 'ENVIRONMENT', icon: <CloudIcon sx={{ color: '#9E3C5D' }} />, type: 'field', value: 'production', isEditing: false },
        { name: 'CACHE_DURATION', icon: <TimelapseIcon sx={{ color: '#9E3C5D' }} />, type: 'dropdown', value: '300', options: ['300', '500', '1000'], isEditing: false },
        { name: 'FEATURE_FLAG_NEW_UI', icon: <FlagIcon sx={{ color: '#9E3C5D' }} />, type: 'dropdown', value: 'True', options: ['True', 'False'], isEditing: false },
        { name: 'DEFAULT_LANGUAGE', icon: <LanguageIcon sx={{ color: '#9E3C5D' }} />, type: 'dropdown', value: 'en', options: ['en', 'de', 'es'], isEditing: false },
    ]);

    const [searchTerm, setSearchTerm] = useState('');
    const [isModalOpen, setModalOpen] = useState(false);
    const [newVariable, setNewVariable] = useState({
        name: '',
        type: 'field',
        value: '',
        options: [],
    });

    const handleAddVariable = () => {
        if (newVariable.name && newVariable.value) {
            setGlobalVariables([...globalVariables, { ...newVariable, isEditing: false }]);
            setNewVariable({ name: '', type: 'field', value: '', options: [] });
            setModalOpen(false);
        }
    };


    const handleEditClick = (index) => {
        const updatedVariables = [...globalVariables];
        updatedVariables[index].isEditing = !updatedVariables[index].isEditing;
        setGlobalVariables(updatedVariables);
    };

    const handleVariableChange = (index, newValue) => {
        const updatedVariables = [...globalVariables];
        updatedVariables[index].value = newValue;
        setGlobalVariables(updatedVariables);
    };

    const handleSave = (index) => {
        const updatedVariables = [...globalVariables];
        updatedVariables[index].isEditing = false;
        setGlobalVariables(updatedVariables);
    };

    const filteredVariables = globalVariables.filter(variable =>
        variable.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <Box
            sx={{
                flexGrow: 1,
                padding: 3,
                height: '100%',
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'column', md: 'column' }
            }}
        >
            {/* <Typography variant="h5" gutterBottom fontWeight="bold" color="textSecondary">Assets</Typography>
            <Divider/> */}
            <Box sx={{display:"flex", alignItems:"center"}}>
                <Box sx={{width:"70%"}}>           
                    <TextField
                        placeholder="Search Variables"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        sx={{
                            marginBottom: 3,
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: colors.silver,
                                    borderRadius: '15px'
                                },
                                '&:hover fieldset': {
                                    borderColor: colors.primary,
                                    borderRadius: '15px'
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: colors.silver,
                                    borderRadius: '15px'
                                },
                            },
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon sx={{ color: colors.silver }} />
                                </InputAdornment>
                            ),
                        }} 
                    />
                </Box>
                <Box flexGrow={1}/> 
                <Box sx={{alignItems:"center"}}>
                    <Button
                        variant="contained"
                        startIcon={<Add />}
                        onClick={() => setModalOpen(true)}
                        sx={gradientButtonStyle}
                    >
                        Add Variable
                    </Button>
                </Box>                
            </Box>
            <TableContainer component={Paper} sx={{ maxHeight: 700, overflowY: 'auto' }}>
                <Table sx={{ boxShadow: 2 }}>
                    <TableHead sx={{ background: `#e5e5e5` }}>
                        <TableRow>
                            <TableCell >
                                <Typography variant="subtitle1" color="textSecondary" fontWeight={"bold"}>Variable Name</Typography>
                            </TableCell>
                            <TableCell >
                                <Typography variant="subtitle1" color="textSecondary" fontWeight={"bold"} sx={{ marginLeft: 12 }}>Current Value</Typography>
                            </TableCell>
                            <TableCell align="center">
                                <Typography variant="subtitle1" color="textSecondary" fontWeight={"bold"}>Actions</Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredVariables.map((variable, index) => (
                            <TableRow key={variable.name} hover>
                                <TableCell >
                                    <Box display="flex" alignItems="center">
                                        <Typography variant="body1" ml={1}>{variable.name}</Typography>
                                    </Box>
                                </TableCell>
                                <TableCell >
                                    {variable.isEditing ? (
                                        variable.type === 'field' ? (
                                            <TextField
                                                value={variable.value}
                                                onChange={(e) => handleVariableChange(index, e.target.value)}
                                                variant="outlined"
                                                sx={{
                                                    backgroundColor: 'background.paper',
                                                    borderRadius: 1,
                                                    width: 300,
                                                    margin: "auto"
                                                }}
                                                InputProps={{
                                                    inputProps: { style: { textAlign: 'center' } }
                                                }}
                                            />
                                        ) : variable.type === 'dropdown' ? (
                                            <Select
                                                value={variable.value}
                                                onChange={(e) => handleVariableChange(index, e.target.value)}
                                                variant="outlined"
                                                sx={{
                                                    backgroundColor: 'background.paper',
                                                    borderRadius: 1,
                                                    width: 300,
                                                    margin: "auto"
                                                }}
                                            >
                                                {variable.options.map((option) => (
                                                    <MenuItem key={option} value={option}>
                                                        {option}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        ) : null
                                    ) : (
                                        <TextField
                                            value={variable.value}
                                            variant="outlined"
                                            sx={{
                                                backgroundColor: 'background.paper',
                                                borderRadius: 1,
                                                width: 300,
                                                margin: "auto",
                                                '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                        borderColor: '#d8d8d8',
                                                    },
                                                    '&:hover fieldset': {
                                                        borderColor: '#aaa',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: '#9E3C5D',
                                                    },
                                                },
                                            }}
                                            InputProps={{
                                                inputProps: {
                                                    style: { textAlign: 'center' },
                                                    readOnly: true
                                                }
                                            }}
                                        />
                                    )}
                                </TableCell>
                                <TableCell align="center">
                                    {variable.isEditing ? (
                                        <Button
                                            size='small'
                                            variant="outlined"
                                            startIcon={<Save />}
                                            onClick={() => handleSave(index)}
                                            style={{ color: '#9E3C5D', border: '1px solid #9E3C5D ' }}
                                        >
                                            Save
                                        </Button>
                                    ) : (
                                        <Button
                                            size='small'
                                            variant="outlined"
                                            startIcon={<Edit />}
                                            onClick={() => handleEditClick(index)}
                                            style={{ color: '#9E3C5D', border: '1px solid #9E3C5D ' }}
                                        >
                                            Edit
                                        </Button>
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Modal open={isModalOpen} onClose={() => setModalOpen(false)}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        p: 4,
                        borderRadius: 1,
                        boxShadow: 24,
                    }}
                >
                    <IconButton
                        onClick={() => setModalOpen(false)}
                        sx={{ position: 'absolute', top: 8, right: 8 }}
                    >
                        <Close />
                    </IconButton>
                    <Typography variant="h6" gutterBottom>
                        Add New Variable
                    </Typography>
                    <TextField
                        fullWidth
                        label="Variable Name"
                        value={newVariable.name}
                        onChange={(e) => setNewVariable({ ...newVariable, name: e.target.value })}
                        margin="normal"
                    />
                    <Select
                        fullWidth
                        value={newVariable.type}
                        onChange={(e) => setNewVariable({ ...newVariable, type: e.target.value })}
                        margin="normal"
                    >
                        <MenuItem value="field">Field</MenuItem>
                        <MenuItem value="dropdown">Dropdown</MenuItem>
                    </Select>
                    {newVariable.type === 'dropdown' && (
                        <TextField
                            fullWidth
                            label="Dropdown Options (comma-separated)"
                            value={newVariable.options.join(', ')}
                            onChange={(e) =>
                                setNewVariable({
                                    ...newVariable,
                                    options: e.target.value.split(',').map((opt) => opt.trim()),
                                })
                            }
                            margin="normal"
                        />
                    )}
                    <TextField
                        fullWidth
                        label="Value"
                        value={newVariable.value}
                        onChange={(e) => setNewVariable({ ...newVariable, value: e.target.value })}
                        margin="normal"
                    />
                    <Box sx={{display: "flex",justifyContent:"center", width:"100%"}}>
                        <Button
                            variant="contained"
                            onClick={handleAddVariable}
                            sx={gradientButtonStyle}
                        >
                            Add Variable
                        </Button>                        
                    </Box>
                </Box>
            </Modal>
        </Box>
    );
}

export default Assets;
