import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  Button,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import colors from '../../components/styles/colors';
import AgentNavbar from '../../components/Navbar/AgentNavbar/AgentNavbar';
import Profile from './CreateAgent/Profile';
import Behaviour from './CreateAgent/Behaviour';
import OperationalWorkflow from './CreateAgent/OperationalWorkflow';
import AgentsTab from './CreateAgent/AgentsTab';
import ReferenceDoc from './CreateAgent/ReferenceDoc';
import AgentTemplate from '../../components/Constants/AgentDetails';
import AccessAndPermissions from './CreateAgent/AccessAndPermissions';
import AdvancedSettings from './CreateAgent/AdvancedSettings';
import Assets from './CreateAgent/Assets';
import Validation from './CreateAgent/Validation';
import { getAgentdata } from '../../components/Apis/AgentApi';
// import React, { useState, useEffect } from 'react';
// import { getAgentdata } from '../../Apis/AgentApi'; // Ensure proper import
// import AgentNavbar from './AgentNavbar'; // Adjust the path as necessary
// import Profile from './Profile'; // Assuming Profile is a component you use
// import Behaviour from './Behaviour'; // Assuming Behaviour is a component you use
// import OperationalWorkflow from './OperationalWorkflow'; // Assuming it is a component
// import AgentsTab from './AgentsTab'; // Assuming it is a component
// import ReferenceDoc from './ReferenceDoc'; // Assuming it is a component
// import Validation from './Validation'; // Assuming it is a component
// import Assets from './Assets'; // Assuming it is a component
// import AccessAndPermissions from './AccessAndPermissions'; // Assuming it is a component
// import AdvancedSettings from './AdvancedSettings'; // Assuming it is a component
// import CloseIcon from '@mui/icons-material/Close'; // Ensure the icon import
// import colors from '../../styles/colors'; // Add colors if required

const AgentProfile = ({ id, handleClose }) => {
  const [navId, setNavId] = useState(0);
  const [collapsed, setCollapsed] = useState(true);
  const [isFinishButtonVisible, setIsFinishButtonVisible] = useState(true);
  const [agentData, setAgentData] = useState(AgentTemplate); // Ensure AgentTemplate is defined/imported
  const [isAgentEdit, setAgentEdit] = useState(false);

  const setNavCollapsed = (e) => {
    setCollapsed(e);
  };

  useEffect(() => {
    console.log('agentData***',agentData)
    if (id) {
      getAgentdata(id).then((res) => {
        setAgentData(res.data);
        setAgentEdit(true);
      });
    }
  }, [id]);

  // useEffect(() => {
  //   setIsFinishButtonVisible(navId === 24);
  // }, [navId]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%', borderRadius: '10px', overflow: 'hidden' }}>
      {/* Navbar */}
      <div style={{ 
        height: '60px', // Set a fixed height for the navbar
        backgroundColor: colors.white,
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
      }}>
        <AgentNavbar 
          setNavId={setNavId} 
          setNavCollapsed={setNavCollapsed} 
          isVisible={isFinishButtonVisible} 
          agentData={agentData} 
          closePopup={handleClose} 
          isEditi={isAgentEdit} 
          agentid={id}
        />
      </div>

      {/* Profile Section */}
      <div style={{
        flex: 1, // Take the remaining height
        overflowY: 'auto', // Allow scrolling if content overflows
        padding: '16px',
        minHeight: '1000px',
        maxHeight: '1000px'
      }}>
        {navId === 0 && <Profile agentData={agentData} setAgentData={setAgentData} />}
        {navId === 1 && <Behaviour agentData={agentData} setAgentData={setAgentData} />}
        {navId === 2 && <OperationalWorkflow agentData={agentData} setAgentData={setAgentData} />}
        {navId === 11 && <AgentsTab agentData={agentData} setAgentData={setAgentData} />}
        {navId === 12 && <ReferenceDoc agentData={agentData} setAgentData={setAgentData} />}
        {navId === 21 && <Validation agentData={agentData} setAgentData={setAgentData} />}
        {navId === 22 && <Assets agentData={agentData} setAgentData={setAgentData} />}
        {navId === 23 && <AccessAndPermissions agentData={agentData} setAgentData={setAgentData} />}
        {navId === 24 && <AdvancedSettings agentData={agentData} setAgentData={setAgentData} />}
        
        <div style={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          zIndex: 1 // Keep the close button on top
        }}>
          <button onClick={handleClose}>
            <CloseIcon />
          </button>
        </div>
      </div>
    </div>
  );
};

export default AgentProfile;