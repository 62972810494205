import { Home, Notifications, Settings, Dashboard, Extension, Build, HelpOutline } from '@mui/icons-material';
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import PreviewIcon from '@mui/icons-material/Preview';

export const NavbarTopItems = [
    // {
    //     id: 1,
    //     icon: <Home />,
    //     label: 'My Hive',
    //     route: 'home',
    // },
    // {
    //     id: 2,
    //     icon: <Notifications/>,
    //     label: 'Notifications',
    //     route: '',
    // },
    // {
    //     id: 3,
    //     icon: <Settings />,
    //     label: 'Settings',
    //     route: '',
    // },
    // {
    //     id: 4,
    //     icon: <Dashboard />,
    //     label: 'Templates',
    //     route: '',
    // },
]
export const UiLinkingItems = [
    {
        id: 31,
        icon: <ModelTrainingIcon />,
        label: 'Training',
        route: 'Training',
    },
    {
        id: 32,
        icon: <GroupWorkIcon/>,
        label: 'Task Allocation',
        route: 'WorkAllocation',
    },
]

export const NavbarWorkplaceItems = [
    {
        id: 11,
        icon: <Extension/>,
        label: 'Agents',
        route: 'agent',
    },
    {
        id: 12,
        icon: <Build/>,
        label: 'Tools',
        route: 'tools',
    },
    // {
    //     id: 13,
    //     icon: <Dashboard/>,
    //     label: 'DataHub',
    //     route: 'DataHub',
    // },
    // {
    //     id: 14,
    //     icon: <Dashboard/>,
    //     label: 'Dashboard',
    //     route: 'Dashboard',
    // },
    // {
    //     id: 15,
    //     icon: <PreviewIcon/>,
    //     label: 'Data View',
    //     route: 'DataView',
    // },
]

export const NavbarBottomItems = [
    // {
    //     id: 21,
    //     icon: <HelpOutline/>,
    //     label: 'Cruzemortal',
    //     route: '',
    // },
    {
        id: 22,
        icon: <HelpOutline/>,
        label: 'Support/Help',
        route: '',
    },
]
