import React from 'react';
import Horizontalchart from './Horizontalchart';

const DotBarChart = () => {
    const data = [5, 8, 3, 7, 6, 2, 4, 10, 2];
    const maxHeight = Math.max(...data);

    const dotStyle = {
        width: '10px',
        height: '10px',
        borderRadius: '50%',
        backgroundColor: 'transparent',
        margin: '3px',
        opacity: 0,
        transform: 'scale(0)',
        animation: 'grow 0.4s ease-out forwards',
    };

    const filledDotStyle1 = {
        backgroundColor: '#ff4081', // Color for odd bars
    };

    const filledDotStyle2 = {
        backgroundColor: '#ff9e80', // Color for even bars
    };

    // Inline animation for dots
    const animationStyle = {
        '@keyframes grow': {
            '0%': {
                opacity: 0,
                transform: 'scale(0)',
            },
            '100%': {
                opacity: 1,
                transform: 'scale(1)',
            },
        },
    };

    return (
        <div style={{ display: 'block', textAlign: 'left' }}>
            <h2 style={{ margin: 0, padding: 0, textAlign: 'left' }}>DIALOG STATISTIC</h2>
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                paddingLeft: '25px',
                paddingRight: '25px'
            }}>
                <div style={{
                    display: 'flex',
                    padding: '0px 5px',
                    fontFamily: 'Arial, sans-serif',
                    maxWidth: '200px',
                    maxHeight:'150px',
                    justifyContent: 'flex-start',
                }}>
                    {data.map((value, index) => (
                        <div key={index} style={{
                            display: 'flex',
                            flexDirection: 'column-reverse',
                            alignItems: 'center',
                        }}>
                            {[...Array(maxHeight)].map((_, rowIndex) => (
                                <divl
                                    key={rowIndex}
                                    style={{
                                        ...dotStyle,
                                        ...(rowIndex < value
                                            ? index % 2 === 0
                                                ? filledDotStyle1
                                                : filledDotStyle2
                                            : {}),
                                        animationDelay: `${0.08 * rowIndex}s`,
                                    }}
                                />
                            ))}
                        </div>
                    ))}
                    <style>
                        {` @keyframes grow {
                            0% {
                                opacity: 0;
                                transform: scale(0);
                            }
                            100% {
                            opacity: 1;
                            transform: scale(1);
                            }
                        }
                        `}
                    </style>
                </div>
                <Horizontalchart width='60%' height='60%' />
            </div>
            <div style={{display:'flex',justifyContent: 'space-between',paddingLeft: '25px',paddingRight: '25px'}}>
                    <p style={{
                        color: 'white',
                        display: 'block',
                        fontSize: '15px',
                        maxWidth: '200px',
                        textAlign: 'left', 
                        margin:0,
                        padding:0
                    }}>
                        Dialog per month
                    </p>
                
            </div>
            <div style={{ display: 'flex', gap: 2 }}>
                <h1 style={{ fontSize: '50px', margin: 0, padding: 0, textAlign: 'left', paddingBottom:'5px' }}>300000+</h1>
                <p style={{ background: '#fff', color: '#000', fontWeight: 'bold', fontSize: '13px', alignContent: 'center', padding: '2px', borderRadius: '5px', marginLeft: '20px', paddingLeft:'2px' }}>Total Dialogs</p>
            </div>
        </div >
    );
};

export default DotBarChart;
