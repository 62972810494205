import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { dataHubDataFetch } from '../../components/Apis/DataHubApi';
import {
    Box,
    Grid,
    IconButton,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextField,
    Pagination,
    MenuItem,
    Stack,
    Button,
    Typography,
    Divider,
    Avatar,
} from '@mui/material';
import { KeyboardArrowRight, Refresh, Article, FolderOpen, Group } from '@mui/icons-material';
import StorageIcon from '@mui/icons-material/Storage';
import colors from "../../components/styles/colors";
import { useTranslation } from 'react-i18next';
import ApexCharts from 'react-apexcharts';

const DonutChart = ({ series, labels, handleNavigate }) => {

    const { t } = useTranslation();

    const options = {
        chart: {
            type: 'donut',
            events: {
                click: (event, chartContext, config) => {
                    const dataPointIndex = config.dataPointIndex;
                    if (dataPointIndex !== undefined && labels[dataPointIndex] && series[dataPointIndex]) {
                        const selectedType = {
                            document_type: labels[dataPointIndex],
                            total_count: series[dataPointIndex]
                        };
                        handleNavigate(selectedType);
                    } else {
                        console.error('Invalid data point selected or chart data is not available');
                    }
                }
            }
        },
        labels: labels,
        // colors: ['#674f87', '#ef798a', '#8b6cfc', '#fbc760', '#66cc99'],
        colors: ['#442F60', '#6D355F', '#9A3C5E', '#684893', '#ef798a'],
        dataLabels: {
            enabled: true,
            formatter: (val) => `${Math.round(val)}%`,
        },
        legend: {
            show: true,
            position: 'bottom',
        },
    };

    return (
        series && series.length > 0 && labels && labels.length > 0 ? ( // Add checks for series and labels
            <ApexCharts options={options} series={series} type="donut" width="380" />
        ) : (
            <div>Loading Chart</div>
        )
    );
};

const DatahubDashboard = () => {
    const [dataHubList, setDataHubList] = useState([]);
    const [docList, setDocList] = useState([]);
    const [docTypeCounts, setDocTypeCounts] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [searchTerm, setSearchTerm] = useState('');
    const [totalRecords, setTotalRecords] = useState(0);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const navigate = useNavigate();

    const totalCategories = Object.keys(docTypeCounts).length;
    const chartSeries = Object.values(docTypeCounts);
    const chartLabels = Object.keys(docTypeCounts);

    const fetchData = async (offset, rowsPerPage, search, startDate, endDate) => {
        setIsLoading(true);
        try {
            const response = await dataHubDataFetch(offset, rowsPerPage, search, startDate, endDate);
            if (response.success !== false) {
                setDataHubList(response.items || []);
                setTotalRecords(response.total || 0);
            } else {
                setDataHubList([]);
                console.warn(response.message || 'No data in response');
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const offset = (page - 1) * rowsPerPage;
        fetchData(offset, rowsPerPage, searchTerm, startDate, endDate);
    }, [page, rowsPerPage, searchTerm, startDate, endDate]);

    const fetchDoc = async (offset, limit, search, startDate, endDate) => {
        setIsLoading(true);
        try {
            const response = await dataHubDataFetch(offset, limit, search, startDate, endDate);
            if (response.success !== false) {
                setDocList(response.items || []);
            } else {
                setDataHubList([]);
                console.warn(response.message || 'No data in response');
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const limit = totalRecords;
        const offset = (page - 1) * limit;
        fetchDoc(offset, limit, '', '', '');
    }, [totalRecords]);

    useEffect(() => {
        const typeCounts = docList.reduce((acc, doc) => {
            acc[doc.data_name] = (acc[doc.data_name] || 0) + 1;
            return acc;
        }, {});
        setDocTypeCounts(typeCounts);
    }, [docList]);

    const handleNavigate = () => {

    };

    return (
        <div style={{ padding: '40px 30px' }}>
            <h2>Data Hub <StorageIcon style={{ fontSize: 30, verticalAlign: 'bottom' }} /></h2>
            <Box sx={{marginBottom:10}}>
                <Grid container alignItems="center" justifyContent="center" marginBottom={5}>
                    <Grid item xs={12} sm={6} md={3} display="flex" flexDirection="row" justifyContent="center" alignItems="center" gap={2}>
                        <Box alignItems="center">
                            <Avatar sx={{ bgcolor: '#432F60', width: { xs: 80, sm: 100 }, height: { xs: 80, sm: 100 } }}>
                                <Article sx={{ fontSize: { xs: 40, sm: 60 }, color: '#d8d8d8' }} />
                            </Avatar>
                        </Box>
                        <Box>
                            <Typography variant="h5" sx={{ marginBlock: 1 }}>
                                Documents
                            </Typography>
                            <Typography variant="h4" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                                {totalRecords}
                            </Typography>
                        </Box>
                    </Grid>

                    <Divider orientation="vertical" flexItem sx={{ mx: 2, borderWidth: 3, display: { xs: 'none', md: 'block' }, color: '#d8d8d8' }} />

                    <Grid item xs={12} sm={6} md={3} display="flex" flexDirection="row" justifyContent="center" alignItems="center" gap={2}>
                        <Box alignItems="center">
                            <Avatar sx={{ bgcolor: '#432F60', width: { xs: 80, sm: 100 }, height: { xs: 80, sm: 100 } }}>
                                <FolderOpen sx={{ fontSize: { xs: 40, sm: 60 }, color: '#d8d8d8' }} />
                            </Avatar>
                        </Box>
                        <Box>
                            <Typography variant="h5" sx={{ marginBlock: 1 }}>
                                Categories
                            </Typography>
                            <Typography variant="h4" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                                {totalCategories}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", margin: "auto" }}>
                <DonutChart series={chartSeries} labels={chartLabels} handleNavigate={handleNavigate} />
            </Box>
        </div>
    );
};

export default DatahubDashboard;
