import { useRef } from 'react';
import { getAudioStream } from './hume/getAudioStream';
import { getBrowserSupportedMimeType, MimeType } from './hume/getBrowserSupportedMimeType';
import { ensureSingleValidAudioTrack } from './hume/ensureSingleValidAudioTrack';
import { convertBlobToBase64 } from './hume/convertBlobToBase64';

export const VoiceChat = (first_message, setfirst_message, startSpeaking, setStartRecord, sendMessage, setUserRecording) => {
	const mediaRecorderRef = useRef(null);
	const audioQueueRef = useRef([]);
	const isPlayingRef = useRef(false);
	let audioStream = useRef(null);

    const mimeType = getBrowserSupportedMimeType().success 
	? getBrowserSupportedMimeType().mimeType 
	: MimeType.WEBM;

	const startRecording = async () => {
		try {
			console.log('Starting recording...');
			audioStream = await getAudioStream();
			ensureSingleValidAudioTrack(audioStream);
			const recorder = new MediaRecorder(audioStream, { mimeType });
			recorder.ondataavailable = async ({ data }) => {
				console.log((first_message || startSpeaking));
				if ((data.size > 0 && !isPlayingRef.current) && (first_message || startSpeaking)) {
					if (first_message){
						setfirst_message(false);
					}
					const base64Data = await convertBlobToBase64(data);
					console.log('Sending audio data. Size:', base64Data.length);
					let messageData = { "type": 'audio', "data": base64Data };
					let messageString = JSON.stringify(messageData);
					sendMessage(messageString);
				}
			};
			mediaRecorderRef.current = recorder;
			recorder.start(1000);
			setStartRecord(true);
			console.log('Recording started.');
		} catch (error) {
			console.error('Error starting recording:', error);
		}
	};

    const stopRecording = () => {
		console.log('Stopping recording...');
		setStartRecord(false);
		mediaRecorderRef.current?.stop();
		audioStream = null
			console.log('Recording stopped.');
	};

	const playNextAudio = () => {
		setUserRecording(true);
		if (audioQueueRef.current.length === 0) {
			isPlayingRef.current = false;
			// setIsPlaying(false);
			setUserRecording(false);
			return;
		}

		isPlayingRef.current = true;
		// setIsPlaying(true);
		const audioBlob = audioQueueRef.current.shift();
		const audioUrl = URL.createObjectURL(audioBlob);
		const audio = new Audio(audioUrl);
		audio.onended = () => {
			URL.revokeObjectURL(audioUrl);
			playNextAudio();
		};
		audio.play();
		setUserRecording(false);
	};

	const convertBase64ToBlob = (base64Data, type) => {
		const binaryString = window.atob(base64Data);
		const len = binaryString.length;
		const bytes = new Uint8Array(len);
		for (let i = 0; i < len; i++) {
			bytes[i] = binaryString.charCodeAt(i);
		}
		return new Blob([bytes], { type: type });
	};

    const receivedAudio = (messageData, audioType) => {
        const audioBlob = convertBase64ToBlob(messageData, audioType);
		audioQueueRef.current.push(audioBlob);
		if (!isPlayingRef.current) {
			playNextAudio();
		}
	};

    return  {
        startRecording: startRecording,
        stopRecording: stopRecording,
        receivedAudio: receivedAudio,
        convertBase64ToBlob: convertBase64ToBlob
    };

}