/*
import React, {useState} from 'react';
import {Table, TableBody, TableRow, TableCell, IconButton, Collapse, TableHead} from '@mui/material';
import {KeyboardArrowDown, KeyboardArrowUp} from '@mui/icons-material';

// Helper function to render values in a readable format
const renderPrimitiveValue = (value) => {
    if (value === null || value === undefined) return ''; // Return empty for null/undefined
    if (typeof value === 'object') return ''; // Skip objects here, handled by CollapsibleRow
    if (Array.isArray(value)) return ''; // Skip arrays here, handled by CollapsibleRow
    return value.toString();
};

const CollapsibleRow = ({keyLabel, value, level}) => {
    const [open, setOpen] = useState(true); // Set open to true for expanded by default
    const hasNestedContent = Array.isArray(value) || (typeof value === 'object' && value !== null);

    return (
        <>
            <TableRow>
                <TableCell style={{paddingLeft: `${level * 20}px`}}>
                    {hasNestedContent && (
                        <IconButton size="small" onClick={() => setOpen(!open)}>
                            {open ? <KeyboardArrowUp/> : <KeyboardArrowDown/>}
                        </IconButton>
                    )}
                    <strong>{keyLabel}</strong>
                </TableCell>
                <TableCell>
                    {!hasNestedContent ? renderPrimitiveValue(value) : null} {/!* Render if no nested content *!/}
                </TableCell>
            </TableRow>
            {hasNestedContent && (
                <TableRow>
                    <TableCell colSpan={2} style={{padding: 0, border: 'none'}}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Table size="small">
                                <TableBody>
                                    {Array.isArray(value) ? renderArrayTable(value, level + 1) : renderDetails(value, level + 1)}
                                </TableBody>
                            </Table>
                        </Collapse>
                    </TableCell>
                </TableRow>
            )}
        </>
    );
};

// Function to render nested objects and arrays
const renderDetails = (data, level = 0) => {
    if (data === null || data === undefined) return null;

    if (Array.isArray(data)) {
        return data.map((item, index) => (
            <CollapsibleRow key={index} keyLabel={`Item ${index + 1}`} value={item} level={level}/>
        ));
    } else if (typeof data === 'object') {
        return Object.entries(data).map(([key, value]) => (
            <CollapsibleRow key={key} keyLabel={key} value={value} level={level}/>
        ));
    }
    return null;
};

// Function to render an array as a table
const renderArrayTable = (array, level) => {
    if (array.length === 0) return null; // Return null for empty arrays

    return (
        <>
            <TableHead>
                <TableRow>
                    {Object.keys(array[0]).map((key) => (
                        <TableCell key={key}><strong>{key}</strong></TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {array.map((item, index) => (
                    <TableRow key={index}>
                        {Object.entries(item).map(([key, val]) => (
                            <TableCell key={key}>{renderPrimitiveValue(val)}</TableCell>
                        ))}
                    </TableRow>
                ))}
            </TableBody>
        </>
    );
};

// Main component to render the table from JSON data
const JsonTable = ({data}) => {
    return (
        <>
            {Object.entries(data).map(([key, value]) => (
                <div key={key}>
                    <h3>{key}</h3> {/!* Render the key as a heading *!/}
                    <Table>
                        <TableBody>{renderDetails(value)}</TableBody> {/!* Loop through the key's data *!/}
                    </Table>
                </div>
            ))}
        </>
    );
};

export default JsonTable;
*/

import {JsonToTable} from "react-json-to-table";

const JsonTable = ({data}) => {
    return (
        <JsonToTable json={data}/>
    )
};
export default JsonTable;