import React, { useState, useEffect } from 'react';
import { List, ListItem, ListItemIcon, ListItemText, Box, Divider, IconButton } from '@mui/material';
import { Badge } from '@mui/material';
import { NavbarTopItems, UiLinkingItems, NavbarWorkplaceItems, NavbarBottomItems } from '../Constants/NavBarItems';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import colors from '../styles/colors';
import { useNavigate } from 'react-router-dom';
import { useGlobalVarible } from '../Constants/globalContextModel';

const NavBar = () => {
    const navigate = useNavigate();
    const [collapsed, setCollapsed] = useState(true);
    const [hovered, setHovered] = useState(false);
    const [activeItem, setActiveItem] = useState(null);
    const { view, anotherVar } = useGlobalVarible();

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 960) {
                setCollapsed(false);
            } else {
                setCollapsed(true);
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const toggleCollapse = () => {
        setCollapsed(!collapsed);
    };

    const handleItemClick = (route, id) => {
        setActiveItem(id);
        navigate(route);
    };

    const listItemStyles = {
        base: {
            color: colors.white,
            padding: '7px 15px',
            borderRadius: '10px',
            transition: 'background-color 0.3s ease',
            '&:hover': {
                ...(view == 1 ? {
                    backgroundColor: colors.listItemBG,
                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
                } : {
                    background: `linear-gradient(to right, #ef798a, #674f87)`
                })

            },
            '& .MuiListItemIcon-root': {
                color: colors.white,
            },
        },
        active: {
            ...(view == 1 ? {
                backgroundColor: colors.listItemActive,
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
            } : {
                background: `linear-gradient(to right, #ef798a, #674f87)`
            })
        },
        inactive: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
        },
    };

    return (
        <Box
            sx={{
                ...(view == 1
                    ? {
                        width: collapsed ? '80px' : '260px',
                        height: '93vh',
                        background: `linear-gradient(180deg, ${colors.primary} 60%, ${colors.secondary})`,
                        color: colors.white,
                        borderRadius: '30px',
                        display: 'flex',
                        flexDirection: 'column',
                        position: 'relative',
                        margin: '.5%',
                        transition: 'width 0.3s',
                    } : {
                        width: collapsed ? '80px' : '260px',
                        background: `linear-gradient(180deg, #191F2B 60%, #191F2B)`,
                        color: colors.white,
                        display: 'flex',
                        flexDirection: 'column',
                        position: 'relative',
                        transition: 'width 0.3s',
                        height: '100vh',
                    }
                )

            }}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        >
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                p={view === 1 ? 2 : 3.6}
                backgroundColor={view === 1 ? 'transparent' : '#9E3C5D'}
            >
                {view === 1 ? (
                    <img
                        src={collapsed ? "/icons/LogoIcon.svg" : "/icons/Logo.svg"}
                        alt="Mind Bot Logo"
                    />
                ) : (
                    <span></span>
                )}
            </Box>

            <Divider style={{ backgroundColor: colors.divider }} />

            {(hovered || collapsed) && (
                <IconButton
                    onClick={toggleCollapse}
                    sx={{
                        color: colors.white,
                        position: 'absolute',
                        top: '50%',
                        right: collapsed ? '-10px' : '-15px',
                        transform: 'translateY(-50%)',
                        transition: 'right 0.3s',
                    }}
                >
                    {collapsed ? <ArrowForwardIosIcon /> : <ArrowBackIosIcon />}
                </IconButton>
            )}

            {/* Top Menu Items */}
            <List sx={{ marginRight: '25px', marginLeft: '5px' }}>
                <h4 style={{ padding: '0 18px', margin: 0, color: colors.navHeadings, display: collapsed ? 'none' : 'block' }}></h4>
                {NavbarTopItems.map((item) => (
                    <ListItem
                        button
                        key={item.id}
                        onClick={() => handleItemClick(item.route, item.id)}
                        sx={{
                            ...listItemStyles.base,
                            ...(activeItem === item.id ? listItemStyles.active : listItemStyles.inactive),
                        }}
                    >
                        <ListItemIcon sx={{ color: 'inherit' }}>
                            {item.label === 'Notifications' ? (
                                <Badge badgeContent={1} color="error">
                                    {item.icon}
                                </Badge>
                            ) : (
                                item.icon
                            )}
                        </ListItemIcon>
                        {!collapsed && <ListItemText primary={item.label} />}
                    </ListItem>
                ))}
            </List>

            {/* Top UiLinkingItems Items */}
            {view !== 1 && (
                <List sx={{ marginRight: '25px', marginLeft: '5px' }}>
                    {UiLinkingItems.map((item) => (
                        <ListItem
                            button
                            key={item.id}
                            onClick={() => handleItemClick(item.route, item.id)}
                            sx={{
                                ...listItemStyles.base,
                                ...(activeItem === item.id ? listItemStyles.active : listItemStyles.inactive),
                            }}
                        >
                            <ListItemIcon sx={{ color: 'inherit' }}>
                                {item.label === 'Notifications' ? (
                                    <Badge badgeContent={1} color="error">
                                        {item.icon}
                                    </Badge>
                                ) : (
                                    item.icon
                                )}
                            </ListItemIcon>
                            {!collapsed && <ListItemText primary={item.label} />}
                        </ListItem>
                    ))}
                </List>
            )}


            {/* Top NavbarWorkplaceItems Items */}
            <List sx={{ marginRight: '25px', marginLeft: '5px' }}>
                <h4 style={{ padding: '5px 18px', margin: 0, color: colors.navHeadings, display: collapsed ? 'none' : 'block' }}>Workplace</h4>
                {NavbarWorkplaceItems.map((item) => (
                    <ListItem
                        button
                        key={item.id}
                        onClick={() => handleItemClick(item.route, item.id)}
                        sx={{
                            ...listItemStyles.base,
                            ...(activeItem === item.id ? listItemStyles.active : listItemStyles.inactive),
                        }}
                    >
                        <ListItemIcon sx={{ color: 'inherit' }}>
                            {item.label === 'Notifications' ? (
                                <Badge badgeContent={1} color="error">
                                    {item.icon}
                                </Badge>
                            ) : (
                                item.icon
                            )}
                        </ListItemIcon>
                        {!collapsed && <ListItemText primary={item.label} />}
                    </ListItem>
                ))}
            </List>

            {/* Bottom Menu Items */}
            <Box sx={{ position: 'absolute', bottom: '15px', width: '100%' }}>
                <h4 style={{ padding: '0 18px', margin: 0, color: colors.navHeadings, display: collapsed ? 'none' : 'block' }}>ACCOUNT</h4>
                <List sx={{ marginRight: '25px', marginLeft: '5px' }}>
                    {NavbarBottomItems.map((item) => (
                        <ListItem
                            button
                            key={item.id}
                            onClick={() => handleItemClick(item.route, item.id)}
                            sx={{
                                ...listItemStyles.base,
                                ...(activeItem === item.id ? listItemStyles.active : listItemStyles.inactive),
                            }}
                        >
                            <ListItemIcon sx={{ color: colors.white }}>{item.icon}</ListItemIcon>
                            {!collapsed && <ListItemText primary={item.label} />}
                        </ListItem>
                    ))}
                </List>
            </Box>
        </Box>
    );
};

export default NavBar;
