import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import { useEffect } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { NavbarProvider } from './components/Navbar/NavbarContext';
import Dashboard from './pages/Dashboard/Dashboard';
import Agent from './pages/Agents/Agents';
import Tools from './pages/Tools/Tools';
import DataHub from './pages/DataHub/DataHub';
import DetailPage from './pages/DataHub/DetailPage';
import { GlobalProvider } from './components/Constants/globalContextModel';
import LandingPage from './pages/LandingPage/LandingPage';
import LoginSignupage from './pages/Login/LoginSignupPage';
import DatahubDashboard from './pages/DataHub/DatahubDashboard';
import DataView from './pages/DataView/DataView';
import DataViewer from './pages/DataView/DataViewer'; 

// Create a default theme using MUI's createTheme
const theme = createTheme();
const aigen = process.env.AIGEN_URL;
const trainingenv = process.env.TRAINING_URL;
const taskallocation = process.env.TASK_ALLOCATION_URL;
function RedirectToTraining() {
  useEffect(() => {
    window.location.href = 'https://cloud.aichemiste2e.quadance.com';
  }, []);

  return null;
}

function RedirectToWorkAllocation() {
  useEffect(() => {
    window.location.href = 'https://taskagent.aichemiste2e.quadance.com/login';
  }, []);

  return null;
}

ReactDOM.render(
  <React.StrictMode>
    <GlobalProvider>  {/* Wrap everything in GlobalProvider */}
      <NavbarProvider>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Routes>
            <Route path="" element={<LandingPage />}/>
              <Route path="LoginSignupage" element={<LoginSignupage />}/>
              {/* The main layout route */}
              <Route path="/" element={<App />}>
                {/* Nested route for Dashboard */}
                <Route path="home" element={<Dashboard />} />
                <Route path="agent" element={<Agent />} />
                <Route path="tools" element={<Tools />} />
                <Route path="DataHub" element={<DataHub />} />
                <Route path="DataView" element={<DataView />} />
                <Route path="/DataViewer/:id/:docType" element={<DataViewer />} />
                <Route path="Dashboard" element={<DatahubDashboard />} />
                <Route path="/details/:id" element={<DetailPage />} />
                <Route path="Training" element={<RedirectToTraining />} />
                <Route path="WorkAllocation" element={<RedirectToWorkAllocation />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </ThemeProvider>
      </NavbarProvider>
    </GlobalProvider>  {/* End of GlobalProvider */}
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();