import React, { useState, useEffect } from 'react';
import GradientCard from '../../components/Cards/GradientCard';
import AdvertisingStatistcs from '../../components/Charts/advertisingStatistcs';
import ActivitiesChart from '../../components/Charts/ActivitiesChart';
import { Grid, Typography, Box, Tooltip, useMediaQuery } from '@mui/material';
import { getAgentListApi } from '../../components/Apis/AgentApi';
import colors from '../../components/styles/colors';

// const data = [
//     { user: 'Agent 1', description: '#12312-321' },
//     { user: 'Agent 1', description: '#35497-497' },
//     { user: 'Agent 1', description: '#32178-124' },
//     { user: 'Agent 1', description: '#78943-564' },
//     { user: 'Agent 1', description: '#12312-321' },
// ];

const Dashboard = () => {
    const isBelow1024px = useMediaQuery('(max-width: 1024px)');
    const [agents, setAgents] = useState([]);
    const [hoveredIcon, setHoveredIcon] = useState(null);

    const handleMouseEnter = (icon) => {
        setHoveredIcon(icon);
    };

    const handleMouseLeave = () => {
        setHoveredIcon(null);
    };

    const getIconStyle = (icon) => ({
        width: '50px',
        height: '50px',
        marginRight: '10px',
        background: colors.grey,
        border: '1px solid white',
        padding: '15px',
        marginTop: '30px',
        borderRadius: '10px',
        marginRight:'10px'
        // transform: hoveredIcon === icon ? 'scale(1.1)' : 'scale(1)', 
        // transition: 'transform 0.3s ease',
    });

    useEffect(() => {
        const fetchAgents = async () => {
            const agentList = await getAgentListApi();
            if (agentList) {
                setAgents(agentList);
            }
        };

        fetchAgents(); // Initial fetch

        const intervalId = setInterval(fetchAgents, 10000); // Fetch every 10 seconds

        return () => clearInterval(intervalId); // Cleanup on component unmount
    }, []);


    return (
        <div style={{ display: 'block', width: '100%' }}>
            <main
                style={{
                    flexGrow: 1,
                    padding: '15px',
                    borderRadius: '0 30px 30px 0',
                    backgroundColor: '#fff',
                }}
            >
                <h1>Active Agents</h1>

                {/* Adjust flexDirection based on the screen size */}
                <Box sx={{ display: 'flex', flexDirection: isBelow1024px ? 'column' : 'row' }}>
                    {/* Table Section */}
                    <Box p={2} sx={{ minWidth: '550px', maxWidth: '700px', flexGrow: 1, marginRight: isBelow1024px ? '0' : '50px', marginBottom: isBelow1024px ? '20px' : '0' }}>
                        {/* Table with a scrollable body */}
                        <Box sx={{ maxHeight: '280px', overflowY: 'auto' }}>
                            {/* Table Header */}
                            <Grid container spacing={2} style={{ borderBottom: '2px solid #ccc' }}>
                                <Grid item xs={4}>
                                    <Typography fontWeight="bold">Agent Name</Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography fontWeight="bold">Description</Typography>
                                </Grid>
                            </Grid>

                            {/* Scrollable Rows */}
                            {agents.map((row, index) => (
                                <Grid
                                    container
                                    key={index}
                                    spacing={2}
                                    alignItems="center"
                                    py={2}
                                    style={{ borderBottom: '1px solid #ccc' }}
                                >
                                    <Grid item xs={4} display="flex" alignItems="center">
                                        <img
                                            src={row.icon}
                                            alt={row.title}
                                            style={{ width: '20px', height: '20px', marginRight: 8, borderRadius: '50%' }}
                                        />
                                        <Typography>{row.title}</Typography>
                                    </Grid>

                                    <Grid item xs={8}>
                                        <Typography
                                            sx={{
                                                backgroundColor: '#f0f0f0',
                                                padding: '2px 6px',
                                                borderRadius: '12px',
                                            }}
                                        >
                                            {row.description}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            ))}
                        </Box>
                    </Box>

                    {/* GradientCard aligned to the right */}
                    <Box sx={{ display: 'flex', justifyContent: isBelow1024px ? 'flex-start' : 'flex-start' }}>
                        <GradientCard width='550px' height="300px">
                            <AdvertisingStatistcs />
                        </GradientCard>
                    </Box>
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: isBelow1024px ? 'column' : 'row',
                        justifyContent: 'left',
                        gap: isBelow1024px ? '20px' : '50px',
                        marginTop: '20px',
                    }}
                >
                    <GradientCard width='550px' height="300px">
                        <h2 style={{ textTransform: 'capitalize', }}>TOOLS INTEGRATED</h2>
                        <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                            <Box display="flex" flexDirection="column" alignItems="center">
                                <Tooltip title="Gmail" arrow>
                                    <img
                                        src={`/icons/gmailLogo.svg`}
                                        alt="gmail"
                                        style={getIconStyle('gmail')}
                                        onMouseEnter={() => handleMouseEnter('gmail')}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                </Tooltip>
                                <Typography variant="body2">Gmail</Typography>
                            </Box>

                            <Box display="flex" flexDirection="column" alignItems="center">
                                <Tooltip title="Outlook" arrow>
                                    <img
                                        src={`/icons/outlookLogo.svg`}
                                        alt="outlook"
                                        style={getIconStyle('outlook')}
                                        onMouseEnter={() => handleMouseEnter('outlook')}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                </Tooltip>
                                <Typography variant="body2">Outlook</Typography>
                            </Box>

                            <Box display="flex" flexDirection="column" alignItems="center">
                                <Tooltip title="Document Extraction" arrow>
                                    <img
                                        src={`/icons/docExtractionLogo.svg`}
                                        alt="Document_Extractor"
                                        style={getIconStyle('gmail2')}
                                        onMouseEnter={() => handleMouseEnter('gmail2')}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                </Tooltip>
                                <Typography variant="body2">Extraction</Typography>
                            </Box>

                            <Box display="flex" flexDirection="column" alignItems="center">
                                <Tooltip title="Google Map" arrow>
                                    <img
                                        src={`/icons/mapLogo.png`}
                                        alt="google map"
                                        style={getIconStyle('map')}
                                        onMouseEnter={() => handleMouseEnter('gmail2')}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                </Tooltip>
                                <Typography variant="body2">Google Map</Typography>
                            </Box>

                            <Box display="flex" flexDirection="column" alignItems="center">
                                <Tooltip title="Authentication" arrow>
                                    <img
                                        src={`/icons/AuthenticationLogo.png`}
                                        alt="AuthenticationLogo"
                                        style={getIconStyle('map')}
                                        onMouseEnter={() => handleMouseEnter('gmail2')}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                </Tooltip>
                                <Typography variant="body2">Authentication</Typography>
                            </Box>
                        </Box>

                    </GradientCard>
                    <Box sx={{ width: '550px', marginTop: isBelow1024px ? '20px' : '0' }}>
                        <ActivitiesChart width="650px" height="300px" />
                    </Box>
                </Box>
            </main>
        </div>
    );
};

export default Dashboard;
