import React, {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom'; // To get the ID from the URL
import {Paper, Box, Typography, Button, Menu, MenuItem, Pagination, TextField, CircularProgress} from '@mui/material';
import {dataHubDetailFetch} from "../../components/Apis/DataHubApi";
import JsonTable from "../../components/DynamicTables/JsonTable";
import ReactJson from 'react-json-view';

const DetailPage = () => {
    const {id} = useParams(); // Get the row ID from the URL
    const [dataHubDetails, setDataHubDetails] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async (id) => {
            try {
                const response = await dataHubDetailFetch(id);
                if (response) {
                    setDataHubDetails(response.data);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchData(id);
    }, [id]);

    const parsedData = dataHubDetails ? JSON.parse(dataHubDetails.data) : null;
    const [selectedIndex, setSelectedIndex] = useState(0); // State for selected parsedData index
    const [viewMode, setViewMode] = useState('table'); // 'table' or 'json'
    const [page, setPage] = useState(1);
    const [inputPage, setInputPage] = useState(''); // State for input field
    const totalPages = parsedData ? parsedData.length : 0;
    const [anchorEl, setAnchorEl] = useState(null); // State for dropdown menu
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget); // Open dropdown menu
    };
    const handleClose = () => {
        setAnchorEl(null); // Close dropdown menu
    };

    const downloadJsonFile = (data) => {
        downloadFile(data); // Call helper function to download file
        handleClose(); // Close dropdown after selection
    };
    const handlePageChange = (event, value) => {
        setPage(value); // Update the page based on pagination control
    };

    const handleInputChange = (event) => {
        setInputPage(event.target.value); // Update the input value
    };

    const handleJumpToPage = () => {
        const pageNumber = parseInt(inputPage, 10);
        if (!isNaN(pageNumber) && pageNumber > 0 && pageNumber <= totalPages) {
            setPage(pageNumber); // Set page to the input value if valid
            setInputPage(''); // Clear input after jump
        } else {
            alert('Please enter a valid page number');
        }
    };
    const handleSelectChange = (event) => {
        setSelectedIndex(event.target.value); // Update index based on selection
    };
    // Function to toggle between table and JSON view
    const handleViewToggle = (mode) => {
        setViewMode(mode);
    };

    // Function to download JSON as a file
    const downloadFile = (pageData) => {
        const blob = new Blob([JSON.stringify(pageData, null, 2)], {type: 'application/json'});
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `${dataHubDetails.data_name}.json`;
        link.click();
    };
    /*const data_aaa = {
    };
    console.warn(data_aaa);*/
    return isLoading || !dataHubDetails ? (
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
            <CircularProgress/>
        </div>
    ) : (
        /*<Paper elevation={3} style={{padding: '16px', margin: '20px'}}>
            <Box p={2}>
                <Typography variant="h4" gutterBottom>
                    Agent: {dataHubDetails.data_name}
                </Typography>
                {dataHubDetails.data_type === 'json' ? (
                    <JsonTable data={parsedData[0]}/>
                ) : (
                    <Typography variant="body1">{dataHubDetails.data}</Typography> // Render as plain text
                )}
            </Box>
        </Paper>*/
        /*
                <Paper elevation={3} style={{padding: '16px', margin: '20px'}}>
                    <Box p={2} display="flex" alignItems="center" justifyContent="space-between">
                        <Typography variant="h4" gutterBottom>
                            Agent: {dataHubDetails.data_name}
                        </Typography>
                        <Box>
                            <Button variant="contained" onClick={() => handleViewToggle('table')} style={{marginRight: '10px'}}>
                                Table
                            </Button>
                            <Button variant="contained" onClick={() => handleViewToggle('json')} style={{marginRight: '10px'}}>
                                JSON
                            </Button>
                            <Button variant="contained" onClick={downloadJsonFile}>
                                Download as JSON
                            </Button>
                        </Box>
                    </Box>

                    <Box mt={2}>
                        {viewMode === 'table' ? (
                            dataHubDetails.data_type === 'json' ? (
                                <JsonTable data={parsedData[0]}/>
                            ) : (
                                <Typography variant="body1">{dataHubDetails.data}</Typography> // Render as plain text
                            )
                        ) : (
                            <Box>
                                <ReactJson src={parsedData[0]} collapsed={2} enableClipboard={true}/>
                            </Box>
                        )}
                    </Box>
                </Paper>
        */
        /*<Paper elevation={3} style={{padding: '16px', margin: '20px'}}>
            <Box p={2} display="flex" alignItems="center" justifyContent="space-between">
                <Typography variant="h4" gutterBottom>
                    Agent: {dataHubDetails.data_name}
                </Typography>
                <Box>
                    <Button variant="contained" onClick={() => handleViewToggle('table')} style={{marginRight: '10px'}}>
                        Table
                    </Button>
                    <Button variant="contained" onClick={() => handleViewToggle('json')} style={{marginRight: '10px'}}>
                        JSON
                    </Button>
                    <Button variant="contained" onClick={downloadJsonFile}>
                        Download as JSON
                    </Button>
                </Box>
            </Box>

            <Box mt={2} display="flex" alignItems="center">
                <Typography variant="body1" style={{marginRight: '10px'}}>Select Page:</Typography>
                <Select value={selectedIndex} onChange={handleSelectChange}>
                    {parsedData.map((_, index) => (
                        <MenuItem key={index} value={index}>
                            {`Page ${index + 1}`}
                        </MenuItem>
                    ))}
                </Select>
            </Box>

            <Box mt={2}>
                {viewMode === 'table' ? (
                    dataHubDetails.data_type === 'json' ? (
                        <JsonTable data={parsedData[selectedIndex]}/> // Render selected parsedData item in table
                    ) : (
                        <Typography variant="body1">{dataHubDetails.data}</Typography> // Render as plain text
                    )
                ) : (
                    <Box>
                        <ReactJson src={parsedData[selectedIndex]} collapsed={2} enableClipboard={true}/>
                    </Box>
                )}
            </Box>
        </Paper>*/
        /*<Paper elevation={3} style={{padding: '16px', margin: '20px'}}>
            <Box p={2} display="flex" alignItems="center" justifyContent="space-between">
                <Typography variant="h4" gutterBottom>
                    Agent: {dataHubDetails.data_name}
                </Typography>
                <Box>
                    <Button variant="contained" onClick={() => handleViewToggle('table')} style={{marginRight: '10px'}}>
                        Table
                    </Button>
                    <Button variant="contained" onClick={() => handleViewToggle('json')} style={{marginRight: '10px'}}>
                        JSON
                    </Button>
                    <Button variant="contained" onClick={downloadJsonFile}>
                        Download as JSON
                    </Button>
                </Box>
            </Box>
            {/!* Pagination controls *!/}
            <Box mt={2} display="flex" justifyContent="center">
                <Pagination
                    count={parsedData.length}
                    page={page}
                    onChange={handlePageChange}
                    color="primary"
                />
            </Box>

            <Box mt={2}>
                {viewMode === 'table' ? (
                    dataHubDetails.data_type === 'json' ? (
                        <JsonTable data={parsedData[page - 1]}/> // Render current page data in table
                    ) : (
                        <Typography variant="body1">{dataHubDetails.data}</Typography> // Render as plain text
                    )
                ) : (
                    <Box>
                        <ReactJson src={parsedData[page - 1]} collapsed={2} enableClipboard={true}/>
                    </Box>
                )}
            </Box>


        </Paper>*/
        <Paper elevation={3} style={{padding: '16px', margin: '20px'}}>
            <Box p={2} display="flex" alignItems="center" justifyContent="space-between">
                <Typography variant="h4" gutterBottom>
                    Agent: {dataHubDetails.data_name}
                </Typography>
                <Box>
                    <Button variant="contained" onClick={() => handleViewToggle('table')} style={{marginRight: '10px'}}>
                        Table
                    </Button>
                    <Button variant="contained" onClick={() => handleViewToggle('json')} style={{marginRight: '10px'}}>
                        JSON
                    </Button>
                    {/*<Button variant="contained" onClick={() => downloadJsonFile(parsedData)}>
                        Download as JSON
                    </Button>*/}
                    {/* Dropdown button for download options */}
                    <Button variant="contained" onClick={handleClick}>
                        Download as JSON
                    </Button>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={() => downloadJsonFile(parsedData[page - 1], `page-${page}.json`)}>
                            Download Current Page as JSON
                        </MenuItem>
                        <MenuItem onClick={() => downloadJsonFile(parsedData, 'all-pages.json')}>
                            Download All Pages as JSON
                        </MenuItem>
                    </Menu>
                </Box>
            </Box>
            <Box mt={2} display="flex" justifyContent="center" alignItems="center">
                <TextField
                    label="Go to Page"
                    variant="outlined"
                    size="small"
                    value={inputPage}
                    onChange={handleInputChange}
                    style={{marginRight: '10px'}}
                />
                <Button variant="contained" onClick={handleJumpToPage}>
                    Go
                </Button>
                <Pagination
                    count={totalPages} // Total pages based on parsedData length
                    page={page}
                    onChange={handlePageChange}
                    color="primary"
                />
            </Box>
            <Box mt={2}>
                {viewMode === 'table' ? (
                    dataHubDetails.data_type === 'json' ? (
                        // <JsonTable data={data_aaa}/>
                        <JsonTable data={parsedData[page - 1]}/> // Render current page data in table
                    ) : (
                        <Typography variant="body1">{dataHubDetails.data}</Typography> // Render as plain text
                    )
                ) : (
                    <Box>
                        <ReactJson src={parsedData[page - 1]} collapsed={2} enableClipboard={true}/>
                    </Box>
                )}
            </Box>
        </Paper>

    );
};

export default DetailPage;
