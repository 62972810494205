import React from 'react';
import { TextField, Typography, Box } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import colors from '../../../components/styles/colors';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const BehaviorSetting = (props) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const handleChange = (section, field, value) => {
        props.setAgentData(prevData => ({
            ...prevData,
            [section]: {
                ...prevData[section],
                [field]: value
            }
        }));
    };
    // return (
    //     <Box
    //         sx={{
    //             flexGrow: 1,
    //             padding: 3,
    //             height: '85%',
    //             display: 'flex',
    //             flexDirection: { xs: 'column', sm: 'column', md: 'row' }
    //         }}
    //     >
    //         {/* Left section */}
    //         <Box
    //             sx={{
    //                 border: `1px solid ${colors.silver}`,
    //                 flex: 3,
    //                 padding: 2,
    //                 marginBottom: { xs: 2, sm: 0 },
    //                 display: 'flex',
    //                 flexDirection: 'column',
    //                 marginRight: '1%',
    //                 minHeight: '70%'
    //             }}
    //         >
    //             <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 3 }}>
    //                 <AccountCircleIcon sx={{ width: 56, height: 56, color: colors.silver }} />
    //                 <Typography sx={{ marginLeft: 2, fontSize: '20px' }}>
    //                     {props.agentData.profile.Name ? (
    //                         props.agentData.profile.Name
    //                     ) : (
    //                         'Name Surname'
    //                     )}
    //                 </Typography>

    //             </Box>
    //             {/* Behavior Setting Section */}
    //             <Typography sx={{ marginBottom: 1, fontSize: '20px' }}>
    //                 Behaviour Setting
    //             </Typography>
    //             <TextField
    //                 multiline
    //                 rows={isSmallScreen ? 8 : 15}
    //                 placeholder="Enter agent details"
    //                 value={props.agentData.BehaviourSettings.Details}
    //                 onChange={(e) => handleChange('BehaviourSettings', 'Details', e.target.value)}
    //                 variant="outlined"
    //                 sx={{
    //                     margin: 1,
    //                     width: '93%',
    //                     borderRadius: '25px',
    //                     '& .MuiOutlinedInput-root': {
    //                         '& fieldset': {
    //                             borderColor: colors.silver,
    //                         },
    //                         '&:hover fieldset': {
    //                             borderColor: colors.primary,
    //                         },
    //                         '&.Mui-focused fieldset': {
    //                             borderColor: colors.silver,
    //                         },
    //                     },
    //                 }}
    //             />
    //         </Box>

    //         {/* Right section - Suggestions */}
    //         <Box
    //             sx={{
    //                 border: `1px solid ${colors.silver}`,
    //                 overflowY: 'scroll',
    //                 flex: 1,
    //                 padding: 2,
    //                 display: 'flex',
    //                 flexDirection: 'column',
    //                 minHeight: '70%',
    //                 '&::-webkit-scrollbar': {
    //                     width: '5px',
    //                 },
    //                 '&::-webkit-scrollbar-thumb': {
    //                     backgroundColor: colors.secondary,
    //                     borderRadius: '10px',
    //                 },
    //                 '&::-webkit-scrollbar-track': {
    //                     backgroundColor: 'transparent',
    //                 },
    //             }}
    //         >
    //             <Typography sx={{ marginBottom: 2, fontWeight: 'bold' }}>
    //                 User Guidelines for Interacting with Workflow Agents
    //             </Typography>
    //             <Typography variant="body2" sx={{ fontSize: '12px' }}>
    //                 <p>To ensure smooth and efficient communication with the Workflow Agents, it’s important to understand how each agent behaves and operates, and what permissions they have. These guidelines will help you navigate your interactions more effectively.</p>
                    
    //                 <br />
    //                 <Typography sx={{ marginBottom: 2, fontWeight: 'bold', fontSize: '15px' }}>
    //                     Behaviour Guidelines
    //                 </Typography>
    //                 <p><strong>Tone & Language:</strong></p>
    //                 <ul>
    //                     <li>Agents maintain a professional, respectful, and courteous tone in all interactions.</li>
    //                     <li>Depending on the agent, the language can be supportive, empathetic, or promotional (for example, the Automobile agent uses more promotional language).</li>
    //                     <li>Responses are kept clear and concise, avoiding complex terms or jargon unless necessary.</li>
    //                 </ul>
                    
    //                 <p><strong>Response Style:</strong></p>
    //                 <ul>
    //                     <li><strong>Informative:</strong> The agent provides accurate and relevant information, especially for inquiries like HR policies or technical support.</li>
    //                     <li><strong>Sales-Oriented:</strong> For agents like the Automobile agent, the response focuses on marketing products, such as promoting cars and their features.</li>
    //                     <li><strong>Empowering:</strong> The agents encourage you to take action, like registering for a test drive or applying for a benefit, by guiding you through the necessary steps.</li>
    //                 </ul>
                    
    //                 <em>User Tip:</em> You can expect each agent to respond according to these behaviour guidelines. For example, ask questions clearly and you’ll receive precise, easy-to-understand information.
    //                 <br />
    //                 <br />
    //                 <Typography sx={{ marginBottom: 2, fontWeight: 'bold', fontSize: '16px' }}>
    //                     Key Things to Remember:
    //                 </Typography>
    //                 <ol>
    //                     <li>Ask Clear Questions: This will help the agent provide more accurate and relevant information.</li>
    //                     <li>Follow the Step-by-Step Instructions: Agents provide solutions in simple steps to avoid confusion. Take each step carefully for the best results.</li>
    //                     <li>Escalate When Necessary: If your issue cannot be resolved by the agent, you’ll be given the option to escalate to a human representative or create a support ticket.</li>
    //                     <li>Agent Behaviour Varies: The tone and response style depend on the agent you’re interacting with. HR agents will be more supportive, while sales agents will focus on promoting products.</li>
    //                 </ol>
                    
    //                 <p>By following these guidelines, you’ll have an easier time navigating your interactions with the system and getting the assistance you need.</p>
    //             </Typography>
    //         </Box>
    //     </Box>
    // );
    return (
        <Box
            sx={{
                flexGrow: 1,
                padding: 3,
                height: '85%',
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'column', md: 'row' }
            }}
        >
            {/* Left section */}
            <Box
                sx={{
                    // border: `1px solid ${colors.silver}`,
                    flex: 3,
                    padding: 2,
                    marginBottom: { xs: 2, sm: 0 },
                    display: 'flex',
                    flexDirection: 'column',
                    marginRight: '1%',
                    borderRadius: 3,
                    minHeight: '70%',
                    background:"#fff",
                    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
                }}
            >
                {/* <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 3 }}>
                    <AccountCircleIcon sx={{ width: 56, height: 56, color: colors.silver }} />
                    <Typography sx={{ marginLeft: 2, fontSize: '20px', fontWeight: 'bold' }}>
                        {props.agentData.profile.Name || 'Name Surname'}
                    </Typography>
                </Box> */}

                {/* Behavior Setting Section */}
                <Typography sx={{ marginBottom: 1, fontSize: '20px', fontWeight: 'bold' }}>
                    Behavior Setting
                </Typography>
                
                <TextField
                    multiline
                    rows={isSmallScreen ? 8 : 15}
                    placeholder="Enter agent details"
                    value={props.agentData.BehaviourSettings.Details}
                    onChange={(e) => handleChange('BehaviourSettings', 'Details', e.target.value)}
                    variant="outlined"
                    sx={{
                        margin: 1,
                        width: '93%',
                        borderRadius: '25px',
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: colors.silver,
                            },
                            '&:hover fieldset': {
                                borderColor: colors.primary,
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: colors.primary,
                            },
                        },
                    }}
                />
            </Box>

            {/* Right section - Suggestions */}
            <Box
                sx={{
                    // border: `1px solid ${colors.silver}`,
                    background:"#fff",
                    overflowY: 'scroll',
                    flex: 1,
                    padding: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: 3,
                    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
                    minHeight: '70%',
                    '&::-webkit-scrollbar': {
                        width: '5px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: colors.secondary,
                        borderRadius: '10px',
                    },
                    '&::-webkit-scrollbar-track': {
                        backgroundColor: 'transparent',
                    },
                }}
            >
                <Typography sx={{ marginBottom: 2, fontWeight: 'bold', fontSize: '18px' }}>
                    User Guidelines for Interacting with Workflow Agents
                </Typography>

                <Typography variant="body2" sx={{ fontSize: '14px', lineHeight: 1.6 }}>
                    <p>To ensure smooth and efficient communication with the Workflow Agents, be sure to understand how each agent behaves and operates. These guidelines will help you navigate your interactions effectively.</p>
                    
                    <Typography sx={{ marginY: 2, fontWeight: 'bold', fontSize: '15px' }}>
                        Behaviour Guidelines
                    </Typography>
                    <p><strong>Tone & Language:</strong></p>
                    <ul>
                        <li>Agents maintain a professional, respectful, and courteous tone in all interactions.</li>
                        <li>The language can be supportive, empathetic, or promotional depending on the agent type.</li>
                        <li>Responses are clear and concise, avoiding complex terms unless necessary.</li>
                    </ul>
                    
                    <p><strong>Response Style:</strong></p>
                    <ul>
                        <li><strong>Informative:</strong> Provides accurate information for inquiries like HR policies.</li>
                        <li><strong>Sales-Oriented:</strong> Focuses on marketing products for agents like the Automobile agent.</li>
                        <li><strong>Empowering:</strong> Encourages actions like registering or applying, guiding you through steps.</li>
                    </ul>
                    
                    <em>User Tip:</em> Ask clear questions to receive precise, easy-to-understand information.
                    <br />
                    
                    <Typography sx={{ marginY: 2, fontWeight: 'bold', fontSize: '16px' }}>
                        Key Things to Remember:
                    </Typography>
                    <ol>
                        <li>Ask Clear Questions to help the agent provide accurate information.</li>
                        <li>Follow the Step-by-Step Instructions provided by agents.</li>
                        <li>Escalate When Necessary if issues can't be resolved.</li>
                        <li>Agent Behaviour Varies; tone and response depend on agent type.</li>
                    </ol>
                    
                    <p>By following these guidelines, you’ll navigate your interactions with ease and get the assistance you need.</p>
                </Typography>
            </Box>
        </Box>
    );

};

export default BehaviorSetting;
