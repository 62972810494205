import {React, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { dataHubDataFetch } from '../../components/Apis/DataHubApi';
import {
    Box,
    Grid,
    IconButton,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextField,
    Pagination,
    MenuItem,
    Stack,
    Button // Import the Button component
} from '@mui/material';
import { KeyboardArrowRight, Refresh } from '@mui/icons-material';
import StorageIcon from '@mui/icons-material/Storage';
import colors from "../../components/styles/colors";
import { getDataList } from '../../components/Apis/AgentApi';

const gradientButtonStyle = {
    background: `linear-gradient(180deg, ${colors.primary} 60%, ${colors.secondary} 90%)`,
    color: colors.white,
    '&:hover': {
        background: `linear-gradient(180deg, ${colors.primary} 60%, ${colors.secondary} 90%)`,
        opacity: 0.8,
    },
};
let data = [
    { 'id': 3, 'patient_name': 'Mr. KHESAV GROODOYAL', 'doc_type': 'Medical Report' },
    { 'id': 2, 'patient_name': 'Mr. KHESAV GROODOYAL', 'doc_type': 'Discharge Summary' },
    { 'id': 6, 'patient_name': 'RUDOLF JACOBUS NIEMAN', 'doc_type': 'Medical Report' },
    { 'id': 1, 'patient_name': 'Mr. RUDOLF JACOBUS NIEMAN', 'doc_type': 'Bill' },
    { 'id': 7, 'patient_name': 'ELIE MATHIEU', 'doc_type': 'Medical Report' },
    { 'id': 17, 'patient_name': 'Mr. ELIE MATHIEU', 'doc_type': 'Discharge Summary' },
    { 'id': 10, 'patient_name': 'Mr. ELIE MATHIEU', 'doc_type': 'Bill' }
]

const DataView = () => {
    const [dataHubList, setDataHubList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [totalRecords, setTotalRecords] = useState(0);
    const navigate = useNavigate();

    const [dataList, setDataList] = useState(data);



    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(event.target.value);
        setPage(1);
    };
    const handleViewClick = (rowId, docType) => {
        navigate(`/DataViewer/${rowId}/${docType}`);
    };
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getDataList();
                setDataList(response);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
   
        fetchData(); // Call the async function
    }, []);
 


    return (
        <div style={{ padding: '40px 30px' }}>
            <h2>Data View <StorageIcon style={{ fontSize: 30, verticalAlign: 'bottom' }} /></h2>
            <Box
                sx={{
                    p: 2,
                    mb: 4,
                    backgroundColor: colors.containerBG,
                    borderRadius: 5,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    textAlign: 'center',
                    width: '100%'
                }}
            >
                {isLoading ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <CircularProgress />
                    </div>
                ) : (
                    <>
                        <TableContainer component={Paper} sx={{ borderRadius: '10px', overflow: 'hidden' }}>
                            <Table>
                                <TableHead>
                                    <TableRow sx={{ padding: 0 }}>
                                        <TableCell>INDEX</TableCell>
                                        <TableCell>NAME</TableCell>
                                        <TableCell>DOCUMENT TYPE</TableCell>
                                        <TableCell>ACTIONS</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {dataList.map((row, index) => (
                                        <TableRow key={row.id} hover sx={{padding:0}}>
                                            <TableCell>{index + 1}</TableCell>
                                            <TableCell>{row.patient_name}</TableCell>
                                            <TableCell>{row.doc_type}</TableCell>
                                            <TableCell>
                                                <Button variant="outlined" sx={gradientButtonStyle} style={{ borderRadius: '20px' }}
                                                onClick={() => handleViewClick(row.id,row.doc_type)}>
                                                    View
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Grid container justifyContent="space-between" alignItems="center" sx={{ mt: 2 }}>
                            <Grid item>
                                <TextField
                                    select
                                    label="Rows per page"
                                    value={rowsPerPage}
                                    onChange={handleRowsPerPageChange}
                                    sx={{ width: '120px' }}
                                >
                                    {[5, 10, 20].map((option) => (
                                        <MenuItem key={option} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item>
                                <Pagination
                                    count={Math.ceil(totalRecords / rowsPerPage)}
                                    page={page}
                                    onChange={(event, value) => setPage(value)}
                                    color="primary"
                                />
                            </Grid>
                        </Grid>
                    </>
                )}
            </Box>
        </div>
    );
};

export default DataView;
