// GlobalState.js
import React, { createContext, useState, useContext } from 'react';

// Create the context
const GlobalContext = createContext();

// Create a provider component
export const GlobalProvider = ({ children }) => {
    const [agentid, setagentid] = useState(null);
    const [view, setView] = useState(2);

    // Return the context provider
    return (
        <GlobalContext.Provider value={{ agentid, setagentid, view, setView }}>
            {children}
        </GlobalContext.Provider>
    );
};

// Custom hook to use the context (only returning get and set)
export const useGlobalVarible = () => {
    const context = useContext(GlobalContext);
    if (!context) {
        throw new Error('useGlobalState must be used within a GlobalProvider');
    }
    return context; // This returns the entire context object
};
