import React from 'react';
import { Typography, Box } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import colors from '../../../components/styles/colors';
import { Paper, Select, MenuItem, FormControl } from '@mui/material';

const AdvancedSettings = (props) => {
    return (
        <Box
            sx={{
                flexGrow: 1,
                padding: 3,
                height: '85%',
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'column', md: 'row' }
            }}
        >
            {/* Left section */}
            <Box
                sx={{
                    // border: `1px solid ${colors.silver}`,
                    background:"#fff",
                    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
                    borderRadius:3,
                    flex: 3,
                    padding: 2,
                    marginBottom: { xs: 2, sm: 0 },
                    display: 'flex',
                    flexDirection: 'column',
                    marginRight: '1%',
                    minHeight: '70%'
                }}
            >
            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 3 }}>
                <AccountCircleIcon sx={{ width: 56, height: 56, color: colors.silver }} />
                <Typography sx={{ marginLeft: 2, fontSize: '20px', fontWeight: 'bold' }}>
                    {props.agentData.profile.Name || 'Name Surname'}
                </Typography>
            </Box>
                <Box sx={{ width: '100%', border: `1px solid ${colors.border}`, borderRadius: '10px' }}>
                    <div style={{ padding: '20px', width: '92%', margin: 'auto' }}>
                        {/* Language Model Section */}
                        <Paper elevation={3} style={{ padding: '20px', backgroundColor: colors.grey, marginBottom: '20px', display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <Box>
                                <Typography style={{ fontWeight: 'bold', fontSize: '20px' }}>
                                    Language Model
                                </Typography>
                                <Typography variant="body1">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit,<br />
                                    recommended LLM for optimum performance
                                </Typography>

                            </Box>
                            <FormControl sx={{ minWidth: 150, padding: 0, height: '30px' }}>
                                <Select
                                    defaultValue=""
                                    displayEmpty
                                    sx={{
                                        height: '30px',
                                        '.MuiSelect-select': {
                                            padding: '4px 10px',
                                            fontSize: '14px',
                                            minHeight: 'unset',
                                            lineHeight: 'normal'
                                        }
                                    }}
                                >
                                    <MenuItem value="">Model name</MenuItem>
                                    <MenuItem value="">Model name1</MenuItem>
                                    {/* Add other options as needed */}
                                </Select>
                            </FormControl>

                        </Paper>
                    </div>
                </Box>


            </Box>

            {/* Right section - Suggestions */}
            <Box
                sx={{
                    // border: `1px solid ${colors.silver}`,
                    background:"#fff",
                    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
                    borderRadius:3,
                    overflowY: 'scroll',
                    flex: 1,
                    padding: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '70%',
                    '&::-webkit-scrollbar': {
                        width: '5px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: colors.secondary,
                        borderRadius: '10px',
                    },
                    '&::-webkit-scrollbar-track': {
                        backgroundColor: 'transparent',
                    },
                }}
            >
                <Typography sx={{ marginBottom: 2, fontWeight: 'bold' }}>
                    Suggestions
                </Typography>
                <Typography variant="body2" sx={{ fontSize: '12px' }}>
                    <p>Behavior Setting Instructions for a Call Center Customer Support AI Agent</p>
                    <br /><br />
                    <em>Objective:</em> To provide a clear and comprehensive set of guidelines for an AI agent to effectively interact with customers in a call center environment, ensuring a positive and helpful experience.
                    <br /><br />
                    <p>Core Principles</p>
                    <p>Empathy and Understanding: The AI agent should strive to understand the customer's perspective, emotions, and needs.</p>
                    <p>Clarity and Conciseness: Responses should be clear, concise, and easy to understand. Avoid jargon or technical terms.</p>
                    <p>Politeness and Respect: Always maintain a polite and respectful tone, even in challenging situations.</p>
                    <p>Problem-Solving Focus: The AI agent should be proactive in identifying and resolving customer issues.</p>
                </Typography>
            </Box>
        </Box>
    );
};

export default AdvancedSettings;
