import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getPatientData } from '../../components/Apis/AgentApi';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

const DataViewer = () => {
    const { id, docType } = useParams();
    const [fetchedHtmlData, setFetchedHtmlData] = useState();
    const [base64Pdf, setBase64Pdf] = useState("")
    const [pdfUrl, setPdfUrl] = useState(`data:application/pdf;base64,${base64Pdf}`);

    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await getPatientData(id, docType);
          setFetchedHtmlData(response["html"]);
          setBase64Pdf(response["file"]);
          console.log(response["file"]);
          setPdfUrl(`data:application/pdf;base64,${response["file"]}`)
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
    
      fetchData();
    }, [id, docType]);
    return (
        <div style={{ width: '98%', margin: '0 auto', minHeight: '100vh' }}> 
            <h1>Data Viewer</h1>
            <div style={{ display: 'flex', alignItems: 'stretch' }}>
                <div style={{
                    flex: 1,
                    padding: '20px',
                    backgroundColor: '#f0f0f0',
                    minHeight: '75vh',
                    height: 'auto'
                }}>
                    {pdfUrl ? (
                        <Worker workerUrl={`https://unpkg.com/pdfjs-dist@2.10.377/build/pdf.worker.min.js`}>
                            <Viewer fileUrl={pdfUrl} />
                        </Worker>
                    ) : (
                        <p>Loading PDF...</p>
                    )}
                </div>
                <div style={{
                    width: '2px',
                    backgroundColor: '#000',
                    height: 'auto'
                }}></div>
                <div style={{
                    flex: 1,
                    padding: '20px',
                    backgroundColor: '#e0e0e0',
                    minHeight: '75vh',
                    height: 'auto'
                }}
                    dangerouslySetInnerHTML={{ __html: fetchedHtmlData }}
                ></div>
            </div>
        </div>
    );
};

export default DataViewer;
